import React, { useEffect, useState } from "react";

import styled from "styled-components";

import SettingList from "../../Components/Settings/SettingList";
import AccountDetails from "../../Components/Settings/AccountDetails";
import { useDispatch, useSelector } from "react-redux";
import { getAccessToken, getUserDetails, getuserInfo } from "../../store/Actions/dashboard.action";

import LoginSecurity from "../../Components/Settings/LoginAndSecurity";
import TaxDetails from "../../Components/Settings/Taxdetails";

import CallingUI from "../../Components/Dashboard/CallingUI";
import IncomingUI from "../../Components/Dashboard/IncomingUI";
import ProductCatalogue from "../../Components/Dashboard/Payments/ProductCatalogue";
import TermsAndConditions from "../../Components/Settings/TermAndConitions";
import GoogleLog from "../../Pages/GoogleLog";
import GoogleBusiness from "../../Pages/GoogleBusiness";
import FacebookLeads from "../Dashboard/Contacts/FacebookLeads";



function Settings() {
    const [data, setdata] = useState([])
    const [connection, setConnection] = useState()
    const [index, setIndex] = useState('Inbox')
    const [settingVal, setSettingVal] = useState('Account details')
    const callShow = useSelector(state => state?.auth?.showCall)
    const incomingcallShow = useSelector(state => state?.auth?.showIncomingCall)
    const detail = useSelector(state => state?.auth?.incominDetail)
    const dispatch = useDispatch()
    const init = async () => {
        await dispatch(getUserDetails())
        await dispatch(getuserInfo())
        await dispatch(getAccessToken())

    }
    connection?.then((call) => {
        call.on("disconnect", () => {
            dispatch({ type: "CALLSHOW", payload: false })
        })
    })


    useEffect(() => {
        init()
    }, [])

    const renderData = (value) => {
        switch (settingVal) {
            case "Account details":
                return (<AccountDetails />)
                break;
            case "Login and security":
                return (<LoginSecurity />)
                break;
            case "Tax details":
                return (<TaxDetails />)
            case "Product catalogue":
                return (<ProductCatalogue />)
                break;
            case 'Connections':
                return (<GoogleBusiness/>)
            case "Terms and Conditions":
                return(<TermsAndConditions/>)
            case 'Pricebook':
                return(<ProductCatalogue/>)
                case 'Facebook leads':
                    return(<FacebookLeads/>)
            default:
                break;
        }
    }
    return (
        <>

            <Body >
             <div style={{ display: 'flex',width:'100%' }}>
                    
                    <SettingList
                        setIndex={(val) => setSettingVal(val)}
                    />
                    {renderData(settingVal)}
                </div>
            </Body>
          
        </>
    )
}

export default Settings

const Body = styled.div`
display:flex;
width:calc(100% - 240px);
overflow-x:scroll;
overflow-y:hidden;
position: fixed;
top: 0;
left: 0;
right: 0;
bottom: 0;
margin-left:240px;


`
const NumberDiv = styled.div`
width:350px;
padding-top:15px;
flex-shrink: 0;
background-color: #DDDDD;
overflow: auto; 
@media (min-width: 260px) and (max-width: 1311px){
    display:none;
}
`