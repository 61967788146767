import React from 'react'
import Connect from '../Components/GoogleBusiness/Connect'

function GoogleBusiness(){
return(
<>
<Connect/>
</>
)
}
export default GoogleBusiness