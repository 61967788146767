import { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getInvoiceData, getQuotedata } from '../store/Actions/Auth.action';
import styled from 'styled-components';
import { changeInvoiceStatus, sendNotification } from '../store/Actions/dashboard.action';
// import { Button } from '@mui/material';
import moment from 'moment'
import { calculateTotalTaxAmount, taxprice, totalprice } from '../utils/Commonfunction';
import { Checkbox } from '@mui/material';
import { Player } from '@lottiefiles/react-lottie-player';
import { palette } from '../utils/Palette';
import { FileDownloadOutlined } from '@mui/icons-material';
import Modals from '../Components/Modal'
import { cross } from '../assets';
import ImageCarousel from '../ReuseableComponets/CuroselImage';
function Quote() {
    let location = useLocation()

    let { id } = useParams()
    //const params = new URLSearchParams(windowUrl);
    const searchParams = new URLSearchParams(location.search);
    const userData = useSelector(state => state?.auth?.userNameData)
    const [photoVisible,setPhotoVisible]=useState(false)
    const [loader, setLoader] = useState(false)
    const [loader1, setLoader1] = useState(false)
    const [imagemodalOpen, setImageModalOpen] = useState(false)
    const [previewURL, setpreviewURL] = useState('')
    const [data, setData] = useState([])
    const [data1, setData1] = useState([])
    //let quote = location.pathname

    let dispatch = useDispatch()
    let navigate = useNavigate();
    useEffect(() => {
        init()
    }, [])
    const init = async () => {
        setLoader(true)
        const res = await dispatch(getInvoiceData({ referenceNo: id }))
        setData(res?.data?.data)
        await dispatch(sendNotification({
            referenceNo: id,
            date: moment(new Date()).format('DD MMM, hh:mm A'),
            invoice: true
        }))
        setLoader(false)


    }
    const quotedata = useSelector(state => state?.auth?.invoice)
    const quoteuserdata = useSelector(state => state?.auth?.userNameData)
    const putCommaInvalue = (price) => {

        const cleanText1 = price?.toString().replace(/,/g, '');
        const formatted = cleanText1?.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        return formatted;
    }
    const taxprices = (price, quantity) => {
        const cleanText = price?.replace(/,/g, '');
        const taxes = parseFloat(cleanText ? cleanText : 0) * parseFloat(quantity ? quantity : 1)

        return taxes;
    }
    const addCustomFiled = async (index, val) => {


        const dataa = [...data?.items]
        if (val.target.checked) {
            dataa[index] = { ...dataa[index], optional: false };
            setData1([...data1, { ...dataa[index], optional: false }])
        }
        else {
            dataa[index] = { ...dataa[index], optional: true };
            const array = data1.filter(item => item._id !== dataa[index]?._id);
            setData1(array)
        }

        console.log(val.target.checked, index, { ...dataa[index], optional: false }, dataa, "index")
        setData({ ...data, items: dataa });


    }
    const getName = (channel) => {
        if (!channel) {
            return
        }
        const val = channel?.split(' ')

        if (val.length == 2) {
            return `${val[0]?.charAt(0).toUpperCase()}${val[1]?.charAt(0).toUpperCase()}`
        }
        else {
            return `${val[0]?.charAt(0).toUpperCase()}`
        }
    }
    const applyDiscount = () => {
        var discountedTotalValue = 0
        let discountPercentage = 0;
        if (quotedata?.discountType === '$') {
            discountedTotalValue = parseFloat(quotedata?.discount);
            discountPercentage = (quotedata?.discount / (flattenItems([...quotedata?.items]).reduce(function (accumulator, currentValue) {
                return accumulator + totalprice(currentValue?.price, currentValue?.quantity);
            }, 0)?.toFixed(2)) * 100)
        }
        else if (quotedata?.discountType === '%') {
            discountedTotalValue = (flattenItems([...quotedata?.items]).reduce(function (accumulator, currentValue) {
                return accumulator + totalprice(currentValue?.price, currentValue?.quantity);
            }, 0)?.toFixed(2) * (quotedata?.discount / 100));
            discountPercentage = parseFloat(quotedata?.discount);
        }

        return discountedTotalValue.toFixed(2);
    };
        const flattenItems = (items) => {
        return items?.reduce((acc, item) => {
            if (item.type === 'Bundle' && Array.isArray(item.lineItems)) {
                acc.push(...item.lineItems);
            } else {
                acc.push(item);
            }
            return acc;
        }, []);
    };
    return (
        <Main>
            {loader ? <div style={{ display: 'flex', height: '100vh', alignItems: 'center', justifyContent: 'center' }}> <Player
                src={require('../assets/loaders.json')}
                className="player"
                style={{ width: '200px', height: '200px' }}
                loop
                autoplay
            />
            </div> :
                <MainContainer>

                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                        {quoteuserdata?.logoImage ? <ProfilePic src={quoteuserdata?.logoImage} /> : <OutProfile>
                            {getName(quoteuserdata?.businessName)}
                        </OutProfile>}
                        <h3>{quoteuserdata?.businessName}</h3>
                    </div>
                    <Container>
                        <p style={{ fontSize: '14px', color: palette.charcoleBlack }}>Invoice #{quotedata?.numberOfInvoice}</p>
                        <h1 style={{ fontWeight: '600', marginTop: '0px', paddingBottom: '15px', width: '100%', textAlign: 'start', borderBottom: '1px solid #ddd' }}>{`$${putCommaInvalue((flattenItems(data?.items)?.reduce(function (accumulator, currentValue) {
                        if (!currentValue?.optional) {
                            return accumulator + totalprice(currentValue?.price, currentValue?.quantity);
                        }
                        return accumulator;
                    }, 0) - applyDiscount()+calculateTotalTaxAmount(flattenItems(data?.items),applyDiscount()))?.toFixed(2))}`}</h1>
                        <CustomButton style={{ background: '#fff', color: palette.charcoleBlack, padding: '0px', width: 'auto', height: 'auto', marginBottom: '10px', alignSelf: 'self-start' }} onClick={() => {
                            const isMobileDevice = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

                            // If it's a mobile device, open the URL in the same window
                            if (isMobileDevice || (navigator.userAgent.includes('Macintosh') && navigator.userAgent.includes('Safari'))) {
                                window.location.href = `https://app.getnotifi.com/previewinvoice/${id}`;
                            } else {
                                // If it's not a mobile device, open the URL in a new tab
                                window.open(`https://app.getnotifi.com/previewinvoice/${id}`, '_blank');
                            }
                            //window.open(`https://app.getnotifi.com/previewinvoice/${id}`);

                        }}><FileDownloadOutlined style={{ marginBottom: -5, marginRight: 5 }} />
                            View detailed invoice
                        </CustomButton>
                        {quotedata?.paymentLink || quotedata?.acceptPaymentOnline ? <CustomButton style={{ marginTop: '10px', border: loader1 ? '1px solid #FAFAFA' : '1px solid #000', background: loader1 ? '#FAFAFA' : 'black' }} onClick={async () => {
                            const arrayOfIds = data1.map(item => item._id);
                            setLoader1(true)
                            const res = await dispatch(changeInvoiceStatus({
                                "optionalId": arrayOfIds,
                                userEmail: userData?.email,
                                "totalAmount": `${flattenItems(data?.items)?.reduce(function (accumulator, currentValue) {
                                    if (!currentValue?.optional) {
                                        return accumulator + totalprice(currentValue?.price, currentValue?.quantity, currentValue?.tax);
                                    }
                                    return accumulator;
                                }, 0)}`,
                                "subTotalAmount": `${flattenItems(data?.items)?.reduce(function (accumulator, currentValue) {
                                    if (!currentValue?.optional) {
                                        return accumulator + totalprice(currentValue?.price, currentValue?.quantity);
                                    }
                                    return accumulator
                                }, 0)}`
                            }, id))
                            setLoader1(false)
                            const isMobileDevice = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

                            // If it's a mobile device, open the URL in the same window
                            if (isMobileDevice || (navigator.userAgent.includes('Macintosh') && navigator.userAgent.includes('Safari'))) {
                                window.location.href = res?.data;
                            } else {
                                // If it's not a mobile device, open the URL in a new tab
                                window.open(res?.data, '_blank');
                            }
                            //window.open(res?.data);
                            init()

                        }}>
                            {loader1 ? <div style={{ display: 'flex', marginTop: '-20px', alignItems: 'center', justifyContent: 'center' }}> <Player
                                src={require('../assets/loaders.json')}
                                className="player"
                                style={{ width: '80px', height: '80px' }}
                                loop
                                autoplay
                            />
                            </div> : "Pay invoice"}

                        </CustomButton> : null}

                    </Container>
                    {quotedata?.notesInvoice && <ContainerRow>
                        <h4 style={{ textAlign: 'start', marginBottom: '10px', marginTop: '0px', fontWeight: '500' }}>Message</h4>
                        <div style={{ textAlign: 'start', color: palette.charcoleBlack }}>{quotedata?.notesInvoice}</div>
                    </ContainerRow>}

                    <ContainerRow>
                        {quotedata?.items?.map((item) => item?.optional ? null : <>
                            {item?.picUrl && <ItemImg onClick={() => {
                                setpreviewURL(item?.picUrl)
                                setImageModalOpen(true)
                            }} src={item?.picUrl} />}
                            <div style={{ display: 'flex', justifyContent: 'space-between', background: '#fff' }}>
                                <div>
                                    <p style={{ fontSize: '14px', textAlign: 'start', fontWeight: '500' }}>{item?.name} × {item?.quantity || '1'}</p>
                                    <p style={{ fontSize: '14px', textAlign: 'start', color: 'gray', marginTop: '-5px' }}>{item?.description}</p>
                                </div>
                                <p style={{ fontSize: '14px', fontWeight: '500' }}>${putCommaInvalue(taxprices(item?.price, item?.quantity)?.toFixed(2))}</p>
                            </div>
                        </>

                        )
                        }
                        {quotedata?.items?.some(item => item.optional === true) ? <p style={{ textAlign: 'start', fontWeight: 'bold' }}>Add on</p> : null}
                        {quotedata?.items?.map((item, index) =>
                            item?.optional ? <>
                                {item?.picUrl && <ItemImg onClick={() => {
                                    setpreviewURL(item?.picUrl)
                                    setImageModalOpen(true)
                                }} src={item?.picUrl} />}
                                <div style={{ display: 'flex', justifyContent: 'space-between', background: '#fff' }}>
                                    <div style={{ display: 'flex', alignItems: 'start' }}>
                                        <Checkbox
                                            key={index}
                                            style={{ marginTop: '5px' }}
                                            // value={item?.email}
                                            // checked={day.includes(item?.email)}
                                            onChange={(val) => { addCustomFiled(index, val) }}

                                        />
                                        <div>
                                            <p style={{ fontSize: '14px', textAlign: 'start', }}>{item?.name} × {item?.quantity || '1'}</p>
                                            <p style={{ fontSize: '14px', textAlign: 'start', color: 'gray', marginTop: '-5px' }}>{item?.description}</p>
                                        </div>
                                    </div>
                                    <p style={{ fontSize: '14px' }}>${putCommaInvalue(taxprices(item?.price, item?.quantity)?.toFixed(2))}</p>
                                </div></> : null

                        )
                        }
                    </ContainerRow>
                    <TotalContainer >
                        <div style={{ display: 'flex', justifyContent: 'space-between', borderBottomWidth: '1px solid #F3F3F3' }}>
                            <p style={{ fontSize: '14px', fontWeight: '500' }}>Subtotal</p>
                            <p style={{ fontSize: '14px', fontWeight: '500' }}>{`$${putCommaInvalue(flattenItems(data?.items)?.reduce(function (accumulator, currentValue) {
                                if (!currentValue?.optional) {
                                    return accumulator + totalprice(currentValue?.price, currentValue?.quantity);
                                }
                                return accumulator
                            }, 0)?.toFixed(2))}`}</p>
                        </div>
                        <TaxDiv>
                            <p style={{ fontSize: '14px', fontWeight: '500' }}>Tax</p>
                            <p style={{ fontSize: '14px', fontWeight: '500' }}>{`$${putCommaInvalue((calculateTotalTaxAmount(flattenItems(data?.items),applyDiscount()))?.toFixed(2))}`}</p>
                        </TaxDiv>
                        <div style={{ display: 'flex', justifyContent: 'space-between', borderBottomWidth: '1px solid #F3F3F3' }}>
                            <p style={{ fontSize: '14px', fontWeight: '500' }}>Discount</p>
                            <p style={{ fontSize: '14px', fontWeight: '500' }}>{`$${applyDiscount()}`}</p>
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'space-between', borderTopWidth: '1px solid #F3F3F3' }}>
                            <p style={{ fontWeight: '500' }}>Total</p>
                            <p style={{ fontWeight: '500' }}>{`$${putCommaInvalue((flattenItems(data?.items)?.reduce(function (accumulator, currentValue) {
                        if (!currentValue?.optional) {
                            return accumulator + totalprice(currentValue?.price, currentValue?.quantity);
                        }
                        return accumulator;
                    }, 0) - applyDiscount()+calculateTotalTaxAmount(flattenItems(data?.items),applyDiscount()))?.toFixed(2))}`}</p>
                        </div>
                    </TotalContainer>
                   {data?.attachment?.length>0&& <ContainerRow>
                    <h4 style={{ textAlign: 'start', marginBottom: '0px',marginTop:'0px' }}>Attachments / Photos</h4>
                    <ValueContainer>
                        <PhotosWrapper>
                            {data?.attachment?.map((item) =>
                                <PhotoContainer onClick={()=>setPhotoVisible(true)}>
                                    <PhotosStyle src={item} />
                                </PhotoContainer>
                            )}
                        </PhotosWrapper>
                    </ValueContainer>
                </ContainerRow>}
                {photoVisible&& <ImageCarousel
                visible={photoVisible}
                images={data?.attachment||[]}
                onClose={()=>setPhotoVisible(false)}
                />}
                </MainContainer>}
            {imagemodalOpen && (
                <Modals
                    isOpen={imagemodalOpen}
                    onClose={() => setImageModalOpen(false)}>
                    <div style={{ display: 'flex', alignItems: 'center', marginTop: '10px' }}>
                        <CloseButton onClick={() => setImageModalOpen(false)}>
                            <Icons src={cross} />
                        </CloseButton>

                    </div>
                    <div style={{ width: '300px', height: 'auto' }}>
                        <Image onClick={() => {
                            window.open(previewURL)
                        }} src={previewURL} />
                    </div>

                </Modals>
            )}
        </Main>
    );
}

export default Quote;

const TaxDiv = styled.div`
display:flex;
justify-content:space-between;
padding-bottom:5px;
border-bottom: 1px solid #ddd; 
`
const Main = styled.div`
display: flex;
  justify-content: center;
 
  background:#FAFAFA;


`
const MainContainer = styled.div`
margin-top:15px;
margin-bottom:15px;
min-height:100vh;
@media (min-width: 820px) and (max-width:9999px)
{ 
    width: 100%;
    max-width: 420px;
}
@media (min-width: 260px) and (max-width: 820px){

    width: 100%;
    padding-left:25px;
    padding-right:25px;
    }

`
const Container = styled.div`
background:#fff;
padding:15px;
padding-left:30px;
padding-right:30px;
padding-bottom:30px;
display: flex;
  justify-content: center;
  align-items:flex-start;
  flex-direction:column;
  border:1px solid #F3F3F3;
  border-radius:12px;
`
const ContainerRow = styled.div`
background:#fff;
padding:15px;
padding-left:30px;
padding-right:30px;
padding-bottom:20px;
margin-top:25px;
  border:1px solid #F3F3F3;
  border-radius:12px;
`
const TotalContainer = styled.div`
background:#fff;
padding:15px;
padding-left:30px;
padding-right:30px;
padding-bottom:20px;
margin-top:25px;
display: flex;
flex-direction:column;
  border:1px solid #F3F3F3;
  border-radius:12px;
`
const CustomButton = styled.button`
width:100%;
height:44px;
border-radius:8px;
border:1px solid white;
align-self:center;
color:white;
font-size:14px;
background:#000;
cursor:pointer;
`
const ProfilePic = styled.img`
color:white;
height:85px;
width:85px;
display:flex;
justify-content:center;
align-items:center;
font-size:14px;
padding-bottom:0px;
font-weight:bold;

`
const OutProfile = styled.div`
background:#2b373f;
color:white;
height:85px;
width:85px;
display:flex;
justify-content:center;
align-items:center;
font-size:20px;
padding-bottom:0px;
border-radius:45px;
font-weight:bold;
`

const ItemImg = styled.img`
background:#fff;
color:white;
width:50px;
margin-bottom:0px;
margin-top:10px;
height:50px;
display:flex;
justify-content:center;
align-items:center;
border-radius:25px;
font-weight:bold;
`
const Image = styled.img`
width:100%;
height:100%;
margin-top:20px;
margin-bottom:20px;

`
const CloseButton = styled.button`

  display:flex;
  align-items:center;
  justify-content:center;
  padding-left:10px;
  border: none;
  background:#ddd;
  margin-top:15px;
  width:30px;
  height:30px;
  align-self:center;
  border-radius:25px;
  font-size: 14px;
  cursor: pointer;
  
  `
const Icons = styled.img`
  width: 20px;
  height: 20px;
  `
  const PhotosWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding-right: 10px;
`;
const PhotoContainer = styled.div`
  flex-wrap: wrap;
  position: relative;
  overflow: hidden;

`;
const PhotosStyle = styled.img`
display:flex;
border-radius:4px;
margin-right:15px;
margin-bottom:10px;
width: 60px;
height: 60px;

`
const ValueContainer = styled.div`
  flex-direction: column;
  margin-top: 10px;
  margin-bottom: 10px;
`;