import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Divider from '@mui/material/Divider';
import styled from "@emotion/styled";
import Checkbox from '@mui/material/Checkbox';

import { palette } from '../utils/Palette';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import RippleButton from './RippleButton';

export default function SelectableMenu({ items, anchorEl, open, onClose,onCancel, selectedvalue, onSelect, onclickButton }) {
    const [selectedItems, setSelectedItems] = useState(items.filter(item => item.isSelected && !item.divider));
    const [unselectedItems, setUnselectedItems] = useState(items.filter(item => !item.isSelected && !item.divider && !item.custom));
    const [unselectedItems1, setUnselectedItems1] = useState(items.filter(item => !item.isSelected && !item.divider && item.custom));

    useEffect(()=>{
        setSelectedItems(items.filter(item => item.isSelected && !item.divider))
        setUnselectedItems(items.filter(item => !item.isSelected && !item.divider && !item.custom))
        setUnselectedItems1(items.filter(item => !item.isSelected && !item.divider && item.custom))
    },[items])
    const Container = styled("div")`
  display: flex;
  background-color: ${props => (props.isDraggingOver ? "#639ee2" : "inherit")};
`;

    const handleSelect = (text) => {
        const selectedItem = selectedItems.find(item => item.text === text);
        if (selectedItem) {
            const updatedSelectedItems = selectedItems.filter(item => item.text !== text);
            const updatedUnselectedItem = { ...selectedItem, isSelected: false };
            if(updatedUnselectedItem?.custom){
                setUnselectedItems1([updatedUnselectedItem, ...unselectedItems1]);
            }
            else{
                setUnselectedItems([updatedUnselectedItem, ...unselectedItems]);
            }
           
            setSelectedItems(updatedSelectedItems);
        }
        else {
            const unselectedItem = unselectedItems.find(item => item.text === text);
            const unselectedItem1 = unselectedItems1.find(item => item.text === text);
            if (unselectedItem) {
                const updatedUnselectedItems = unselectedItems.filter(item => item.text !== text);
                const updatedSelectedItem = { ...unselectedItem, isSelected: true };
                setSelectedItems([...selectedItems, updatedSelectedItem]);
                setUnselectedItems(updatedUnselectedItems);
            }
            else if (unselectedItem1) {
                const updatedUnselectedItems = unselectedItems1.filter(item => item.text !== text);
                const updatedSelectedItem = { ...unselectedItem1, isSelected: true };
                setSelectedItems([...selectedItems, updatedSelectedItem]);
                setUnselectedItems1(updatedUnselectedItems);
            }
        }
    };

    const handleDragEnd = (result) => {
        if (!result.destination) return;

        const draggedItem = selectedItems[result.source.index];
        const remainingItems = [...selectedItems];
        remainingItems.splice(result.source.index, 1);
        remainingItems.splice(result.destination.index, 0, draggedItem);

        setSelectedItems(remainingItems);
    };

    // useEffect(() => {
    //     if (selectedvalue?.length > 0) {
    //         setSelectedItems(selectedvalue)
    //     }
    // }, [selectedvalue])



    return (
        <Menu
            anchorEl={anchorEl}
            open={open}
            onClose={onClose}
            PaperProps={{
                elevation: 0,
                sx: {
                    overflow: 'visible',
                    filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.22))',
                    maxHeight: '70vh',
                    mt: 1.5,
                    '& .MuiAvatar-root': {
                        width: 32,
                        height: 32,
                        ml: -0.5,
                        mr: 1,
                    },
                    '&::before': {
                        content: '""',
                        display: 'block',
                        position: 'absolute',
                        top: 0,
                        right: 14,
                        width: 10,
                        height: 10,
                        bgcolor: 'background.paper',
                        transform: 'translateY(-50%) rotate(45deg)',
                        zIndex: 0,
                    },
                },
            }}
            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        >
            <div style={{ maxHeight: '59vh', overflowY: 'auto' }}>
                <h4 style={{ marginTop: '5px', marginBottom: '5px', marginLeft: '15px' }}>Selected contact</h4>
                <DragDropContext onDragEnd={handleDragEnd}>
                    <Droppable droppableId="selectedItems">
                        {(provided) => (
                            <div
                                {...provided.droppableProps}

                                ref={provided.innerRef}>
                                {selectedItems.map((item, index) => (
                                    item.disable ? (
                                        <MenuItem
                                            key={item.text}
                                            sx={{
                                                color: palette.black,
                                            }}
                                            disabled
                                            style={{ height: '35px', fontSize: '13px', paddingRight: '130px' }}
                                            onClick={() => handleSelect(item.text)}
                                        >
                                            <Checkbox
                                                style={{ marginLeft: '-10px', borderRadius: '4px' }}
                                                size='small'
                                                sx={{
                                                    color: palette.newBlue,
                                                    '&.Mui-checked': {
                                                        color: palette.newBlue,
                                                    },
                                                }}
                                                checked={item.isSelected}
                                            />
                                            {item.icon && <ListItemIcon>{item.icon}</ListItemIcon>}
                                            {item.text}
                                        </MenuItem>
                                    ) : (
                                        <Draggable key={item.text} draggableId={item.text} index={index}>
                                            {(provided, snapshot) => (
                                                <div
                                                    ref={provided.innerRef}
                                                    {...provided.draggableProps}
                                                    {...provided.dragHandleProps}
                                                    style={{
                                                        ...provided.draggableProps.style, top: 'auto !important',
                                                        left: 'auto !important', userSelect: 'none'
                                                    }}


                                                >

                                                    <MenuItem
                                                        sx={{
                                                            color: 'default',
                                                        }}
                                                        ref={provided.innerRef}
                                                        {...provided.draggableProps}
                                                        {...provided.dragHandleProps}

                                                        style={{ height: '35px', fontSize: '13px', paddingRight: '130px' }}
                                                        onClick={() => handleSelect(item.text)}
                                                    >
                                                        <Checkbox
                                                            style={{ marginLeft: '-10px', borderRadius: '4px' }}
                                                            size='small'
                                                            sx={{
                                                                '&.Mui-checked': {
                                                                    color: palette.newBlue,
                                                                },
                                                            }}
                                                            checked={item.isSelected}
                                                        />
                                                        {item.icon && <ListItemIcon>{item.icon}</ListItemIcon>}
                                                        {item.text}
                                                    </MenuItem>
                                                </div>
                                            )}
                                        </Draggable>
                                    )
                                ))}
                                {provided.placeholder}
                            </div>
                        )}
                    </Droppable>
                </DragDropContext>
                <Divider />
                <h4 style={{ marginTop: '15px', marginBottom: '10px', marginLeft: '15px' }}>Contacts </h4>
                {unselectedItems.map((item, index) => (
                    <MenuItem
                        key={index}
                        sx={{
                            color: item.disable ? palette.black : 'default',
                        }}
                        disabled={item.disable ? true : false}
                        style={{ height: '35px', fontSize: '13px', paddingRight: '100px' }}
                        onClick={() => handleSelect(item.text)}
                    >
                        <Checkbox
                            style={{ marginLeft: '-10px', borderRadius: '4px' }}
                            size='small'
                            sx={{
                                color: item.disable ? palette.newBlue : 'default',
                                '&.Mui-checked': {
                                    color: item.disable ? palette.newBlue : palette.newBlue,
                                },
                            }}
                            checked={item.isSelected}
                        />
                        {item.icon && <ListItemIcon>{item.icon}</ListItemIcon>}
                        {item.text}
                    </MenuItem>
                ))}
                <h4 style={{ marginTop: '15px', marginBottom: '10px', marginLeft: '15px' }}>Contact custom field</h4>
                {unselectedItems1.map((item, index) => (
                    <MenuItem
                        key={index}
                        sx={{
                            color: item.disable ? palette.black : 'default',
                        }}
                        disabled={item.disable ? true : false}
                        style={{ height: '35px', fontSize: '13px',paddingRight: '130px'}}
                        onClick={() => handleSelect(item.text)}
                    >
                        <Checkbox
                            style={{ marginLeft: '-10px', borderRadius: '4px' }}
                            size='small'
                            sx={{
                                color: item.disable ? palette.newBlue : 'default',
                                '&.Mui-checked': {
                                    color: item.disable ? palette.newBlue : palette.newBlue,
                                },
                            }}
                            checked={item.isSelected}
                        />
                        {item.icon && <ListItemIcon>{item.icon}</ListItemIcon>}
                        {item.text}
                    </MenuItem>
                ))}
            </div>
            <Divider />
            <div style={{ display: 'flex', justifyContent: 'flex-end', margin: '10px', marginTop: '15px', }}>
                <RippleButton onClick={() => {
                   onCancel()

                }} style={{ background: 'white', color: 'black', border: '1px solid #ddd' }}>Cancel</RippleButton>
                <RippleButton onClick={() => {
                    onclickButton(selectedItems,unselectedItems,unselectedItems1)

                }} style={{ marginLeft: '10px' }}>Apply</RippleButton>
            </div>
        </Menu>
    );
}

SelectableMenu.propTypes = {
    items: PropTypes.arrayOf(
        PropTypes.shape({
            icon: PropTypes.element,
            text: PropTypes.string.isRequired,
            isSelected: PropTypes.bool.isRequired,
            divider: PropTypes.bool,
            disable: PropTypes.bool,
        })
    ).isRequired,
    anchorEl: PropTypes.object,
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onSelect: PropTypes.func.isRequired,
    onclickButton: PropTypes.func.isRequired,
    selectedvalue: PropTypes.array,
    onCancel: PropTypes.func.isRequired,
};

