import React, { useEffect } from "react";
import styled from "styled-components";
import GoogleLogin from 'react-google-login';
import FacebookLogin from 'react-facebook-login';
import { useState } from "react";
function Connect() {
  //const [businessDetails, setBusinessDetails] = useState(null);
  const [jsonData,setJsonData]=useState([])
  const [isconnected,setIsCOnnected]=useState(false)
  const facebookLogin = () => {

    const loginOptions = {
      enable_profile_selector: true,
      return_scopes: true,
      scope: 'email,public_profile',
      auth_type: 'rerequest'
    };
    window.FB.login(loginOptions).then((response) => {
      let array = response.authResponse.grantedScopes.split(',');
      if (array.length === 3) {

      } else {
        alert('You need to provide all the facebook permissions to use the app.');
      }
    })
      .catch((error) => console.error(error));
  }

const init=async()=>{
  const token = await localStorage.getItem("fbToken");
  const tokenVal=JSON.parse(token)
  if(tokenVal?.token){
    setIsCOnnected(true)
  }
  else{
    setIsCOnnected(false)
  }
}

  useEffect(() => {
    init()

    // window.FB.init({

    //   appId: '232761532983156',
    //   xfbml: true,
    // version: 'v3.2'
    // });
    window.gapi.load('client:auth2', () => {
      window.gapi.client.init({
        clientId: '943526723324-3o55fkb05jroufqdffsb7ur94nt11nvf.apps.googleusercontent.com',
        plugin_name: "chats",
        scope: 'https://www.googleapis.com/auth/business.manage'
      }).then(() => {
        //checkConnectionStatus();
      });
    })
  }, []);
  const responseFacebook = async(response) => {
    console.log(response);
    const response1 = await fetch(`https://graph.facebook.com/v12.0/${response.id}/accounts?access_token=${response.accessToken}&api_version=v12.0&access_token_source=TEST_USER`);
    const json = await response1.json();
    console.log(json,"json")
    localStorage.setItem('fbToken',JSON.stringify({
      id:json?.data[0]?.id,
      token:response?.accessToken
    }))
    // const response2 = await fetch(`https://graph.facebook.com/v12.0/${json?.data[0]?.id}?access_token=${response.accessToken}&api_version=v12.0&access_token_source=TEST_USER`);
    // const json = await response1.json();
    // console.log(json,"json")
    // setJsonData(json)
    // fetch(`https://graph.facebook.com/v12.0/${response.id}/leadgen_forms?access_token=${json.access_token}`)
    //   .then(response => response.json())
    //   .then(leadsDat => {
    //     console.log(leadsDat)

    //   })
  }

  const checkConnectionStatus = () => {
    window.gapi.client.request({
      path: 'https://mybusiness.googleapis.com/v4/accounts',
    }).then(response => {
      const { result } = response;
      if (result && result.accounts && result.accounts.length > 0) {
        // setBusinessDetails(result.accounts[0]);
      }
    }).catch(error => {
      console.error(error);
    });
  };
  const responseGoogle = (response) => {
    console.log(response, 'responsee');
    const headers = new Headers({
      Authorization: `Bearer ${response.accessToken}`,
    });
    fetch(`https://mybusiness.googleapis.com/v4/accounts`, { headers, mode: 'cors' })
      .then(response => response.json())
      .then(data => {
        setIsCOnnected(true)
        if (data.accounts && data.accounts.length > 0) {
          //setBusinessDetails(data.accounts[0]);
        }
      })
      .catch(error => {
        console.error(error);
      });
  };

  return (
    <Main>
      <Head>
        {/* <Segment>
          <GoogleLogin
            clientId="943526723324-3o55fkb05jroufqdffsb7ur94nt11nvf.apps.googleusercontent.com"
            buttonText="Connect google business"
            onSuccess={responseGoogle}
            onFailure={responseGoogle}
            redirectUri='http://localhost:3000/google-business'
            scope="https://www.googleapis.com/auth/business.manage"
            accessType="offline"
            prompt="consent"
            uxMode='redirect'
            cookiePolicy={'single_host_origin'}
          />
          <div>
            <Text><Status></Status>Not connected yet</Text>
          </div>

        </Segment> */}
        <Segment>
          <FacebookLogin
            appId="232761532983156"
            autoLoad={true}
            size={'small'}
            callback={responseFacebook}
            fields="first_name, last_name, email"
            scope="public_profile, email,user_friends,pages_messaging"
            returnScopes={true}
            cssClass="fb"
          

          />
          <div>
            <Text style={{color:isconnected&&'green'}}><Status style={{color:isconnected&&'green'}} ></Status>{isconnected?"Connected":"Not connected yet"}</Text>
          </div>
        </Segment>


      </Head>
    </Main>
  )
}
export default Connect


const Main = styled.div`
display:flex;
justify-content:center;
padding-bottom:70px;
margin-bottom:50px;
`
const Head = styled.div`
width:100%;
@media (min-width: 260px) and (max-width: 1311px){
width:100%;
padding-left:30px;
padding-right:30px;
}
`
const Segment = styled.div`
margin:50px;
padding:25px;
border:1px solid lightgray;
display:flex;
justify-content:space-between;
width:100%;
border-radius:10px;
.fb{
  height:35px;
  background:#4169e1;
  color:#fff;
  border:1px solid #4169e1;
  border-radius:8px;
  padding-left:15px;
  padding-right:15px;
  
}
`
const Text = styled.div`
text-align:start;
font-size:14px;
color:gray;
display:flex;
`
const Status = styled.div`
background:lightgray;
border-radius:50%;
height:8px;
width:8px;
margin-top:5px;
margin-right:7px;
`