import React, { memo, useCallback, useEffect, useMemo, useRef } from "react";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import { formatMobileNumber, putCommaInvalue, totalprice } from "../../../utils/Commonfunction";
import {fetchAllLeads, submitchangeStatus } from "../../../store/Actions/dashboard.action";
import { Player } from '@lottiefiles/react-lottie-player';
import FullScreenModal from "../../FullScreenModal";
import RightModal from "../../RightSideModal";
import RightSideConversations from "../RightSideConversation";
import ContactDetails from "./ContactDetails";
import { DateRange } from 'react-date-range'
import format from 'date-fns/format'
import { addDays } from 'date-fns'
import AddContact from "../../Settings/AddContact";
import cross from '../../../assets/cross.png'
import searchs from '../../../assets/searchs.png'
import calenda from '../../../assets/calenda.png'
import 'react-date-range/dist/styles.css'
import 'react-date-range/dist/theme/default.css'
import Modal from "../../Modal";
import moment from "moment";
import { IconButton, InputAdornment, MenuItem, Pagination, Select, TextField, Tooltip } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { palette } from "../../../utils/Palette";
import { Call, MessageOutlined } from "@mui/icons-material";

function LeadTable(props) {
    const popupRef = useRef(null);
    const detail = useSelector(state => state?.auth?.allContacts)
    const [currentPage, setCurrentPage] = useState(1);
    const navigate = useNavigate()
    const [allContact, setAllContact] = useState([])
    const [searchContact, setSearchContact] = useState([])
    const [filter, setFilter] = useState("All leads")
    const ProgressDataColor = [
        { textColor: '#045ad0', backgroundColor: '#cbf5fd' },
        { textColor: '#de8c4e', backgroundColor: '#FFF3E0' },
        { textColor: '#B02A37', backgroundColor: '#F5D7D9' },
        { textColor: '#DC3545', backgroundColor: '#F7D7DA' },
        { textColor: '#28A745', backgroundColor: '#E6F4EA' },
        { textColor: '#FF851B', backgroundColor: '#FFF0E4' }
    ];
    const [search, setSearch] = React.useState('');
    const ProgressData = [
        'Potential', 'In-Progress', 'Qualified', 'Bad fit', 'Customer', 'Not Interested'
    ]
    const [loader, setLoader] = useState(false)
    const [modalOpen, setModalOpen] = useState(false)
    const [isDetailOpen, setIsDetailOpen] = useState(false)
    const [contactDetail, setContactDetail] = useState({})
    const [customerValue, setCustomerValue] = useState({})
    const [popupPosition, setPopupPosition] = useState({ x: 0, y: 0 });
    const [statusModalOpen, setStatusModalOpen] = useState(false)
    const [opendAddContact, setOpenAddContact] = useState(false)
    const [invoice, setInvoice] = useState({})
    const [open, setOpen] = useState(false)
    const containerRef = useRef(null);
    const token = useSelector(state => state?.auth?.token)
    const [range, setRange] = useState([
        {
            startDate: new Date(),
            endDate: addDays(new Date(), 3),
            key: 'selection'
        }
    ])
    const [range1, setRange1] = useState([
        {
            startDate: '',
            endDate: '',
            key: 'selection'
        }
    ])
    const refOne = useRef(null)

    const dispatch = useDispatch()
    const init = async () => {
        setLoader(true)
        let res = await dispatch(fetchAllLeads())
        if (res.status === true) {
            setLoader(false)
            setAllContact(res.data)
            setSearchContact(res.data)


        }
    }

    useEffect(() => {
        init()
    }, [])
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (popupRef.current && !popupRef.current.contains(event.target)) {
                setStatusModalOpen(false);


            }

        };

        document.addEventListener('click', handleClickOutside);

        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, [setStatusModalOpen]);
    useEffect(() => {
        // event listeners
        document.addEventListener("keydown", hideOnEscape, true)
        document.addEventListener("click", hideOnClickOutside, true)
    }, [])

    // hide dropdown on ESC press
    const hideOnEscape = (e) => {
        // console.log(e.key)
        if (e.key === "Escape") {
            setOpen(false)
        }
    }

    // Hide on outside click
    const hideOnClickOutside = (e) => {
        // console.log(refOne.current)
        // console.log(e.target)
        if (refOne.current && !refOne.current.contains(e.target)) {
            setOpen(false)
        }
    }


    const OnsubmitStauts = async (e, status) => {
        console.log(invoice?.invoiceNo, 'dfnjkfdkdjfkjfdjkfdjkn')
        e.stopPropagation()

        const res = await dispatch(submitchangeStatus({
            "numberId": invoice?._id,
            "status": status
        }))
        init()
        setStatusModalOpen(false)
    }

    const searchFilterFunction = (text) => {
        console.log(text, "data");
        if (text) {
            const newData = allContact?.filter((item) => {
                const nameData = item.name ? item.name.toUpperCase() : ''.toUpperCase();
                const numberData = item.number ? item.number.toUpperCase() : ''.toUpperCase();
                const searchText = text.toUpperCase();
                return nameData.includes(searchText) || numberData.includes(searchText);
            });
            setSearchContact(newData);
            setSearch(text);
        } else {
            setSearchContact(allContact);
            setSearch(text);
        }
    };

    const handlePageChange = (event, value) => {
        setCurrentPage(value);
        if (containerRef.current) {
          containerRef.current.scrollTo({
            top: 0,
            behavior: 'smooth',
          });
        }
      };
      const currentData = useMemo(() => {
        const startIndex = (currentPage - 1) * 100;
      
        return searchContact.slice(startIndex, startIndex + 100);
      }, [currentPage, searchContact]);

    const RenderContact = memo(({ item, index }) => {
        return (
            <>

                <TableBody key={index} onClick={(event) => {
                    navigate(`/dashboard/leads/${item?._id}`)


                }} style={{ background: '#fff', cursor: 'pointer' }} >
                    <TableRow style={{}}>
                        <TableCell size='small'>
                            <div style={{ fontWeight: '500' }}>
                                {item?.name}
                            </div>
                        </TableCell>
                        <Tooltip arrow title={`Call ${formatMobileNumber(item?.to || item?.number)}`} placement='top'>
                            <TableCell
                                style={{ width: '15px', }}
                                size='small'
                                onClick={(e) => {
                                    e.stopPropagation()
                                    const call = token.connect({
                                        params: {
                                            to: item?.to || item?.number,
                                            from: `+${localStorage.getItem('number')}`
                                        }
                                    })

                                    dispatch({ type: "CONNECTION", payload: call })
                                    dispatch({
                                        type: "CALLDETAIL", payload: {
                                            name: item?.name,
                                            to: item?.to || item?.number
                                        }
                                    })
                                    dispatch({ type: "CALLSHOW", payload: true })
                                }}
                            >
                                <IconBox>
                                    <Call style={{ fontSize: '14px', color: 'black' }} />
                                </IconBox>
                            </TableCell>
                        </Tooltip>
                        <Tooltip arrow title={`Message  ${item?.name}`} placement='top'>
                            <TableCell
                                onClick={(e) => {
                                    e.stopPropagation()
                                    setCustomerValue({ name: item?.name, number: item?.number })
                                    setModalOpen(true)
                                }}
                                style={{ width: '15px' }}
                                size='small'>
                                <IconBox >
                                    <MessageOutlined style={{ fontSize: '14px', color: 'black' }} />
                                </IconBox>
                            </TableCell>
                        </Tooltip>
                        <TableCell

                            onClick={(event) => {
                                event.stopPropagation()
                                // setStatusIndex(index)
                                setStatusModalOpen(true)
                                setInvoice({ ...item })
                                const rect = event.target.getBoundingClientRect();
                                const containerRect = containerRef.current.getBoundingClientRect();
                                const scrollTop = containerRef.current.scrollTop;
                                const scrollLeft = containerRef.current.scrollLeft;

                                // Calculate the correct position of the popup
                                const posX = rect.left - containerRect.left + scrollLeft;
                                const posY = rect.bottom - containerRect.top + scrollTop;

                                setPopupPosition({ x: posX, y: posY });


                            }}
                            size='small' >
                            <div style={{
                                width: '120px',
                                fontWeight: '500',
                                borderRadius: 5,
                                display: 'flex',
                                textAlign: 'center',
                                justifyContent: 'start',
                                fontSize: '13px'
                            }}>
                                <span style={{ color: item?.status === 'Potential' ? ProgressDataColor[0]?.textColor : item?.status === 'In-Progress' ? ProgressDataColor[1]?.textColor : item?.status === 'Qualified' ? ProgressDataColor[2]?.textColor : item?.status === 'Bad fit' ? ProgressDataColor[3]?.textColor : item?.status === 'Customer' ? ProgressDataColor[4]?.textColor : item?.status === 'Not Interested' ? ProgressDataColor[5]?.textColor : '#000', marginRight: '5px' }}>●</span>  {item?.status}
                            </div>

                        </TableCell>
                        {/* <TableCell
                        style={{ display: 'flex', justifyContent: 'end' }}
                        size='small'>
                        <FlexDiv>
                            <IconBox onClick={(e) => {
                                e.stopPropagation()
                                setCustomerValue({ name: item?.name, number: item?.number })
                                setModalOpen(true)
                            }}>
                                <Icon1 src={convo} alt={convo} />
                            </IconBox>
                            <IconBox
                                onClick={(e) => {
                                    e.stopPropagation()
                                    const call = token.connect({
                                        params: {
                                            to: item?.to || item?.number,
                                            from: `+${localStorage.getItem('number')}`
                                        }
                                    })

                                    dispatch({ type: "CONNECTION", payload: call })
                                    dispatch({
                                        type: "CALLDETAIL", payload: {
                                            name: item?.name,
                                            to: item?.to || item?.number
                                        }
                                    })
                                    dispatch({ type: "CALLSHOW", payload: true })
                                }}
                            >
                                <Icon1 src={calltel} alt={calltel} />
                            </IconBox>
                        </FlexDiv>

                    </TableCell> */}
                        <TableCell size='small'>
                            {formatMobileNumber(item?.number)}

                        </TableCell>

                        <TableCell size='small'>
                            {item?.type || "Manual"}

                        </TableCell>

                        <TableCell size='small'> {item?.createdAt ? moment(item?.createdAt)?.format('DD MMM YYYY') : moment(new Date())?.format('DD MMM YYYY')}</TableCell>

                    </TableRow>

                </TableBody>
                {statusModalOpen && index === 1 && <div style={{ position: 'relative' }}>

                    <StyledPopupContainer ref={popupRef} style={{
                        top: popupPosition.y - 100,
                        left: popupPosition.x,

                    }}>
                        <StyledPopupContent >
                            <StyledPopupItems>

                                {
                                    ProgressData?.map((items, index) =>
                                        <PopupValues onClick={e => {
                                            OnsubmitStauts(e, items)
                                            // setDatadet(item)
                                            // OnsubmitStauts(e, items)
                                        }}>
                                            <a style={{ fontSize: '14px', color: ProgressDataColor[index]?.textColor, marginRight: '8px' }}>{`●  `}</a>
                                            {items}</PopupValues>
                                    )
                                }
                            </StyledPopupItems>

                        </StyledPopupContent>
                    </StyledPopupContainer>


                </div>}
            </>

        )
    }

    )
    const rowItem = useCallback((item, index) => {
        return <RenderContact item={item} index={index} />
    }, [searchContact])
    return (
        <>
            <Body>
                <Flexbox>



                    <CustomTable>
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginLeft: '20px', marginRight: '30px' }}>
                            <h1 style={{ textAlign: 'start' }}>Leads</h1>

                        </div>
                        {loader && !detail?.invoice ? <div style={{ display: 'flex', height: '100%', alignItems: 'center', justifyContent: 'center' }}> <Player
                            src={require('../../../assets/loaders.json')}
                            className="player"
                            style={{ width: '200px', height: '200px' }}
                            loop
                            autoplay
                        />
                        </div>

                            :
                            <div style={{ padding: 20 }}>
                                {/* <div style={{ display: 'flex', width: '100%', marginBottom: '20px' }}>
                                {["All leads", ...ProgressData]?.map((item) => <ContainerBox
                                    onClick={() => {
                                        setFilter(item)
                                        if (item == 'All leads') {
                                            setSearchContact(allContact)
                                        }
                                        else {
                                            const data = allContact?.filter(items => items?.status === item)
                                            setSearchContact(data)
                                        }
                                        // setSearchInvoice(detail?.quote)
                                    }}
                                    style={{ border: filter === item ? '1.5px solid #4169e1' : '1px solid #bbb', color: filter === item ? '#4169e1' : '#000' }}>{item}</ContainerBox>
                                )
                                }
                            </div> */}
                                <div style={{ display: 'flex', width: '100%', marginBottom: '20px', alignItems: 'center', justifyContent: 'space-between' }}>

                                    <CustomTextField
                                        placeholder="Search"
                                        size="small"
                                        value={search}
                                        onChange={(e) => searchFilterFunction(e.target.value)}
                                        InputProps={{
                                            style: {

                                                paddingLeft: '10px',
                                                borderRadius: '8px',
                                                fontSize: '14px',
                                                width: '250px',
                                                marginTop: '-4px'
                                            },
                                            startAdornment: (
                                                <CustomInputAdornment position="start">
                                                    <CustomIconButton>
                                                        <Icons style={{ width: '15px', height: '15px' }} src={searchs} />
                                                    </CustomIconButton>
                                                </CustomInputAdornment>
                                            ),

                                        }}
                                    />
                                    <div style={{ position: 'relative', display: 'flex' }}>
                                        <div style={{ display: 'flex', border: '1px solid #bbb', alignItems: 'center', height: '35px', borderRadius: '8px', marginLeft: '25px', paddingLeft: '15px', paddingRight: '15px' }}>
                                            <Icons style={{ width: '18px', height: '18px', marginRight: '10px' }} src={calenda} />
                                            <CustomUrl
                                                value={range1[0]?.startDate ? `${format(range1[0]?.startDate, "MMM dd")} - ${format(range1[0].endDate, "MMM dd, yyyy")}` : 'Select date'}
                                                readOnly
                                                className="inputBox"

                                                onClick={() => setOpen(open => !open)}
                                            />
                                        </div>


                                        {open &&
                                            <>
                                                <StyleBox style={{ position: 'absolute', left: 0, marginTop: '50px', marginLeft: '10px', background: 'white' }} ref={refOne}>
                                                    <DateRange
                                                        style={{ height: '220px' }}
                                                        onChange={item => setRange([item.selection])}
                                                        editableDateInputs={true}
                                                        moveRangeOnFirstSelection={false}

                                                        ranges={range}
                                                        months={1}
                                                        direction="horizontal"
                                                        className="calendarElement"
                                                    />
                                                    <ButtonBox>
                                                        <SendButton1 style={{ background: '#F3F3F3', color: '#000', marginRight: '15px' }} onClick={() => {
                                                            setOpen(false)
                                                            setRange1([{
                                                                startDate: '',
                                                                endDate: ''
                                                            }])
                                                            setRange([
                                                                {
                                                                    startDate: new Date(),
                                                                    endDate: addDays(new Date(), 3),
                                                                    key: 'selection'
                                                                }
                                                            ])
                                                            setSearchContact(allContact)
                                                            setFilter('All leads')
                                                        }}>Clear</SendButton1>
                                                        <SendButton1 style={{ marginRight: -15 }} onClick={async () => {
                                                            setOpen(false)
                                                            setRange1([{
                                                                startDate: range?.[0]?.startDate,
                                                                endDate: range?.[0]?.endDate
                                                            }])
                                                            const filteredData = allContact.filter(item => {
                                                                const itemDate = moment(item.createdAt).format('YYYY-DD-MM');
                                                                return itemDate >= moment(range?.[0]?.startDate)?.format('YYYY-DD-MM') && itemDate <= moment(range?.[0]?.endDate)?.format('YYYY-DD-MM');
                                                            });
                                                            setSearchContact(filteredData)
                                                            // const res = await dispatch(createNewCustomField({
                                                            //     "fields": [
                                                            //         newCutomField
                                                            //     ]
                                                            // }))
                                                            // setDataCustomField([...customField, newCutomField])
                                                            // setIsModalOpen1(false)
                                                        }}>Apply</SendButton1>
                                                    </ButtonBox>
                                                </StyleBox>
                                            </>
                                        }

                                        <Selectbox
                                            style={{ marginTop: '0px', borderRadius: '8px', border: '1px solid #f3f3f3', fontSize: '14px' }}
                                            placeholder='select' value={'Select an option'} displayEmpty
                                            renderValue={() => filter} onChange={(e) => {
                                                console.log(e.target)

                                            }}
                                            MenuProps={{ // Add MenuProps to customize menu styling
                                                PaperProps: {
                                                    style: {
                                                        marginTop: '8px', // Adjust this value for the desired gap
                                                    },
                                                },
                                            }}
                                        >
                                            {["All leads", ...ProgressData]?.map((item, index) =>
                                                <StyledMenuItem key={index} value={index}>
                                                    <FilterBox
                                                        onClick={() => {
                                                            setFilter(item)
                                                            if (item == 'All leads') {
                                                                setSearchContact(allContact)
                                                            }
                                                            else {
                                                                const data = allContact?.filter(items => items?.status === item)
                                                                setSearchContact(data)
                                                            }
                                                            // setSearchInvoice(detail?.quote)
                                                        }}
                                                        style={{ color: filter === item ? '#4169e1' : '#000' }}>


                                                        {item}</FilterBox>
                                                </StyledMenuItem>
                                            )}
                                        </Selectbox>
                                    </div>

                                </div >

                                <div ref={containerRef} style={{ overflowY: 'auto', height: '84vh' }}>
                                    <Table sx={{ width: '100%' }} aria-label="simple table">
                                        <CustomHead>
                                            <TableRow >

                                                <TableCell size='small'>
                                                    <div >
                                                        Customer name
                                                    </div>
                                                </TableCell>
                                                <TableCell style={{ width: '15px' }} size="small">

                                                </TableCell>
                                                <TableCell style={{ width: '15px' }} size="small">

                                                </TableCell>
                                                <TableCell style={{ width: '120px', position: 'relative', color: palette.black, fontWeight: '700' }} size="small">
                                                    Status
                                                </TableCell>

                                                <TableCell style={{ position: 'relative', color: palette.black, fontWeight: '700' }} size="small">
                                                    Phone number
                                                </TableCell>
                                                <TableCell style={{ position: 'relative', color: palette.black, fontWeight: '700' }} size='small'>Source</TableCell>
                                                <TableCell style={{ position: 'relative', color: palette.black, fontWeight: '700' }} size='small'>Date</TableCell>


                                            </TableRow>
                                        </CustomHead>


                                        {currentData?.map((item, index) =>
                                            rowItem(item, index)
                                        )}

                                    </Table>
                                    {searchContact?.length > 90 && <Pagination
                                        count={Math.ceil(searchContact.length / 100)}
                                        page={currentPage}
                                        onChange={handlePageChange}
                                        sx={{
                                            display: 'flex', justifyContent: 'center',
                                            marginTop: '50px', marginBottom: '100px',
                                            '& .MuiPaginationItem-root': {
                                                color: '#000',
                                            },
                                            '& .MuiPaginationItem-root.Mui-selected': {
                                                backgroundColor: '#0F52BA',
                                                color: '#fff',
                                            },
                                        }}
                                    />}
                                </div>
                            </div>
                        }

                    </CustomTable>

                </Flexbox>
                {isDetailOpen && <FullScreenModal
                    isOpen={modalOpen}
                    onClose={() => setModalOpen(false)}
                >
                    <div style={{ display: 'flex' }}>
                        <ContactDetails
                            setModal={() => setIsDetailOpen(false)}
                            value={contactDetail}
                            setConnection={(call) => {
                                props?.setConnection(call)
                            }}
                        />
                    </div>
                </FullScreenModal>}

                {modalOpen && <RightModal
                    customStyle={{ backgroundColor: 'white' }}
                    isOpen={modalOpen}
                    onClose={() => setModalOpen(false)}
                >
                    <div style={{ height: '100%', display: 'flex' }}>
                        <RightSideConversations setConnection={(call) => {
                            // setConnection(call)
                        }} value={customerValue} />
                    </div>
                </RightModal>}
                {opendAddContact && <Modal
                    onClose={() => setOpenAddContact(false)}
                >
                    <div style={{ display: 'flex', justifyContent: 'space-between', height: 'auto' }}>
                        <h2>Add contact</h2>

                        <CloseButton onClick={() => setOpenAddContact(false)}>
                            <Icons src={cross} />
                        </CloseButton>
                    </div>
                    <AddContact
                        onCancel={() => {
                            init()
                            setOpenAddContact(false)
                        }}
                    />
                </Modal>}
            </Body>
        </>
    )


}


export default LeadTable;
const Body = styled.div`
display:flex;
width:calc(100% - 240px);
overflow-x:scroll;
overflow-y:hidden;
position: fixed;
top: 0;
left: 0;
right: 0;
bottom: 0;
margin-left:240px;


`
const StyledPopupContent = styled.div`
    background-color: #FAFAFA;
    box-shadow: 0 12px 48px rgba(32, 40, 77, 0.1), 0 32px 80px rgba(32, 40, 77, 0.1);
    opacity: 1.0;
    border-radius: 8px;
    width: 200px;
    background: white;
    position: absolute;
    top: 0px;
    left: 0px;
    z-index: 20000;
`

const StyledPopupItems = styled.div`
   
    display:flex;
flex-direction:column;
 
    justify-content:center;
    align-items:start;
`
const PopupValues = styled.div`

display:flex;
align-items:center;
border-bottom:1px solid #f3f3f3;
font-size:13px;
width:91%;
padding:10px;
cursor:pointer;
&:hover {
    background:#f3f3f3;
  }

`
const StyledPopupContainer = styled.div`
    z-index: 1500;
    position: absolute;
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-content: stretch;
    align-items: center;
`
const PDFContainer = styled.div`
border:1px solid #f3f3f3;
display:flex;
justify-content:center;
color:#000;
padding-top:5px;
padding-bottom:5px;
border-top-left-radius: 5px;
border-bottom-left-radius: 5px; 
background:#fff;
padding:5px;
&:hover {
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.2); 
  }
`
const DotContainer = styled.div`
border:1px solid #f3f3f3;
display:flex;
justify-content:center;
align-items:center;
color:#000;
padding-top:5px;
padding-bottom:5px;
border-top-right-radius: 5px;
border-bottom-right-radius: 5px; 
padding:5px;
background:#fff;
&:hover {
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.2); 
  }
`
const CustomTable = styled(TableContainer)`
  &::-webkit-scrollbar {
    display: none; /* Hide the scrollbar */
  }
  border-color: red;

  .MuiTableBody-root {
   
    font-family: Inter;
    font-size: 13px;
  }

  .MuiTableHead-root {
    font-weight: 700;
    font-family: Inter;
    font-size: 14px;
    color:${palette.black};
  }

  .MuiTableRow-root {
    height: 35px;
   
    font-family: Inter;
    font-size: 13px;
    &:hover {
      background: #f8f8f8;
    }
  }

  .MuiTableCell-root {
    border-color: #f3f3f3;
    border-right:1px solid #f3f3f3;
    height:10px;
    font-family: Inter;
    white-space: nowrap;
    word-break: break-word;
    border-collapse: separate;
    line-height: 16px;
    color:${palette.black};
    font-size: 13px;
    font-weight:450;
  }
  .sticky {
    position: sticky;
    left: 0;
    z-index: 2;
    background: white;
  
  }
  .MuiTableCell-root:first-child {
    position: sticky;
    left: 0;
    background: #fff;
    border-left:1px solid #f3f3f3;
    font-weight:700;
    color:${palette.newBlue};
    z-index: 1;
    min-width:150px;
 
  }
  .MuiTableRow-root:hover .MuiTableCell-root:first-child {
    background:#f8f8f8;
  }

  .purple {
    background: #974EDE;
  }

  .green {
    background: #32cd32;
  }

  .dimgreen {
    background: #008000;
  }
`;

const CustomHead = styled(TableHead)`
position: sticky;
  top: 0;
  background-color: white;
  z-index: 2;
border-radius:8px;
font-size:14px;
color:${palette.black};
border-top:1px solid #f3f3f3;
color:#000;
height:10px;
flex-wrap:wrap;
background:#f9f9f9;
.MuiTableCell-root:first-child {
  position: sticky;
  background:#f9f9f9;
  color:${palette.black};
  z-index: 1;
  min-width:150px;

}
`
const Flexbox = styled.div`
width:100%;
background:#fff;
display:flex;
@media (min-width: 260px) and (max-width: 1300px){

    flex-shrink: 0;
overflow: auto; 
}
`
const FilterBox = styled.div`
width:100%;
height:35px;
border-radius:8px;
display:flex;
font-size:14px;

align-items:center;

font-weight:500;
cursor:pointer;

`

const Icon1 = styled.img`
height:15px;
width:15px;


`
const FlexDiv = styled.div`
display:flex;
align-items:center;
`
const IconBox = styled.div`
display:flex;
justify-content:center;
align-items:center;
padding-top:4px;
padding-bottom:4px;
border-radius:5px;
color:white;
`
const OptionBox = styled.div`
width:150px;
height:35px;
border-radius:8px;
font-weight:600;
font-size:14px;
border:1px solid #bbb;

display:flex;
align-items:center;
justify-content:center;

cursor:pointer;
&:hover {
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.2); 
}

`
const CloseButton = styled.button`
background:transparent;
display:flex;
align-items:center;
justify-content:center;
border: none;
background:#ddd;
width:30px;
height:30px;
align-self:center;
border-radius:20px;
font-size: 16px;
cursor: pointer;

`
const Icons = styled.img`
width: 20px;
height: 20px;
`
const CustomTextField = styled(TextField)`
border-radius: 8px;
&& {
   
    height: 30px; /* Adjust the height as needed */
    padding: 5px; /* Adjust the padding as needed */
}
`;

const CustomInputAdornment = styled(InputAdornment)`
  
`;

const CustomIconButton = styled(IconButton)`
 
`;
const Selectbox = styled(Select)`
width:200px;
border-radius:8px;
text-align:start;
margin-left:15px;
display:flex;
align-self:flex-start;
justify-content:flex-start;
border:1px solid #f3f3f3;
background:white;
height:38px;
font-size:14px;
 margin-top:10px;


.MuiMenuItem-root:hover {
    background: #dddddd;
    border-radius: 4px;
  }
}

`
const StyledMenuItem = styled(MenuItem)`
display:flex;
align-items:center;
border-bottom:1px solid #f3f3f3;
align-self:start;

  :hover {
    background: #dddddd;
    border-radius: 5px;
  }
`
const CustomUrl = styled.input`
background: #FFF;
border-radius: 8px;
cursor:pointer;
border: 1px solid #fff;
height: 30px;

&:focus {
    outline: none;
    border: none; /* Remove border on focus */
  }
`
const StyleBox = styled.div`
display:flex;
justify-content:flex-start;
flex-direction:column;
border:1px solid #f3f3f3;
background:white;
width:350px;
margin-top:30px;
border-radius:8px;
box-shadow: 0px 4px 16px rgba(30, 30, 30, 0.1);

.keyContainer{
    padding: 10px 15px 10px 15px;
    font-size:16px;
    font-weight:600;
display:flex;
justify-content:space-between;
border-bottom:1px solid #f3f3f3;
align-items:center;


}
.valueContainer{
    padding:15px;
    background:#fff;
    font-size:14px;
display:flex;
}
`
const ButtonBox = styled.div`
align-self:flex-end;
display:flex;
margin-right:20px;
 margin-top:10px;
margin-bottom:15px;
justify-content:flex-end; 

`
const SendButton1 = styled.button`
border:1px solid #4169e1;
 background:#4169e1; 
color:#fff; 
margin-top:15px;
width:100px;
height:38px;
border-radius:8px;
border:1px solid white;
color:white;
font-size:14px;
margin-bottom:10px;
cursor:pointer;

`
