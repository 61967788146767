import React, { useEffect, useRef } from "react";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import { formatMobileNumber, totalprice } from "../../../utils/Commonfunction";
import { changePaymentMethod, getBookings, submitBookingStatus } from "../../../store/Actions/dashboard.action";
import { Player } from '@lottiefiles/react-lottie-player';
import searchs from '../../../assets/searchs.png'
import moment from 'moment'
import Modal from "../../Modal";
import { sendMessage } from "../../../store/Actions/Auth.action";
import FullScreenModal from "../../FullScreenModal";
import PreviewInvoice from "../../../Pages/PreviewInvoice";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import money from '../../../assets/cash.png';
import atmcard from '../../../assets/card.png';
import moneyTransfer from '../../../assets/onlineTransfer.png';
import CreateBooking from "../CreateBooking";
import { IconButton, InputAdornment, MenuItem, Select, TextField } from "@mui/material";
import { useTheme, Tooltip } from "@mui/material";
import 'react-date-range/dist/styles.css'
import 'react-date-range/dist/theme/default.css'
import { DateRange } from 'react-date-range'
import format from 'date-fns/format'
import { addDays } from 'date-fns'
import CustomizedMenu from "../../../ReuseableComponets/MenuComponent";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import UnScheduledJobs from "../../../Pages/unScheduledJobs";
import { palette } from "../../../utils/Palette";
import { LockOpen, PauseCircleOutline, Refresh, Check, Map, CalendarMonth, NorthOutlined, SouthOutlined, VisibilityOffOutlined } from '@mui/icons-material'

function ServiceRequestList(props) {
    const location = useLocation()
    const popupRef = useRef(null);
    const detail = useSelector(state => state?.auth?.invoiceQuote)
    const userdata = useSelector(state => state?.auth?.userdata)
    const [searchParams, setSearchParams] = useSearchParams();
    const [loader, setLoader] = useState(false)
    const [modalOpen, setModalOpen] = useState(false)
    const [isDownloadOpen, setIsDownloadOpen] = useState(false)
    const [isEdit, setIsEdit] = useState(false)
    const [datadet, setDatadet] = useState({})
    const [invoice, setInvoice] = useState({})
    const [allData, setAllData] = useState([])
    const navigate = useNavigate()
    const [searchinvoice, setSearchInvoice] = useState([])
    const [tabData, setTabData] = useState([])
    const [filters, setFilters] = useState("")
    const [popupPosition, setPopupPosition] = useState({ x: 0, y: 0 });
    const [popupPositionDot, setPopupPositionDot] = useState({ x: 0, y: 0 });
    const [filter, setFilter] = useState("All request")
    const [statusModalOpen, setStatusModalOpen] = useState(false)
    const [statusDotOpen, setDotModalOpen] = useState(false)
    const [statusIndex, setStatusIndex] = useState(0)
    const [mapModal, setMapModal] = useState(false)
    const [team, setTeam] = React.useState([])
    const [open, setOpen] = useState(false)
    const [search, setSearch] = React.useState('');
    const containerRef = useRef(null);
    const [range, setRange] = useState([
        {
            startDate: new Date(),
            endDate: addDays(new Date(), 3),
            key: 'selection'
        }
    ])
    const [range1, setRange1] = useState([
        {
            startDate: '',
            endDate: '',
            key: 'selection'
        }
    ])
    const refOne = useRef(null)
    const statusData = [{ name: "Open", img: LockOpen, value: 'Waiting for confirmation' },
    { name: "On hold", img: PauseCircleOutline, value: 'On Hold' },
    { name: "In Progress", img: Refresh, value: 'In Progress' },
    { name: "Completed", img: Check, value: 'Completed' }]
    const theme = useTheme();

    const [value, setValue] = React.useState(0);

    const dotData = [
        {
            name: 'Send Receipt',
            summary: 'Send Receipt',
            image: money
        },
        {
            name: 'Delete Invoice',
            summary: 'Delete Invoice',
            image: atmcard
        },
        {
            name: 'Send Invoice',
            summary: 'Send Invoice',
            image: moneyTransfer
        },
    ]
    const dispatch = useDispatch()
    const init = async () => {

        setFilter('All request')
        setLoader(true)
        let res = await dispatch(getBookings())
        if (res.status === true) {
            setLoader(false)
            const data = [...res.data?.present, ...res.data?.past]
            data.sort((a, b) => {
                const dateA = a.date ? new Date(a.date) : new Date(a.createdAt);
                const dateB = b.date ? new Date(b.date) : new Date(b.createdAt);
                return dateB - dateA;
            });
            data.sort((a, b) => {
                const dateA = new Date(a.createdAt);
                const dateB = new Date(b.createdAt);
                return dateB - dateA;
            })
            setTabData(data)
            setValue(0)
            setSearchInvoice(data)
            setAllData(data)
            setTeam(res?.data?.team)
        }

        const invoiceParam = searchParams.get('request');
        const invoiceParam1 = searchParams.get('id');
        if (invoiceParam1) {
            setModalOpen(true)
            setIsEdit(true)
            setInvoice({ referenceNo: invoiceParam1 })
        }
        if (invoiceParam === 'true') {
            setModalOpen(true)
        }
    }

    useEffect(() => {
        init()
    }, [])
    useEffect(() => {
        // event listeners
        document.addEventListener("keydown", hideOnEscape, true)
        document.addEventListener("click", hideOnClickOutside, true)
    }, [])

    // hide dropdown on ESC press
    const hideOnEscape = (e) => {
        // console.log(e.key)
        if (e.key === "Escape") {
            setOpen(false)
        }
    }

    // Hide on outside click
    const hideOnClickOutside = (e) => {
        // console.log(refOne.current)
        // console.log(e.target)
        if (refOne.current && !refOne.current.contains(e.target)) {
            setOpen(false)
        }
    }
    const sendchat = async (val, data) => {

        await dispatch(sendMessage(
            {
                "to": data?.to,
                "from": `+${localStorage.getItem('number')}`,
                "body": val
            }
        ))



    }
    const printDocument = async () => {
        const input = document.getElementById('divToPrint');
        await html2canvas(input, { scale: 2 }) // Adjust scale as needed
            .then(async (canvas) => {
                const imgData = canvas.toDataURL('image/jpeg', 1.0); // Use JPEG format
                const pdf = new jsPDF('p', 'mm', 'a4');
                const imgWidth = pdf.internal.pageSize.getWidth();
                const imgHeight = (canvas.height * imgWidth) / canvas.width;
                pdf.addImage(imgData, 'JPEG', 0, 0, imgWidth, imgHeight);
                pdf.save("download.pdf");
            })
            .catch((error) => {
                console.log('Error generating PDF: ' + error.message);
            });
    };
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (popupRef.current && !popupRef.current.contains(event.target)) {
                setStatusModalOpen(false);
                setDotModalOpen(false)

            }

        };

        document.addEventListener('click', handleClickOutside);

        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, [setStatusModalOpen]);

    const OnsubmitStauts = async (e, status) => {
        console.log(invoice?.invoiceNo, 'dfnjkfdkdjfkjfdjkfdjkn')
        e.stopPropagation()

        const res = await dispatch(changePaymentMethod({ "val": status?.name, "invoiceNo": invoice?.invoiceNo }))
        init()
        setStatusModalOpen(false)
    }
    const onClickDot = async (e, val, item) => {

        if (val == 'Send Invoice') {
            sendchat(`Please find the invoice attached from ${userdata?.businessName} for $${invoice?.items?.reduce(function (accumulator, currentValue) {
                return accumulator + totalprice(currentValue?.price, currentValue?.quantity, currentValue?.tax);
            }, 0)?.toFixed(2)}. https://app.getnotifi.com/invoice/${invoice?.invoiceNo}`, { "phoneNumber": item?.customerPhoneNumber })
        }
        else if (val == "Delete Invoice") {
            const res = await dispatch(changePaymentMethod({ "val": "Void", "invoiceNo": invoice?.invoiceNo }))
            init()

        }
        else {
            sendchat(`Please see the attached receipt for $${invoice?.items?.reduce(function (accumulator, currentValue) {
                return accumulator + totalprice(currentValue?.price, currentValue?.quantity, currentValue?.tax);
            }, 0)?.toFixed(2)}. https://app.getnotifi.com/receipt/${invoice?.invoiceNo}`, { "phoneNumber": item?.customerPhoneNumber })
        }
        setDotModalOpen(false)
    }

    const bookingStatus = async (value) => {
        const data = {
            "referenceNo": invoice?.referenceNo,
            "bookingStatus": value
        }

        const res = await dispatch(submitBookingStatus(data))
        if (res.status === true) {
            init()
            setStatusModalOpen(false)
        }
    }

    const searchFilterFunction = (text) => {
        console.log(text, "data");

        if (text) {

            const newData = allData?.filter((item) => {
                const nameData = item.name ? item.name.toUpperCase() : ''.toUpperCase();
                const numberData = item.customerNumber ? item.customerNumber.toUpperCase() : ''.toUpperCase();
                const searchText = text.toUpperCase();
                return nameData.includes(searchText) || numberData.includes(searchText);
            });

            setSearchInvoice(newData)
            setTabData(newData)
            setSearch(text);
        } else {
            setFilter('All request')
            setSearchInvoice(allData);
            setTabData(allData)
            setSearch(text);
        }
    };

    const menuItemsc = [
        {
            label: 'Sort ascending',
            icon: <NorthOutlined style={{ fontSize: '18px' }} />,
            onClick: () => console.log('Edit clicked'),

        },
        {
            label: 'Sort descending',
            icon: <SouthOutlined style={{ fontSize: '18px' }} />,
            onClick: () => console.log('Duplicate clicked'),
        },
        {
            divider: true,
        },
        {
            label: 'Hide column',
            icon: <VisibilityOffOutlined style={{ fontSize: '18px' }} />,
            onClick: () => console.log('Archive clicked'),
            disabled: true
        },
    ];
    const onClickRequiredHeader = async (lab, item) => {
        if (lab === 'Sort ascending') {
            const sorted = [...searchinvoice]
            const allcontc = [...allData]
            sorted.sort((a, b) => a.name.localeCompare(b.name));
            allcontc.sort((a, b) => a.name.localeCompare(b.name));
            setSearchInvoice(sorted)
            setTabData(sorted)
            setAllData(allcontc)
        }
        else if (lab === 'Sort descending') {
            const sorted = [...searchinvoice]
            const allcontc = [...allData]
            sorted.sort((a, b) => b.name.localeCompare(a.name));
            allcontc.sort((a, b) => b.name.localeCompare(a.name));
            setSearchInvoice(sorted)
            setTabData(sorted)
            setAllData(allcontc)
        }
        else {
            //   const removeItem = [...selectedItems]
            //   const index = removeItem.filter(items => items.text !== item?.text);
            //   const res = await dispatch(saveTableFilter({
            //     "filterData": index
            //   }))
            //   if (res.status === true) {
            //     init()
            //   }
            //   setSelectedItems(index)
            // 
        }

    }

    const renderSchedule = () => {
        return (
            <div ref={containerRef} style={{ overflowY: 'auto', height: '89vh', paddingBottom: '120px' }}>
                <Table sx={{ width: '100%' }} aria-label="simple table">
                    <CustomHead>
                        <TableRow >

                            <TableCell size='small'>
                                <div >
                                    Customer  name

                                </div>
                                <CustomizedMenu
                                    menuItems={menuItemsc}
                                    onClick={(lab) => onClickRequiredHeader(lab)}
                                />
                            </TableCell>
                            <TableCell style={{ position: 'relative', color: palette.black, fontWeight: '700' }} size="small">
                                Status
                            </TableCell>
                            <TableCell style={{ minWidth: '120px', position: 'relative', color: palette.black, fontWeight: '700' }} size='small'>

                                Phone number

                            </TableCell>

                            <TableCell style={{ position: 'relative', color: palette.black, fontWeight: '700' }} size='small'>Date</TableCell>
                            <TableCell style={{ position: 'relative', color: palette.black, fontWeight: '700' }} size='small'>Time</TableCell>
                            <TableCell style={{ position: 'relative', color: palette.black, fontWeight: '700' }} size="small">
                                Job status
                            </TableCell>
                            <TableCell style={{ minWidth: '120px', position: 'relative', color: palette.black, fontWeight: '700' }} size='small'>Address</TableCell>

                        </TableRow>
                    </CustomHead>
                    {tabData?.map((item, index) => <>

                        <TableBody key={index} onClick={(event) => {
                            navigate(`/dashboard/servicerequests/${item?.referenceNo}`)



                        }} style={{ background: '#fff', cursor: 'pointer' }} >
                            <TableRow>
                                <TableCell size='small'>

                                    {item?.name}

                                </TableCell>
                                <TableCell

                                    onClick={(event) => {
                                        event.stopPropagation()
                                        setStatusIndex(index)
                                        setStatusModalOpen(true)
                                        setInvoice({ ...item })
                                        const rect = event.target.getBoundingClientRect();
                                        const containerRect = containerRef.current.getBoundingClientRect();
                                        const scrollTop = containerRef.current.scrollTop;
                                        const scrollLeft = containerRef.current.scrollLeft;

                                        // Calculate the correct position of the popup
                                        const posX = rect.left - containerRect.left + scrollLeft;
                                        const posY = rect.bottom - containerRect.top + scrollTop;

                                        setPopupPosition({ x: posX, y: posY });


                                    }}
                                    size='small'>
                                    <div style={{

                                        width: '90px',
                                        fontWeight: '500',
                                        borderRadius: 5,
                                        display: 'flex',
                                        textAlign: 'center',
                                        justifyContent: 'start',
                                        // color: item?.bookingStatus === 'Waiting for confirmation' ? '#045ad0' : item?.bookingStatus === 'In Progress' ? '#29740f' : item?.bookingStatus === 'Completed' ? '#004D4D' : '#fa811f',
                                        fontSize: '13px',
                                        // background: item?.bookingStatus === 'Waiting for confirmation' ? '#cbf5fd' : item?.bookingStatus === 'In Progress' ? '#d1fab3' : item?.bookingStatus === 'Completed' ? '#AFF0EE' : '#fee6c8',
                                        // border: item?.bookingStatus === 'Waiting for confirmation' ? '1px solid #045ad0' : item?.bookingStatus === 'In Progress' ? '1px solid #29740f' : item?.bookingStatus === 'Completed' ? '1px solid #004D4D' : '1px solid #fa811f',
                                    }}>
                                        <span style={{ color: item?.bookingStatus === 'Waiting for confirmation' ? '#045ad0' : item?.bookingStatus === 'In Progress' ? '#29740f' : item?.bookingStatus === 'Completed' ? '#004D4D' : '#fa811f', marginRight: '5px' }}>●</span>  {item?.bookingStatus === 'Waiting for confirmation' ? 'Open' : item?.bookingStatus}
                                    </div>

                                </TableCell>
                                <TableCell size='small'>
                                    {formatMobileNumber(item?.customerNumber)}

                                </TableCell>

                                <TableCell style={{
                                    maxWidth: '100px',
                                }} size='small'>
                                    {moment(item?.date).format('hh:mm A') === 'Invalid date' ? item?.date : moment(item?.date).format('DD-MMM YYYY')}

                                </TableCell>
                                <TableCell style={{
                                    maxWidth: '100px',
                                }} size='small'>
                                    {moment(item?.time).format('hh:mm A') === 'Invalid date' ? item?.time : moment(item?.time).format('hh:mm A')}
                                </TableCell>
                                <TableCell style={{
                                    maxWidth: '100px', fontWeight: !item?.date && '500'
                                }} size='small'>
                                    {item?.date ? "Scheduled" : 'Need scheduling'}
                                </TableCell>
                                <TableCell style={{
                                    maxWidth: '250px',
                                }} size='small'>
                                    <div style={{
                                        minWidth: '130px',
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                        lineHeight: '16px',
                                        fontSize: '13px',
                                    }}>
                                        {item?.customerAddress}

                                    </div>
                                </TableCell>

                            </TableRow>
                            {statusModalOpen && index === 0 && <div style={{ position: 'relative' }}>

                                <StyledPopupContainer ref={popupRef} style={{
                                    top: popupPosition.y - 60,
                                    left: popupPosition.x,

                                }}>
                                    <StyledPopupContent >
                                        <StyledPopupItems>

                                            {
                                                statusData?.map((items) => {
                                                    const OutlinedComponent = items?.img
                                                    return (
                                                        <PopupValues style={{ width: '92%', fontWeight: '500', padding: '8px' }} onClick={e => {
                                                            e.stopPropagation()
                                                            bookingStatus(items?.value, item?.referenceNo)
                                                        }}>
                                                            <OutlinedComponent style={{ fontSize: '18px', marginRight: '10px' }} />
                                                            {items?.name}</PopupValues>
                                                    )
                                                }
                                                )
                                            }
                                        </StyledPopupItems>

                                    </StyledPopupContent>
                                </StyledPopupContainer>


                            </div>}
                            {statusDotOpen && index === 1 && <div style={{ position: 'relative' }}>

                                <StyledPopupContainer ref={popupRef} style={{
                                    top: popupPositionDot.y - 120,
                                    left: popupPositionDot.x,

                                }}>
                                    <StyledPopupContent >
                                        <StyledPopupItems>

                                            {
                                                dotData?.map((items) =>
                                                    <PopupValues style={{ fontWeight: '500', padding: '15px', color: invoice?.invoiceStatus === 'Paid' && items?.name === 'Delete Invoice' ? 'gray' : invoice?.invoiceStatus !== 'Paid' && items?.name === 'Send Receipt' ? 'gray' : '#4169e1' }} onClick={e => {
                                                        e.stopPropagation();
                                                        if (invoice?.invoiceStatus === 'Paid' && items?.name === 'Delete Invoice') {

                                                        }
                                                        else if (invoice?.invoiceStatus !== 'Paid' && items?.name === 'Send Receipt') {

                                                        }
                                                        else {
                                                            setDatadet(item)
                                                            onClickDot(e, items?.name, invoice)
                                                        }
                                                    }}>

                                                        {items?.name}</PopupValues>
                                                )
                                            }
                                        </StyledPopupItems>

                                    </StyledPopupContent>
                                </StyledPopupContainer>


                            </div>}

                        </TableBody>


                    </>

                    )}
                </Table>
                {tabData?.length > 0 ? null : <h3 style={{ color: 'gray', display: 'flex', justifyContent: 'center', marginTop: '30vh', marginBottom: '30vh' }}>No requests</h3>}
            </div>
        )
    }

    return (
        <>
            <Body>
                <Flexbox>

                    <CustomTable>
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginTop: '10px' }}>
                            <CustomTextField
                                placeholder="Search"
                                size="small"
                                value={search}
                                onChange={(e) => searchFilterFunction(e.target.value)}
                                InputProps={{
                                    style: {
                                        paddingLeft: '10px',
                                        borderRadius: '8px',
                                        fontSize: '14px',
                                        width: '450px',
                                        marginLeft: '15px',
                                        background: '#f3f3f3',
                                    },
                                    startAdornment: (
                                        <CustomInputAdornment position="start">
                                            <CustomIconButton>
                                                <OptionIcon style={{ width: '15px', height: '15px' }} src={searchs} />
                                            </CustomIconButton>
                                        </CustomInputAdornment>
                                    ),

                                }}
                            />

                        </div>
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginLeft: '20px', marginRight: '20px', marginTop: '10px', }}>
                            <h1 style={{ textAlign: 'center', marginTop: '5px', marginBottom: '0px' }}>Jobs</h1>

                            <OptionBox onClick={() => setModalOpen(true)}>
                                Create job
                            </OptionBox>

                        </div>
                        {loader && searchinvoice?.length <= 0 ? <div style={{ display: 'flex', height: '100%', alignItems: 'center', justifyContent: 'center' }}> <Player
                            src={require('../../../assets/loaders.json')}
                            className="player"
                            style={{ width: '200px', height: '200px' }}
                            loop
                            autoplay
                        />
                        </div>

                            :
                            <>
                                <div style={{ padding: 20, paddingRight: '0px', paddingBottom: '0px' }}>

                                    <div style={{ display: 'flex', width: '100%', marginBottom: '20px' }}>

                                        {[{ name: 'All request', value: 'All request' }, ...statusData]?.map(item =>

                                            <ContainerBox
                                                style={{ border: filter === item?.name ? '1.5px solid #0F52BA' : '1px solid #bbb', color: filter === item?.name ? '#0F52BA' : '#000' }}
                                                onClick={() => {
                                                    if (search) {
                                                        setSearch('')
                                                    }
                                                    setRange1([{
                                                        startDate: '',
                                                        endDate: ''
                                                    }])
                                                    setRange([
                                                        {
                                                            startDate: new Date(),
                                                            endDate: addDays(new Date(), 3),
                                                            key: 'selection'
                                                        }
                                                    ])
                                                    if (item?.name === 'All request') {
                                                        setFilters('')
                                                        setSearchInvoice(allData)
                                                        setTabData(allData)
                                                        setValue(0)
                                                    }
                                                    else {
                                                        console.log(item?.value)

                                                        const data = allData?.filter(items => items?.bookingStatus === item?.value)
                                                        setSearchInvoice(data)
                                                        setTabData(data)

                                                    }
                                                    setFilter(item?.name)

                                                }}
                                            >{item?.value === 'Waiting for confirmation' ? 'Open' : item?.value}
                                                <div>
                                                    {item?.name === 'All request' ? allData?.length : allData?.filter(items => items?.bookingStatus === item?.value)?.length}
                                                </div>
                                            </ContainerBox>
                                        )
                                        }


                                        {/* <ContainerBox>Overdue</ContainerBox> */}


                                    </div>
                                </div>
                                <div style={{ position: 'relative', display: 'flex', justifyContent: 'flex-end', marginBottom: '20px', marginRight: '20px' }}>
                                    <div style={{ display: 'flex', background: '#f3f3f3', border: '1px solid #ddd', borderColor: '#ddd', alignItems: 'center', height: '30px', borderRadius: '8px', marginRight: '10px', paddingLeft: '10px', paddingRight: '10px' }}>
                                        <Map style={{ fontSize: '18px', marginRight: '10px' }} />
                                        <CustomUrl
                                            value={'Unscheduled jobs'}
                                            readOnly
                                            className="inputBox"
                                            style={{ fontSize: '13px', background: '#f3f3f3', height: '28px', fontWeight: '500', color: '#000', border: 'none' }}
                                            onClick={() => setMapModal(open => !open)}
                                        />
                                    </div>
                                    <div style={{ display: 'flex', background: '#f3f3f3', border: '1px solid #ddd', borderColor: '#ddd', alignItems: 'center', height: '30px', borderRadius: '8px', paddingLeft: '10px', paddingRight: '10px' }}>
                                        <CalendarMonth style={{ fontSize: '18px', marginRight: '10px' }} />
                                        <CustomUrl
                                            value={range1[0]?.startDate ? `${format(range1[0]?.startDate, "MMM dd")} - ${format(range1[0].endDate, "MMM dd, yyyy")}` : 'Select date'}
                                            readOnly
                                            className="inputBox"
                                            style={{ fontSize: '13px', background: '#f3f3f3', fontWeight: '500', height: '28px', color: '#353a44', border: 'none' }}
                                            onClick={() => setOpen(open => !open)}
                                        />
                                    </div>


                                    {open &&
                                        <>
                                            <StyleBox style={{ position: 'absolute', right: 0, marginTop: '50px', zIndex: 1000000, marginLeft: '15px', background: 'white' }} ref={refOne}>
                                                <DateRange
                                                    style={{ height: '220px' }}
                                                    onChange={item => setRange([item.selection])}
                                                    editableDateInputs={true}
                                                    moveRangeOnFirstSelection={false}

                                                    ranges={range}
                                                    months={1}
                                                    direction="horizontal"
                                                    className="calendarElement"
                                                />
                                                <ButtonBox>“
                                                    <SendButton1 style={{ background: '#F3F3F3', color: '#000', marginRight: '15px' }} onClick={() => {
                                                        setOpen(false)
                                                        setRange1([{
                                                            startDate: '',
                                                            endDate: ''
                                                        }])
                                                        setRange([
                                                            {
                                                                startDate: new Date(),
                                                                endDate: addDays(new Date(), 3),
                                                                key: 'selection'
                                                            }
                                                        ])

                                                        setFilter('All request')

                                                        setSearchInvoice(allData)
                                                        setTabData(allData)
                                                        setValue(0)
                                                    }}>Clear</SendButton1>
                                                    <SendButton1 style={{ marginRight: -15 }} onClick={async () => {
                                                        setOpen(false)
                                                        setRange1([{
                                                            startDate: range?.[0]?.startDate,
                                                            endDate: range?.[0]?.endDate
                                                        }])
                                                        const filteredData = allData.filter(item => {
                                                            const itemDate = moment(item.date).format('YYYY-DD-MM');
                                                            return itemDate >= moment(range?.[0]?.startDate)?.format('YYYY-DD-MM') && itemDate <= moment(range?.[0]?.endDate)?.format('YYYY-DD-MM');
                                                        });
                                                        setSearchInvoice(filteredData)
                                                        setTabData(filteredData)
                                                        setValue(0)
                                                    }}>Apply</SendButton1>
                                                </ButtonBox>
                                            </StyleBox>
                                        </>
                                    }

                                    {/* <Selectbox
                                            style={{ marginTop: '0px', borderRadius: '8px', border: '1px solid #f3f3f3', fontSize: '14px' }}
                                            placeholder='select' value={'Select an option'} displayEmpty
                                            renderValue={() => filters ? filters : 'Select'} onChange={(e) => {
                                                console.log(e.target)

                                            }}
                                            MenuProps={{ // Add MenuProps to customize menu styling
                                                PaperProps: {
                                                    style: {
                                                        marginTop: '8px', // Adjust this value for the desired gap
                                                    },
                                                },
                                            }}
                                        >
                                            {filterData?.map((item, index) =>
                                                <StyledMenuItem key={index} value={index}>
                                                    <FilterBox
                                                        onClick={() => {
                                                            setFilters(item?.name)
                                                            if (item?.name === 'Scheduled') {
                                                                const data = allData?.filter(items => items?.date)
                                                                console.log(item?.value, data, allData)
                                                                setSearchInvoice(data)
                                                            }
                                                            else if (item?.name === 'Not Scheduled') {
                                                                const data = allData?.filter(items => !items?.date)
                                                                console.log(item?.value, data, allData)
                                                                setSearchInvoice(data)
                                                            }


                                                        }}
                                                        style={{ color: filters === item?.name ? '#4169e1' : '#000' }}>

                                                        <FilerIcon src={item?.image} />
                                                        {item?.name}</FilterBox>
                                                </StyledMenuItem>
                                            )}
                                        </Selectbox> */}
                                </div>

                                {renderSchedule()}

                            </>




                        }

                    </CustomTable>
                    {modalOpen && (
                        <FullScreenModal onClose={() => {
                            setIsEdit(false)

                            setModalOpen(false)
                            searchParams.delete('id');
                            searchParams.delete('request');
                            setSearchParams(searchParams)
                        }}>

                            <CreateBooking
                                {...props}
                                value={isEdit ? { ...invoice, edit: true } : { edit: true }}
                                serviceRequest={isEdit}
                                newService={true}
                               
                                setCreateBookingModal={(val) => {
                                    setIsEdit(false)
                                    setModalOpen(false)
                                    searchParams.delete('request');
                                    searchParams.delete('id');
                                    setSearchParams(searchParams)
                                    if(val?.number){
                                    navigate(`/dashboard/servicerequests/${val?.number}`)}
                                    init()
                                }}
                                team={team}
                                onSubmitsw={async (val, editValue) => {
                                    searchParams.delete('request');
                                    await searchParams.delete('id');
                                    await setSearchParams(searchParams)
                                    sendchat(val, editValue)
                                    navigate(`/dashboard/servicerequests/${editValue?.number}`)
                                    init()
                                    setModalOpen(false)
                                    setIsEdit(false)
                                   


                                }
                                }
                            />


                        </FullScreenModal>
                    )}


                </Flexbox>
                {isDownloadOpen &&
                    <Modal onClose={() => setIsDownloadOpen(false)}>
                        <Display id="divToPrint"  >
                            <div style={{ display: 'flex', justifyContent: 'flex-end', marginRight: '1rem' }}>
                                <CustomButton onClick={printDocument}>
                                    Download
                                </CustomButton>
                            </div>

                            <div
                                style={{

                                    width: '230mm',
                                    height: "297mm",
                                    background: 'white',

                                    pageBreakInside: 'avoid',
                                    boxSizing: 'border-box',
                                    overflow: 'auto', // Enable scrolling if content exceeds the page
                                }}
                            >
                                <PreviewInvoice
                                    userDetails={datadet}
                                    quoteData={datadet?.items}
                                />
                            </div>
                        </Display>
                    </Modal>
                }
                {mapModal &&
                    <FullScreenModal onClose={() => {
                        setIsEdit(false)

                        setModalOpen(false)
                        searchParams.delete('id');
                        searchParams.delete('request');
                        setSearchParams(searchParams)
                    }}>

                        <UnScheduledJobs
                            setModal={() => {
                                init()
                                setMapModal(false)
                            }}
                        />
                    </FullScreenModal>
                }
            </Body>
        </>
    )


}


export default ServiceRequestList;

const Body = styled.div`
display:flex;
width:calc(100% - 240px);
overflow-x:scroll;
overflow-y:hidden;
position: fixed;
top: 0;
left: 0;
right: 0;
bottom: 0;
margin-left:240px;
`

const StyledPopupContent = styled.div`
    background-color: #FAFAFA;
    box-shadow: 0 12px 48px rgba(32, 40, 77, 0.1), 0 32px 80px rgba(32, 40, 77, 0.1);
    opacity: 1.0;
    border-radius: 8px;
    width: 200px;
    background: white;
    position: absolute;
    top: 0px;
    left: 0px;
    z-index: 20000;
`

const StyledPopupItems = styled.div`
   
    display:flex;
flex-direction:column;
 
    justify-content:center;
    align-items:start;
`
const PopupValues = styled.div`

display:flex;
align-items:center;
border-bottom:1px solid #f3f3f3;
font-size:14px;
width:88%;
&:hover {
    background:#f3f3f3;
  }

`
const StyledPopupContainer = styled.div`
    z-index: 1500;
    position: absolute;
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-content: stretch;
    align-items: center;
`

const OptionIcon = styled.img`
width: 12px;
height: 12px;
`
const OptionBox = styled.div`
padding-left:20px;
padding-right:20px;
height:35px;
border-radius:8px;
font-weight:600;
background:#0F52BA;
color:#fff;
font-size:14px;


display:flex;
align-items:center;
justify-content:center;

cursor:pointer;
&:hover {
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.2); 
}

`


const Display = styled.div`
background:#fff;
`


const CustomTable = styled(TableContainer)`
  &::-webkit-scrollbar {
    display: none; /* Hide the scrollbar */
  }
  border-color: red;

  .MuiTableBody-root {
   
    font-family: Inter;
    font-size: 13px;
  }

  .MuiTableHead-root {
    font-weight: 700;
    font-family: Inter;
    font-size: 14px;
    color:${palette.black};
  }

  .MuiTableRow-root {
    height: 35px;
   
    font-family: Inter;
    font-size: 13px;
    &:hover {
      background: #f8f8f8;
    }
  }

  .MuiTableCell-root {
    border-color: #f3f3f3;
    border-right:1px solid #f3f3f3;
    height:10px;
    font-family: Inter;
    white-space: nowrap;
    word-break: break-word;
    border-collapse: separate;
    line-height: 16px;
    color:${palette.black};
    font-size: 13px;
    font-weight:450;
  }
  .sticky {
    position: sticky;
    left: 0;
    z-index: 2;
    background: white;
  
  }
  .MuiTableCell-root:first-child {
    position: sticky;
    left: 0;
    background: #fff;
    border-left:1px solid #f3f3f3;
    font-weight:700;
    color:${palette.newBlue};
    z-index: 1;
    min-width:150px;
 
  }
  .MuiTableRow-root:hover .MuiTableCell-root:first-child {
    background:#f8f8f8;
  }

  .purple {
    background: #974EDE;
  }

  .green {
    background: #32cd32;
  }

  .dimgreen {
    background: #008000;
  }
`;

const CustomHead = styled(TableHead)`
position: sticky;
  top: 0;
  background-color: white;
  z-index: 2;
border-radius:8px;
font-size:14px;
color:${palette.black};
border-top:1px solid #f3f3f3;
color:#000;
height:10px;
flex-wrap:wrap;
background:#f9f9f9;
.MuiTableCell-root:first-child {
  position: sticky;
  background:#f9f9f9;
  color:${palette.black};
  z-index: 1;
  min-width:150px;

}
`
const StyledMenuItem = styled(MenuItem)`
display:flex;
align-items:center;
border-bottom:1px solid #f3f3f3;
align-self:start;

  :hover {
    background: #dddddd;
    border-radius: 5px;
  }
`
const Flexbox = styled.div`
width:100%;
background:#fff;

display:flex;
@media (min-width: 260px) and (max-width: 1300px){

    flex-shrink: 0;
overflow: auto; 
}
`
const FilerIcon = styled.img`
width: 20px;
height: 20px;
margin-right:10px;
`
const Selectbox = styled(Select)`
width:200px;
border-radius:8px;
text-align:start;
margin-top:20px;
display:flex;
align-self:flex-start;
justify-content:flex-start;
border:1px solid #f3f3f3;
background:white;
height:38px;
font-size:14px;
 margin-bottom: 20px; 


.MuiMenuItem-root:hover {
    background: #dddddd;
    border-radius: 4px;
  }
}

`
const PaymnetIcon = styled.img`

height: 50px;
`

const ContainerBox = styled.div`
width:100%;
height:35px;
border-radius:8px;
display:flex;
justify-content:space-between;
font-size:13px;
padding-left:15px;
padding-right:15px;
margin-right:20px;
align-items:center;
border:1px solid #ddd;
font-weight:550;
cursor:pointer;
&:hover {
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.1); /* Box shadow on hover */
  }
`
const CloseButton = styled.button`
background:transparent;
display:flex;
align-items:center;
justify-content:center;
border: none;
background:#ddd;
margin-right:30px;
width:25px;
height:25px;
align-self:center;
border-radius:20px;
font-size: 16px;
cursor: pointer;

`
const Icons = styled.img`
width: 15px;
height: 15px;
`
const CustomButton = styled.button`
width:130px;
height:44px;
margin-top:15px;
border-radius:8px;
border:1px solid #4169E1;
color:white;
font-size:16px;
background:#4169E1;
display:flex;
align-items:center;
justify-content:center;
align-self:flex-end;
cursor:pointer;
`
const CustomUrl = styled.input`
background: #FFF;
border-radius: 8px;
cursor:pointer;
border: 1px solid #fff;
height: 30px;

&:focus {
    outline: none;
    border: none; /* Remove border on focus */
  }
`
const StyleBox = styled.div`
display:flex;
justify-content:flex-start;
flex-direction:column;
border:1px solid #f3f3f3;
background:white;
width:350px;
margin-top:30px;
border-radius:8px;
box-shadow: 0px 4px 16px rgba(30, 30, 30, 0.1);

.keyContainer{
    padding: 10px 15px 10px 15px;
    font-size:16px;
    font-weight:600;
display:flex;
justify-content:space-between;
border-bottom:1px solid #f3f3f3;
align-items:center;


}
.valueContainer{
    padding:15px;
    background:#fff;
    font-size:14px;
display:flex;
}
`
const ButtonBox = styled.div`
align-self:flex-end;
display:flex;
margin-right:20px;
 margin-top:10px;
margin-bottom:15px;
justify-content:flex-end; 

`
const SendButton1 = styled.button`
border:1px solid #4169e1;
 background:#4169e1; 
color:#fff; 
margin-top:15px;
width:100px;
height:38px;
border-radius:8px;
border:1px solid white;
color:white;
font-size:14px;
margin-bottom:10px;
cursor:pointer;

`
const CustomTextField = styled(TextField)`
border-radius: 8px;
&& {
   
    height: 30px; /* Adjust the height as needed */
    padding: 5px; /* Adjust the padding as needed */
}
`;

const CustomInputAdornment = styled(InputAdornment)`
  
`;

const CustomIconButton = styled(IconButton)`
 
`;