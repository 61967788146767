import React, { useEffect } from "react";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Checkbox from '@mui/material/Checkbox';
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import trash from '../../assets/trash.png'
import editing from '../../assets/editing.png'
import { Form } from 'react-bootstrap'
import { useState } from "react";
import { putCommaInvalue, taxprice, totalprice } from "../../utils/Commonfunction";
import { saveTaxes, sendInvoice } from "../../store/Actions/dashboard.action";
import moment from 'moment'
import { palette } from "../../utils/Palette";
import { DeleteOutlined, Edit } from "@mui/icons-material";
import RippleButton from "../../ReuseableComponets/RippleButton";
function TaxDetails(props) {
    const globename = useSelector(state => state?.auth?.globename)
    const globenumber = useSelector(state => state?.auth?.globenum)
    const userdata = useSelector(state => state?.auth?.userData)
    const dispatch = useDispatch()
    const [value, setValue] = useState({
        "name": userdata?.name || "",
        "email": userdata?.email || "",
        "address": userdata?.address || "",
        "phoneNumber": userdata?.to,
        "notes": 'Due date',
        "date": moment(new Date()).format('YYYY-MM-DD'),
        'invoiceNumber': '',
        'currency': userdata?.currency || 'CAD'
    })
    const [isEdit,setIsEdit]=useState(false)
    const [addOwnerData, setAddOwnerData] = useState([{
        name: '',
        value: '',
        defaultTax: false,
        index: 0
    }])

    useEffect(() => {
       
        setAddOwnerData([...userdata?.tax])
    }, [])

    const addCustomFiled = () => {
        setAddOwnerData([...addOwnerData,
        {
            name: '',
            value: '',
            defaultTax: false,
            index: 0

        }
        ])
    }
    const deleteHandler = (index) => {
      
        const data = [...addOwnerData]
        data.splice(index, 1)
        setAddOwnerData(data)


    }
    const onchangeMaterials = (text, index, value) => {
      
        const data = [...addOwnerData]
        data[index][text] = value;
        setAddOwnerData(data);
    }
    const onChangeDefaultTax = (text, index, value) => {
        const data = [...addOwnerData]
        const datrp = []
        data.map((item, indexs) => {
            if (index == indexs) {
                datrp.push({
                    ...item, defaultTax: true
                })
            }
            else {
                datrp.push({
                    ...item, defaultTax: false
                })
            }
        })
        setAddOwnerData(datrp)
    }

    const onSubmit = async () => {
        const data = [...addOwnerData]


        if (data.length > 0) {

            if (data.length === 1) {

                data[0]['defaultTax'] = true;
            }
        }

        const res = await dispatch(saveTaxes({
            "tax": [...data]
        }))
        if (res.status === true) {
            setIsEdit(false)
        }

    }
    return (
        <>
            <Flexbox>


                <CustomTable>
                    <Header>Tax details</Header>
                    <Table aria-label="simple table">
                        <CustomHead>
                            <TableRow>

                                <TableCell size='small'>Tax</TableCell>

                                <TableCell style={{ position: 'relative', color: palette.black, fontWeight: '700' }} size='small'>Rate</TableCell>
                                <TableCell style={{ position: 'relative', color: palette.black, fontWeight: '700' }} size='small'>Registration number</TableCell>
                                <TableCell style={{ position: 'relative', color: palette.black, fontWeight: '700' }} size='small'>Default</TableCell>
                                <TableCell style={{ position: 'relative', color: palette.black, fontWeight: '700' }} size='small'></TableCell>
                            </TableRow>
                        </CustomHead>
                        {addOwnerData.map((item, index) => <>
                            <TableBody  >
                                <TableRow >

                                    <TableCell size='small' >
                                        <Inputbox
                                            type="text"
                                            placeholder='Tax name'
                                            style={{ width: 'auto' }}
                                            value={item?.name}
                                            onChange={(e) => onchangeMaterials('name', index, e.target.value)}
                                        />

                                    </TableCell>

                                    <TableCell size='small'>
                                        <CustomUrl type='number' placeholder='Percentage' value={item?.value} onChange={(e) => onchangeMaterials('value', index, e.target.value)} /></TableCell>
                                       
                                        <TableCell size='small' >
                                        <Inputbox
                                            type="text"
                                            placeholder='Registration number'
                                            style={{ width: 'auto' }}
                                            value={item?.description}
                                            onChange={(e) => onchangeMaterials('description', index, e.target.value)}
                                        />

                                    </TableCell>
                                       
                                    <TableCell size='small'>    <Checkbox key={index}
                                    style={{height:'15px'}}
                                        value={item.defaultTax}
                                        onChange={() => onChangeDefaultTax('defaultTax', index, true)} checked={item?.defaultTax ? true : false} /></TableCell>

                                    <TableCell size='small' style={{cursor:'pointer'}} onClick={() =>!isEdit?setIsEdit(true): deleteHandler(index)} >{!isEdit? <Edit style={{fontSize:'18px'}}  src={isEdit?trash:editing} alt='settings' />: <DeleteOutlined style={{fontSize:'18px'}}  src={isEdit?trash:editing} alt='settings' />}</TableCell>
                                </TableRow>

                            </TableBody>

                        </>)}
                    </Table>
                  {isEdit?  <div style={{ alignSelf: 'flex-end', display: 'flex', justifyContent: 'flex-end', paddingBottom: '40px',paddingTop:'20px' }}>
                        <RippleButton onClick={() => addCustomFiled()}>Add</RippleButton>
                    </div>:  <div onClick={() => setIsEdit(true)} style={{ border: '1px solid #0F52BA',marginTop:'15px',width:'100px',justifyContent:'center',display:'flex', padding: '7px 12px 7px 12px', borderRadius: '20px' }}>
                                <h4 style={{ textAlign: 'start', fontSize: '13px', marginTop: '0px', marginBottom: '0px', color: palette.newBlue, cursor: 'pointer' }}>+ New tax</h4></div>}
                   {isEdit? <div style={{display:'flex',justifyContent:'flex-end',  alignSelf: 'flex-end'}}>
                   <RippleButton style={{background:'#fff',color:'#000',border:'1px solid #ddd', marginRight:'20px'}} onClick={() => setIsEdit(false)}>Cancel</RippleButton>
                        <RippleButton onClick={() => onSubmit()}>Save tax</RippleButton>
                    </div>:null}

                </CustomTable>


            </Flexbox>
        </>
    )
}

export default TaxDetails;

const Header = styled.p
    `
font-weight: 600;
font-size: 24px;
display: flex;
letter-spacing: 0.01em;
color: #000;
margin-top:40px;
margin-right:10px;
`

const CustomUrl = styled(Form.Control)`
background: #FFF;
border-radius: 8px;
border: 1px solid #fff;
height: 25px;
font-size:13px;

&:focus {
    outline: none;
    border: none; /* Remove border on focus */
  }
`

const CustomButton = styled.button`
width:180px;
height:45px;
border-radius:8px;
background:#000;
border:1px solid #000;
align-self:flex-end;
color:white;
font-size:16px;
background:#000;
margin-top:15px;
cursor:pointer;
`
const Inputbox = styled.input`
height: 25px;
border-radius:5px;
font-size:13px;

font-weight: 400;
color: #344054;
background: #ffffff;
border: 1px solid #fff;


align-self:center;
::placeholder {
    font-size: 14px;
    font-weight: 300;

    color: gray;
  }

  &:focus {
    outline: none;
    border: none;
   
  }


`

const SendButton = styled.button`
border:1px solid #4169e1;
 background:#4169e1; 
 borderRadius:4px;
color:#fff; 
font-size: 16px;

width:150px;
height:45px;
border-radius:8px;
border:1px solid white;

color:white;
font-size:16px;
margin-bottom:10px;
cursor:pointer;

`
const CustomTable = styled(TableContainer)`
  &::-webkit-scrollbar {
    display: none; /* Hide the scrollbar */
  }
  border-color: red;

  .MuiTableBody-root {
   
    font-family: Inter;
    font-size: 13px;
  }

  .MuiTableHead-root {
    font-weight: 700;
    font-family: Inter;
    font-size: 14px;
    color:${palette.black};
  }

  .MuiTableRow-root {
    height: 35px;
   
    font-family: Inter;
    font-size: 13px;
 
  }

  .MuiTableCell-root {
    border-color: #f3f3f3;
    border-right:1px solid #f3f3f3;
    height:10px;
    font-family: Inter;
    white-space: nowrap;
    word-break: break-word;
    border-collapse: separate;
    line-height: 16px;
    color:${palette.black};
    font-size: 13px;
    font-weight:450;
  }
  .sticky {
    position: sticky;
    left: 0;
    z-index: 2;
    background: white;
  
  }
  .MuiTableCell-root:first-child {
    position: sticky;
    left: 0;
    background: #fff;
    border-left:1px solid #f3f3f3;
    font-weight:700;
    color:${palette.newBlue};
    z-index: 1;
    min-width:150px;
 
  }


  .purple {
    background: #974EDE;
  }

  .green {
    background: #32cd32;
  }

  .dimgreen {
    background: #008000;
  }
`;

const CustomHead = styled(TableHead)`
position: sticky;
  top: 0;
  background-color: white;
  z-index: 2;
border-radius:8px;
font-size:14px;
color:${palette.black};
border-top:1px solid #f3f3f3;
color:#000;
height:10px;
flex-wrap:wrap;
background:#f9f9f9;
.MuiTableCell-root:first-child {
  position: sticky;
  background:#f9f9f9;
  color:${palette.black};
  z-index: 1;
  min-width:150px;

}
`

const Flexbox = styled.div`
width:100%;
background:#fff;
margin-left:30px;
margin-right:30px;
display:flex;
@media (min-width: 260px) and (max-width: 1300px){
    width:100%;
    flex-shrink: 0;
overflow: auto; 
}
`
const SubTable = styled.div`

display:flex;
width:100%; 
justify-content:flex-end; 
align-self:flex-end;
padding-top:25px; 

.key{
    width:30%;
     text-align:start; 
     font-weight:bold;
}
`
const Icon = styled.img`
height:20px;
width:20px;
cursor:pointer;
color:black
`