import React, { memo, useCallback, useEffect, useMemo, useRef } from "react";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import { colorThemes, formatMobileNumber } from "../../../utils/Commonfunction";
import { fetchAllContacts, getUserDetails, saveTableFilter, submitchangeStatus } from "../../../store/Actions/dashboard.action";
import { Player } from '@lottiefiles/react-lottie-player';
import { Box, debounce, IconButton, InputAdornment, Pagination, TextField, Tooltip } from "@mui/material";
import FullScreenModal from "../../FullScreenModal";
import RightModal from "../../RightSideModal";
import RightSideConversations from "../RightSideConversation";
import ContactDetails from "./ContactDetails";
import AddContact from "../../Settings/AddContact";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { palette } from "../../../utils/Palette";
import { ArrowDropDown, Call, MenuBook, MessageOutlined, NorthOutlined, SearchOutlined, SouthOutlined, VisibilityOffOutlined } from "@mui/icons-material";
import SelectableMenu from "../../../ReuseableComponets/MultipleSelect";
import CustomizedMenu from "../../../ReuseableComponets/MenuComponent";

function ContactList(props) {
  const location = useLocation()
  const popupRef = useRef(null);
  const detail = useSelector(state => state?.auth?.allContacts)
  const [currentPage, setCurrentPage] = useState(1);
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [search, setSearch] = React.useState('');
  const ProgressDataColor = [
    { textColor: '#007BFF', backgroundColor: '#E6F2FF' },
    { textColor: '#FFC107', backgroundColor: '#FFF3E0' },
    { textColor: '#B02A37', backgroundColor: '#F5D7D9' },
    { textColor: '#DC3545', backgroundColor: '#F7D7DA' },
    { textColor: '#28A745', backgroundColor: '#E6F4EA' },
    { textColor: '#FF851B', backgroundColor: '#FFF0E4' }
  ];
  const ProgressData = [
    'Potential', 'In-Progress', 'Qualified', 'Bad fit', 'Customer', 'Not Interested'
  ]
  const [loader, setLoader] = useState(false)
  const [modalOpen, setModalOpen] = useState(false)
  const [isDetailOpen, setIsDetailOpen] = useState(false)
  const [contactDetail, setContactDetail] = useState({})
  const [customerValue, setCustomerValue] = useState({})
  const [popupPosition, setPopupPosition] = useState({ x: 0, y: 0 });
  const [allContact, setAllContact] = useState([])
  const [searchContact, setSearchContact] = useState([])
  const [statusModalOpen, setStatusModalOpen] = useState(false)
  const [opendAddContact, setOpenAddContact] = useState(false)
  const [invoice, setInvoice] = useState({})
  const containerRef = useRef(null);
  const token = useSelector(state => state?.auth?.token)
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [selectedItems, setSelectedItems] = React.useState([]);
  const open = Boolean(anchorEl);
  const [menuData, setMenuData] = useState([])
  const menuItems = [
    { text: 'Name', isSelected: true, disable: true, id: 1 },
    { text: 'Phone', isSelected: true, disable: true, id: 2 },
    { text: 'Email', isSelected: true, disable: true, id: 3 },
    { divider: true },
    { text: 'Address', isSelected: selectedItems.includes('Address'), id: 4 },
    { text: 'Status', isSelected: selectedItems.includes('Status'), id: 5 },
    // { text: 'Jobs', isSelected: selectedItems.includes('Jobs'), id: 6 },
    { text: 'Created by', isSelected: selectedItems.includes('Created by'), id: 7 },
    { text: 'Created on', isSelected: selectedItems.includes('Created on'), id: 8 },
    { text: 'Updated by', isSelected: selectedItems.includes('Updated by'), id: 9 },
    { text: 'Updated on', isSelected: selectedItems.includes('Updated on'), id: 10 },
    // { text: 'Assigned member', isSelected: selectedItems.includes('Assigned member'), id: 11 },
    { text: 'Tags', isSelected: selectedItems.includes('Tags'), id: 12 },
    { text: 'Notes', isSelected: selectedItems.includes('Notes'), id: 13 },
    { text: 'Tasks', isSelected: selectedItems.includes('Tasks'), id: 14 },

  ];
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSelectItem = (item) => {
    setSelectedItems((prevSelected) =>
      prevSelected.includes(item)
        ? prevSelected.filter((i) => i !== item)
        : [...prevSelected, item]
    );
  };





  const dispatch = useDispatch()
  const init = async () => {
    const searchParams = new URLSearchParams(location.search);
    const invoiceParam = searchParams.get('contact');
    if (invoiceParam === 'true') {
      setOpenAddContact(true)
    }
    setLoader(true)
    await dispatch(getUserDetails())
    let res = await dispatch(fetchAllContacts())
    if (res.status === true) {
      setLoader(false)
      setAllContact(res.data)
      setSearchContact(res.data)
      let customField = []
      const selectedTexts = res?.filter.map(item => item.text);
      const customText = res?.customField.map(item => item.fieldName);
      const filteredMenuItems = menuItems.filter(menuItem => !selectedTexts.includes(menuItem.text));
      customText.filter((menuItem, index) => {
        console.log(menuItem, "ddccd")
        if (!selectedTexts.includes(menuItem)) {
          customField?.push({ text: menuItem, isSelected: false, id: 15 + index, custom: true },)
        }
      });

      console.log(res.data, 'fdydayafilter')
      setMenuData([...res?.filter, ...filteredMenuItems, ...customField])
      setSelectedItems(res?.filter)
    }
  }

  useEffect(() => {
    init()
  }, [])
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        setStatusModalOpen(false);
      }
    };

    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [setStatusModalOpen]);


  const OnsubmitStauts = async (e, status) => {
    console.log(invoice?.invoiceNo, 'dfnjkfdkdjfkjfdjkfdjkn')
    e.stopPropagation()

    const res = await dispatch(submitchangeStatus({
      "numberId": invoice?._id,
      "status": status
    }))
    init()
    setStatusModalOpen(false)
  }

  const searchFilterFunction = (text) => {
    console.log(text, "data");
    if (text) {
      const newData = allContact?.filter((item) => {
        const nameData = item?.name ? item.name.toUpperCase() : ''.toUpperCase();
        const numberData = item?.number ? item.number.toUpperCase() : ''.toUpperCase();
        const addressData = item?.address ? item.address?.toUpperCase() : ''.toUpperCase();
        const emailData = item?.email ? item.email?.toUpperCase() : ''.toUpperCase();
        const searchText = text.toUpperCase();
  
        // Check if any tag matches the search text
        const tagsData = item?.hashTags ? item.hashTags.map(tag => tag.toUpperCase()) : [];
        const tagMatch = tagsData.some(tag => tag.includes(searchText));
  
        return (
          nameData.includes(searchText) || 
          numberData.includes(searchText) || 
          addressData.includes(searchText) || 
          emailData.includes(searchText) ||
          tagMatch
        );
      });
      setSearchContact(newData);
      setSearch(text);
    } else {
      setSearchContact(allContact);
      setSearch(text);
    }
  };

  const menuItemsc = [
    {
      label: 'Sort ascending',
      icon: <NorthOutlined style={{ fontSize: '18px' }} />,
      onClick: () => console.log('Edit clicked'),

    },
    {
      label: 'Sort descending',
      icon: <SouthOutlined style={{ fontSize: '18px' }} />,
      onClick: () => console.log('Duplicate clicked'),
    },
    {
      divider: true,
    },
    {
      label: 'Hide column',
      icon: <VisibilityOffOutlined style={{ fontSize: '18px' }} />,
      onClick: () => console.log('Archive clicked'),
      disabled: true
    },
  ];
  const menuItemsDynamic = [
    {
      label: 'Sort ascending',
      icon: <NorthOutlined style={{ fontSize: '18px' }} />,
      onClick: () => console.log('Edit clicked'),
      disabled: true

    },
    {
      label: 'Sort descending',
      icon: <SouthOutlined style={{ fontSize: '18px' }} />,
      onClick: () => console.log('Duplicate clicked'),
      disabled: true
    },
    {
      divider: true,
    },
    {
      label: 'Hide column',
      icon: <VisibilityOffOutlined style={{ fontSize: '18px' }} />,
      onClick: () => console.log('Archive clicked'),

    },
  ];

  const onClickRequiredHeader = async (lab, item) => {
    if (lab === 'Sort ascending') {
      const sorted = [...searchContact]
      const allcontc = [...allContact]
      sorted.sort((a, b) => a.name.localeCompare(b.name));
      allcontc.sort((a, b) => a.name.localeCompare(b.name));
      setSearchContact(sorted)
      setAllContact(allcontc)
    }
    else if (lab === 'Sort descending') {
      const sorted = [...searchContact]
      const allcontc = [...allContact]
      sorted.sort((a, b) => b.name.localeCompare(a.name));
      allcontc.sort((a, b) => b.name.localeCompare(a.name));
      setSearchContact(sorted)
      setAllContact(allcontc)
    }
    else {
      const removeItem = [...selectedItems]
      const index = removeItem.filter(items => items.text !== item?.text);
      const res = await dispatch(saveTableFilter({
        "filterData": index
      }))
      if (res.status === true) {
        init()
      }
      setSelectedItems(index)
    }

  }

  const saveFilter = async (val) => {
    const res = await dispatch(saveTableFilter({
      "filterData": val
    }))
    if (res.status === true) {
      init()
    }
  }
  const handlePageChange = (event, value) => {
    setCurrentPage(value);
    if (containerRef.current) {
      containerRef.current.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    }
  };
  const currentData = useMemo(() => {
    const startIndex = (currentPage - 1) * 100;
  
    return searchContact.slice(startIndex, startIndex + 100);
  }, [currentPage, searchContact]);


  const RenderContact = memo(({ item, index }) => {
    return (
      <>

        <TableBody key={index} onClick={(event) => {
          navigate(`/dashboard/contacts/${item?._id}`)


        }} style={{ background: '#fff', cursor: 'pointer' }} >
          <TableRow >
            <TableCell style={{ fontWeight: '500' }} className="sticky" size='small'>
              <div>
                {item?.displayName ? item?.businessName : item?.name}

              </div>

            </TableCell>

            <Tooltip arrow title={`Call ${formatMobileNumber(item?.to || item?.number)}`} placement='top'>
              <TableCell
                style={{ width: '15px', }}
                size='small'
                onClick={(e) => {
                  e.stopPropagation()
                  const call = token.connect({
                    params: {
                      to: item?.to || item?.number,
                      from: `+${localStorage.getItem('number')}`
                    }
                  })

                  dispatch({ type: "CONNECTION", payload: call })
                  dispatch({
                    type: "CALLDETAIL", payload: {
                      name: item?.name,
                      to: item?.to || item?.number
                    }
                  })
                  dispatch({ type: "CALLSHOW", payload: true })
                }}
              >
                <IconBox>
                  <Call style={{ fontSize: '14px', color: 'black' }} />
                </IconBox>
              </TableCell>
            </Tooltip>
            <Tooltip arrow title={`Message  ${item?.name}`} placement='top'>
              <TableCell
                onClick={(e) => {
                  e.stopPropagation()
                  setCustomerValue({ name: item?.name, number: item?.number })
                  setModalOpen(true)
                }}
                style={{ width: '15px' }}
                size='small'>
                <IconBox >
                  <MessageOutlined style={{ fontSize: '14px', color: 'black' }} />
                </IconBox>
              </TableCell>
            </Tooltip>
            {selectedItems.some(item => item.text === 'Status') && <TableCell size='small'  >

              <div style={{
                width: '120px',
                fontWeight: '500',
                borderRadius: 5,
                display: 'flex',
                textAlign: 'center',
                justifyContent: 'start',
                fontSize: '13px'
              }}>
                <span style={{ color: item?.status === 'Potential' ? ProgressDataColor[0]?.textColor : item?.status === 'In-Progress' ? ProgressDataColor[1]?.textColor : item?.status === 'Qualified' ? ProgressDataColor[2]?.textColor : item?.status === 'Bad fit' ? ProgressDataColor[3]?.textColor : item?.status === 'Customer' ? ProgressDataColor[4]?.textColor : item?.status === 'Not Interested' ? ProgressDataColor[5]?.textColor : '#000', marginRight: '5px' }}>●</span>  {item?.status}
              </div>



            </TableCell>}
            <TableCell size='small'  >
              {formatMobileNumber(item?.number)}

            </TableCell>

            <TableCell size='small'>
              {item?.email}

            </TableCell>
            {
              selectedItems?.map((items) => items?.disable ? null :
                items?.text === 'Status' ? null : items?.text === 'Tags' ?
                  <TableCell style={{ minWidth: '100px', height: '2px' }}>
                    <div style={{ display: 'flex', height: '0px', marginTop: '-10px' }}>
                      {item?.hashTags?.slice(0, 2).map((itemo, index) => (
                        <Tooltip key={index} arrow placement="top" title={itemo}>
                          <div
                            style={{
                              marginRight: '5px',
                              color: '#fff',
                              background: colorThemes[index % colorThemes.length]?.text,
                              border: `1px solid ${colorThemes[index % colorThemes.length]?.outline}`,
                              fontSize: '10px',
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',

                              height: '16px',
                              padding: '0px',
                              paddingLeft: '5px',
                              paddingRight: '5px',
                              borderRadius: '5px'
                            }}
                          >
                            {itemo}
                          </div>
                        </Tooltip>
                      ))}
                      {item?.hashTags?.length - 2 > 0 && (
                        <Tooltip
                          arrow
                          placement="top"
                          title={
                            <div>
                              {(item?.hashTags).map((itemo, index) => (
                                <div key={index} style={{ marginBottom: '2px' }}>
                                  {itemo}
                                </div>
                              ))}
                            </div>
                          }
                        >
                          <div
                            style={{
                              marginRight: '5px',
                              color: '#fff',
                              background: '#333',
                              border: `1px solid #555`,
                              fontSize: '10px',
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                              width: '25px',
                              height: '16px',
                              padding: '0px',
                              borderRadius: '5px'
                            }}
                          >
                            +{item?.hashTags?.length - 2}
                          </div>
                        </Tooltip>
                      )}
                    </div>
                  </TableCell> : items?.text === 'Tasks' ? <TableCell style={{ minWidth: '100px', height: '2px' }}>
                    <div style={{ display: 'flex', height: '0px', marginTop: '-10px' }}>
                      {item?.task?.slice(0, 2).map((itemo, index) => (
                        <Tooltip key={index} arrow placement="top" title={itemo?.task}>
                          <div
                            style={{
                              marginRight: '5px',
                              color: '#000',
                              background: '#ddd',
                              maxWidth: '120px',
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                              fontSize: '10px',
                              height: '18px',
                              padding: '0px',
                              paddingLeft: '5px',
                              paddingRight: '5px',
                              borderRadius: '5px'
                            }}
                          >
                            {itemo?.task}
                          </div>
                        </Tooltip>
                      ))}
                      {item?.task?.length - 2 > 0 && (
                        <Tooltip
                          arrow
                          placement="top"
                          title={
                            <div>
                              {(item?.task).map((itemo, index) => (
                                <div key={index} style={{ marginBottom: '2px' }}>
                                  {`- ${itemo?.task}`}
                                </div>
                              ))}
                            </div>
                          }
                        >
                          <div
                            style={{
                              marginRight: '5px',
                              color: '#fff',
                              background: '#333', // Example color for the "+X" indicator
                              border: `1px solid #555`, // Example border color for the "+X" indicator
                              fontSize: '10px',
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                              width: '25px',
                              height: '15px',
                              padding: '0px',
                              borderRadius: '5px'
                            }}
                          >
                            +{item?.task?.length - 2}
                          </div>
                        </Tooltip>
                      )}
                    </div>
                  </TableCell>
                    :
                    <Tooltip arrow placement="top" title={item[`${(items?.text?.replace(/\s+/g, '_'))?.toLowerCase()}`]}>
                      <TableCell
                        style={{
                          maxWidth: '250px',
                        }}

                        size="small">
                        <div style={{
                          minWidth: '130px',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          lineHeight: '16px',
                          fontSize: '13px',
                        }}>
                          {item[`${(items?.text)?.toLowerCase()}`]}
                        </div>
                      </TableCell>
                    </Tooltip>
              )
            }
            {/* <TableCell size="small">
                            <IconBox style={{ background: '#fff' }}>
                              <Icons src={trash} onClick={async (e) => {
                                e.stopPropagation()
                                setContactDetail({ ...item })
                                setIsDelete(true)

                              }} />
                            </IconBox>
                          </TableCell> */}
          </TableRow>
        </TableBody>
        {statusModalOpen && index === 1 && <div style={{ position: 'relative' }}>
          <StyledPopupContainer ref={popupRef} style={{
            top: popupPosition.y - 120,
            left: popupPosition.x,

          }}>
            <StyledPopupContent >
              <StyledPopupItems>

                {
                  ProgressData?.map((items, index) =>
                    <PopupValues style={{ width: '100%' }} onClick={e => {
                      OnsubmitStauts(e, items)
                      // setDatadet(item)
                      // OnsubmitStauts(e, items)
                    }}>
                      <a style={{ fontSize: '16px', color: ProgressDataColor[index]?.textColor, marginRight: '8px' }}>{`●  `}</a>
                      {items}</PopupValues>
                  )
                }
              </StyledPopupItems>

            </StyledPopupContent>
          </StyledPopupContainer>


        </div>}
      </>

    )
  }

  )

  const rowItem = useCallback((item, index) => {
    return <RenderContact item={item} index={index} />
  }, [searchContact])

  return (
    <>
      <Body>
        <Flexbox >
          <CustomTable ref={containerRef}>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginTop: '20px' }}>
              <CustomTextField
                placeholder="Search"
                size="small"
                value={search}
                onChange={(e) => searchFilterFunction(e.target.value)}
                InputProps={{
                  style: {
                    paddingLeft: '10px',
                    borderRadius: '8px',
                    fontSize: '14px',
                    width: '450px',
                    marginLeft: '15px',
                    background: '#f3f3f3',

                  },
                  startAdornment: (
                    <CustomInputAdornment position="start">
                      <CustomIconButton>
                        <SearchOutlined style={{ fontSize: '16px' }} />
                      </CustomIconButton>
                    </CustomInputAdornment>
                  ),

                }}
              />
              <OptionBox onClick={() => setOpenAddContact(true)} >
                New contact
              </OptionBox>
            </div>
            <StyledContactFlex>
              <h1>Contacts</h1>
              <div style={{ display: 'flex' }}>
                <SelectBox onClick={handleClick}>
                  <MenuBook style={{ fontSize: '22px' }} />
                  <ArrowDropDown style={{ fontSize: '22px' }} />
                </SelectBox>
                {menuData?.length > 0 && <SelectableMenu
                  items={[...menuData]}
                  anchorEl={anchorEl}
                  open={open}
                  selectedvalue={selectedItems}
                  onClose={handleClose}
                  onSelect={handleSelectItem}
                  onCancel={() => {
                    setAnchorEl(null)
                  }}
                  onclickButton={async (val) => {
                    console.log(val, "djdbcjd")
                    setAnchorEl(null)
                    await saveFilter(val)

                    setSelectedItems(val)
                  }
                  }

                />}
              </div>

            </StyledContactFlex>

            {loader && !detail?.invoice ? <StyledPlayer> <Player
              src={require('../../../assets/loaders.json')}
              className="player"
              style={{ width: '200px', height: '200px' }}
              loop
              autoplay
            />
            </StyledPlayer>
              :
              <div >
                <div ref={containerRef} style={{ overflowY: 'auto', height: '82vh', }}>
                  <Table style={{ gridTemplateColumns: 'repeat(3, 1fr)' }} sx={{ width: '100%' }} aria-label="simple table">
                    <CustomHead>
                      <TableRow >
                        <TableCell className="sticky" size="small" style={{ cursor: 'pointer' }}>
                          Name
                          <CustomizedMenu
                            menuItems={menuItemsc}
                            onClick={(lab) => onClickRequiredHeader(lab)}
                          />
                        </TableCell>
                        <TableCell style={{ width: '15px' }} size="small">

                        </TableCell>
                        <TableCell style={{ width: '15px' }} size="small">

                        </TableCell>
                        {selectedItems.some(item => item.text === 'Status') && <TableCell style={{ width: '120px', position: 'relative', color: palette.black, fontWeight: '700' }} size='small'>
                          Status
                          <CustomizedMenu
                            menuItems={menuItemsDynamic}
                            onClick={(lab) => onClickRequiredHeader(lab, { text: 'Status', isSelected: true, id: 5 })}
                          />

                        </TableCell>}
                        <TableCell style={{ minWidth: '120px', position: 'relative', color: palette.black, fontWeight: '700' }} size='small'>

                          Phone number
                          <CustomizedMenu
                            menuItems={menuItemsc}
                            onClick={(lab) => onClickRequiredHeader(lab)}
                          />
                        </TableCell>
                        <TableCell style={{ minWidth: '150px', color: palette.black, position: 'relative', fontWeight: '700' }} size='small'>Email address
                          <CustomizedMenu
                            menuItems={menuItemsc}
                            onClick={(lab) => onClickRequiredHeader(lab)}
                          />
                        </TableCell>
                        {
                          selectedItems?.map((item) => item?.disable ? null :
                            item?.text === 'Status' ? null : item?.text === 'Tags' ? <TableCell style={{ minWidth: '150px', fontWeight: '700', position: 'relative', maxWidth: '200px' }} size="small">
                              Tags
                              <CustomizedMenu
                                menuItems={menuItemsDynamic}
                                onClick={(lab) => onClickRequiredHeader("Tags", { text: 'Tags', isSelected: true, id: 12 })}
                              />
                            </TableCell> :
                              <TableCell style={{ maxWidth: '250px', fontWeight: '700', position: 'relative' }} size="small">
                                {item?.text}
                                <CustomizedMenu
                                  menuItems={menuItemsDynamic}
                                  onClick={(lab) => onClickRequiredHeader(lab, item)}
                                />
                              </TableCell>
                          )
                        }

                      </TableRow>
                    </CustomHead>


                    {currentData?.map((item, index) =>
                      rowItem(item, index)
                    )}

                  </Table>
                 
                  {searchContact?.length>90&&  <Pagination
                      count={Math.ceil(searchContact.length / 100)}
                      page={currentPage}
                      onChange={handlePageChange}
                      sx={{
                        display: 'flex', justifyContent: 'center',
                          marginTop:'50px',marginBottom:'100px',
                        '& .MuiPaginationItem-root': {
                          color: '#000',
                        },
                        '& .MuiPaginationItem-root.Mui-selected': {
                          backgroundColor: '#0F52BA',
                          color: '#fff',
                        },
                      }}
                    />}
                 
                </div>

              </div>
            }

          </CustomTable>

        </Flexbox>
        {isDetailOpen && <FullScreenModal
          isOpen={modalOpen}
          onClose={() => setModalOpen(false)}
        >
          <div style={{ display: 'flex' }}>
            <ContactDetails
              setModal={() => setIsDetailOpen(false)}
              value={contactDetail}
              setConnection={(call) => {
                props?.setConnection(call)
              }}
            />
          </div>
        </FullScreenModal>}

        {modalOpen && <RightModal
          customStyle={{ backgroundColor: 'white' }}
          isOpen={modalOpen}
          onClose={() => setModalOpen(false)}
        >
          <div style={{ height: '100%', display: 'flex' }}>
            <RightSideConversations setConnection={(call) => {
              // setConnection(call)
            }} value={customerValue} />
          </div>
        </RightModal>}
        {opendAddContact && <FullScreenModal
          isOpen={opendAddContact}
          onClose={() => {
            setOpenAddContact(false)

            searchParams.delete('contact');
            setSearchParams(searchParams)
          }}
        >
          <div style={{ height: '100%', display: 'flex', justifyContent: 'center' }}>
            <AddContact
              onCancel={(num) => {
                init()
                setOpenAddContact(false)
                searchParams.delete('contact');
                setSearchParams(searchParams)


              }}
              onSubmit={(num) => {
                init()
                setOpenAddContact(false)
                searchParams.delete('contact');

                setSearchParams(searchParams)
                navigate(`/dashboard/contacts/${num?._id}`)

              }}
            />
          </div>
        </FullScreenModal>}
      </Body>
    </>
  )
}


export default ContactList;
const Body = styled.div`
display:flex;
width:calc(100% - 240px);
overflow-x:scroll;
overflow-y:hidden;
position: fixed;
top: 0;
left: 0;
right: 0;
bottom: 0;
margin-left:240px;
`
const StyledPopupContent = styled.div`
    background-color: #FAFAFA;
    box-shadow: 0 12px 48px rgba(32, 40, 77, 0.1), 0 32px 80px rgba(32, 40, 77, 0.1);
    opacity: 1.0;
    border-radius: 8px;
    width: 250px;
    background: white;
    position: absolute;
    top: 0px;
    left: 0px;
    z-index: 20000;
`
const StyledPopupItems = styled.div`
   
    display:flex;
flex-direction:column;
 
    justify-content:center;
    align-items:start;
`
const StyledPlayer = styled.div`
display:flex;
height:80vh; 
align-items:center; 
justify-content:center; 
`
const PopupValues = styled.div`

display:flex;
align-items:center;
border-bottom:1px solid #f3f3f3;
font-size:14px;
width:88%;
padding:8px;
cursor:pointer;
&:hover {
    background:#f3f3f3;
  }

`
const StyledPopupContainer = styled.div`
    z-index: 1500;
    position: absolute;
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-content: stretch;
    align-items: center;
`
const StyledContactFlex = styled.div`
 display:flex; 
 justify-content:space-between; 
 align-items:center; 
 margin-left:20px; 
 margin-right:20px;
 margin-top:20px;
 margin-bottom:20px; 

 h1{
  text-align:center; 
  margin-top:5px; 
  margin-bottom:5px; 
 }

`

const CustomTable = styled(TableContainer)`
  &::-webkit-scrollbar {
    display: none; /* Hide the scrollbar */
  }
  border-color: red;

  .MuiTableBody-root {
   
    font-family: Inter;
    font-size: 13px;
  }

  .MuiTableHead-root {
    font-weight: 700;
    font-family: Inter;
    font-size: 14px;
    color:${palette.black};
  }

  .MuiTableRow-root {
    height: 35px;
   
    font-family: Inter;
    font-size: 13px;
    &:hover {
      background: #f8f8f8;
    }
  }

  .MuiTableCell-root {
    border-color: #f3f3f3;
    border-right:1px solid #f3f3f3;
    height:10px;
    font-family: Inter;
    white-space: nowrap;
    word-break: break-word;
    border-collapse: separate;
    line-height: 16px;
    color:${palette.black};
    font-size: 13px;
    font-weight:450;
  }
  .sticky {
    position: sticky;
    left: 0;
    z-index: 2;
    background: white;
  
  }
  .MuiTableCell-root:first-child {
    position: sticky;
    left: 0;
    background: #fff;
    border-left:1px solid #f3f3f3;
    font-weight:700;
    color:${palette.newBlue};
    z-index: 1;
    min-width:150px;
 
  }
  .MuiTableRow-root:hover .MuiTableCell-root:first-child {
    background:#f8f8f8;
  }

  .purple {
    background: #974EDE;
  }

  .green {
    background: #32cd32;
  }

  .dimgreen {
    background: #008000;
  }
`;

const CustomHead = styled(TableHead)`
position: sticky;
  top: 0;
  background-color: white;
  z-index: 2;
border-radius:8px;
font-size:14px;
color:${palette.black};
border-top:1px solid #f3f3f3;
color:#000;
height:10px;
flex-wrap:wrap;
background:#f9f9f9;
.MuiTableCell-root:first-child {
  position: sticky;
  background:#f9f9f9;
  color:${palette.black};
  z-index: 1;
  min-width:150px;

}
`
const Flexbox = styled.div`
width:100%;
background:#fff;
overflow-y:hidden;
display:flex;
@media (min-width: 260px) and (max-width: 1300px){

    flex-shrink: 0;
overflow: auto; 
}
`
const SelectBox = styled.div`
border:none;
margin-bottom:0px;
cursor:pointer;
padding-left:8px;
padding-right:8px;
border-radius:4px;
padding-top:4px;
paddin-bottom:4px;
&:hover {
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.2); 
}
`
const IconBox = styled.div`
display:flex;
justify-content:center;
align-items:center;
padding-top:4px;
padding-bottom:4px;
border-radius:5px;
color:white;
`

const OptionBox = styled.div`
padding-left:25px;
padding-right:25px;
height:35px;
border-radius:8px;
font-weight:500;
font-size:14px;
background:#0F52BA;
color:#fff;
display:flex;
align-items:center;
justify-content:center;
cursor:pointer;
margin-right:15px;
margin-top:5px; 
&:hover {
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.2); 
}

`
const Icons = styled.img`
width: 15px;
height: 15px;
`

const CustomTextField = styled(TextField)`
border-radius: 8px;
&& {
   
    height: 25px; /* Adjust the height as needed */
    padding: 5px; /* Adjust the padding as needed */
}
`;

const CustomInputAdornment = styled(InputAdornment)`
  
`;

const CustomIconButton = styled(IconButton)`
 
`;