import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import ReactDatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';
import './App.css'
import { CalendarMonthOutlined } from '@mui/icons-material';
import { palette } from '../utils/Palette';


// Styled components
const DatePickerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
 
  position: relative;
`;

const Label = styled.label`
  font-size: 13px;
  font-weight: 500;
  margin-bottom: 5px;
  margin-left:3px;
`;

const DateButton = styled.button`
  display: flex;
  align-items: center;
  padding: 8px;
  font-size: 13px;
  border: 1px solid #ddd;
  border-radius: 4px;
  background: #fff;
  width:100%;
  min-width:150px;
  position:relative;
  padding=left:30px;
  display:flex;
  justify-content:space-between;
  cursor: pointer;
`;

const DatePicker = styled(ReactDatePicker)`
.react-datepicker__triangle {
    display: none;
  }
  .react-datepicker {
    z-index: 25555555555555555555555555555555; /* Ensure the date picker appears above other elements */
  }
`;

const CustomInput = ({ value, onClick, disabled,customStyle }) => (
    <DateButton disabled={disabled} onClick={onClick} style={customStyle}>
        {value || 'Date'}
        <CalendarMonthOutlined style={{ fontSize: '16px' }} alt="calendar icon" />
    </DateButton>
);

CustomInput.propTypes = {
    value: PropTypes.string,
    onClick: PropTypes.func,
    disabled: PropTypes.bool,
    customStyle:PropTypes.object
};

// DatePicker component
const DatePickerComponent = ({ label, selectedDate, onDateChange, disabled,customStyle }) => {
    return (
        <DatePickerWrapper>
            {label && <Label>{label}</Label>}
            <DatePicker
              disabled={disabled}
                selected={selectedDate}
                onChange={onDateChange}
                dateFormat="dd MMM yyyy"
                popperPlacement='top-end'
            
            
            

                customInput={<CustomInput
                    disabled={disabled}
                    customStyle={customStyle}
                />}
                dropdownMode="select"

            />
        </DatePickerWrapper>
    );
};

// PropTypes validation
DatePickerComponent.propTypes = {
    label: PropTypes.string,
    selectedDate: PropTypes.instanceOf(Date),
    onDateChange: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    customStyle:PropTypes.object
    
};

DatePickerComponent.defaultProps = {
    label: '',
    selectedDate: null,
};

export default DatePickerComponent;
