import { Calendar, momentLocalizer, Views } from 'react-big-calendar'
import moment from 'moment'
import * as React from 'react';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { fetchTeamData, getBookings } from '../../../store/Actions/dashboard.action';
import { useDispatch } from 'react-redux';

import styled from 'styled-components'
import 'react-tooltip/dist/react-tooltip.css'
import { useState } from 'react';
import { colorArray, formatMobileNumber } from '../../../utils/Commonfunction';
import { Box, Button, Divider, MenuItem, Popover, Select, Tooltip, Typography } from '@mui/material';
import FullScreenModal from '../../FullScreenModal';
import CreateBooking from '../CreateBooking';
import { palette } from '../../../utils/Palette';
import { useNavigate, useNavigation, useSearchParams } from 'react-router-dom';
import RippleButton from '../../../ReuseableComponets/RippleButton';
import ToggleTabs from '../../../ReuseableComponets/ToggleTab';
import { ArrowBackIosNew, ArrowForwardIos, CalendarMonth } from '@mui/icons-material';

const localizer = momentLocalizer(moment);

const CustomTimeGrid = ({ events, localizer, date }) => {
    const times = [];
    let time = moment().startOf('day');
    while (time.isBefore(moment().endOf('day'))) {
        times.push(time.clone());
        time.add(1, 'hour');
    }

    return (
        <CustomTimeGridContainer>
            <div className="times">
                {times.map((timeSlot, idx) => (
                    <div key={idx} className="time-slot">
                        {localizer.format(timeSlot.toDate(), 'hh:mm A')}
                    </div>
                ))}
            </div>
            <div className="events">
                {events.map((event, idx) => {
                    const start = moment(event.start);
                    const end = moment(event.end);
                    const duration = moment.duration(end.diff(start)).asHours();
                    const startOffset = moment.duration(start.diff(moment().startOf('day'))).asHours();

                    return (
                        <div
                            key={idx}
                            className="event"
                            style={{
                                left: `${startOffset * 100}px`,
                                width: `${duration * 100}px`
                            }}
                        >
                            {event.title}
                        </div>
                    );
                })}
            </div>
        </CustomTimeGridContainer>
    );
};


function Bookings() {
    const [selectedEvent, setSelectedEvent] = React.useState(null);
    const localizer = momentLocalizer(moment)
    const [event, setEvents] = React.useState([])
    const [AllData, setAllData] = React.useState([])
    const [ismodal, setIsModal] = useState(false)
    const [team, setTeam] = React.useState([])
    const [selected, setSelected] = useState({})
    const dispatch = useDispatch()
    const [filter, setFilter] = useState("Week")
    const [tooltipContent, setTooltipContent] = useState('');
    const [tooltipId, setTooltipId] = useState('');
    const [value, setValue] = useState({})
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const [type, setType] = useState('week')

    const handleEventClick = (event) => {

        setTooltipContent(`Event: ${event?.title}`);
        setTooltipId(`tooltip-${event?.title}`);



    };

    const fetchTeam = async (val) => {
        setSelected(val)
        if (val?.email === 'AllData') {
            setEvents(AllData)

            return
        }
        setEvents([])
        const res = await dispatch(fetchTeamData({
            "emails": [val?.email]
        }))
        let arr = []
        res.data?.[0]?.bookings.map((item) => {
            arr.push({
                data: item,
                title: item?.name || item?.to,
                start: new Date(new Date(item?.date).getFullYear(), new Date(item?.date).getMonth(), new Date(item?.date).getDate(), new Date(item?.time).getHours(), new Date(item?.time).getMinutes()),
                end: item?.endDate && item?.endTime ? new Date(new Date(item?.endDate).getFullYear(), new Date(item?.endDate).getMonth(), new Date(item?.endDate).getDate(), new Date(item?.endTime).getHours(), new Date(item?.endTime).getMinutes()) : new Date(new Date(item?.date).getFullYear(), new Date(item?.date).getMonth(), new Date(item?.date).getDate(), new Date(item?.time).getHours() + 1, new Date(item?.time).getMinutes()),
                color: '#daf0ff',
                description: item?.customerAddress,
            })
        })
        setEvents(arr)
    }

    const init = async () => {


        const res1 = await dispatch(getBookings())
        let arr = []
        let teamArr = []
        const dataarr = [...res1.data?.present, ...res1.data?.past]
        dataarr?.map((item) => {
            let startdat = new Date(new Date(item?.date).getFullYear(), new Date(item?.date).getMonth(), new Date(item?.date).getDate(), new Date(item?.time).getHours(), new Date(item?.time).getMinutes())
            if (!isNaN(startdat)) { // Check if startdat is a valid date
                console.log(startdat, new Date(new Date(item?.endDate).getFullYear(), new Date(item?.endDate).getMonth(), new Date(item?.endDate).getDate(), new Date(item?.endTime).getHours(), new Date(item?.endTime).getMinutes()));
                arr.push({
                    data: item,
                    title: item?.name || item?.to,
                    start: startdat,
                    end: item?.endDate && item?.endTime ? new Date(new Date(item?.endDate).getFullYear(), new Date(item?.endDate).getMonth(), new Date(item?.endDate).getDate(), new Date(item?.endTime).getHours(), new Date(item?.endTime).getMinutes()) : new Date(new Date(item?.date).getFullYear(), new Date(item?.date).getMonth(), new Date(item?.date).getDate(), new Date(item?.time).getHours() + 1, new Date(item?.time).getMinutes()),
                    color: '#daf0ff',
                    description: item?.customerAddress,
                    status: item?.bookingStatus
                });
            }
        })
        res1?.data?.team?.map((item, index) => {
            teamArr.push({ ...item, index: index + 1 })
        })
        setEvents(arr)
        setAllData(arr)
        setTeam([...teamArr])
    }
    React.useEffect(() => {
        const invoiceParam = searchParams.get('type');
        console.log(invoiceParam, "dkparam")
        if (invoiceParam) {
            setType(invoiceParam)
            setFilter(invoiceParam?.charAt(0).toUpperCase() + invoiceParam?.slice(1))
            console.log(Views.MONTH)
        }

        init()
    }, [])
    const handleSelectEvent = (event) => {
        setValue({})
        // setIsModal(true)
        // setSelectedEvent(event?.data);
        // console.log(event?.data?.referenceNo)
        navigate(`/dashboard/calander/${event?.data?.referenceNo}`)
    };

    const MyEvent = ({ event }) => {
        console.log(event, "fhjdfbdjb")


        return (
            <TooltipStyled
                disableTouchListener
                disableInteractive={false}
                placement={'right-start'}
                PopperProps={{
                    disableInteractive: true, // Ensure the Popper itself is interactive
                }}
                componentsProps={{
                    tooltip: {
                        sx: {
                            backgroundColor: '#fff',
                            color: '#000',
                            boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.3)',
                            border: '1px solid #ddd',
                            fontSize: '14px',
                            maxHeight: '350px',
                            paddingBottom: '40px',
                            width: '350px',

                            overflowY: 'scroll',
                        },
                    },
                    arrow: {
                        sx: {
                            color: '#fff',
                        },
                    },
                }}
                title={
                    <div onClick={(e) => e.stopPropagation()} style={{ marginTop: '5px', marginBottom: '5px' }}>
                        <EventBox>
                            <Typography variant="body2" color="textSecondary" style={{ color: palette.newBlue, fontSize: '12px' }}>
                                {`${moment(event?.start).format('DD MMM, hh:mm A')} - ${moment(event?.end).format('DD MMM, hh:mm A')}`}
                            </Typography>
                            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'start' }}>
                                <div>
                                    <EventTitle>{event?.title}</EventTitle>
                                    {event?.data?.email && <StyledTypography style={{ marginBottom: '6px' }} variant="body2" color="textSecondary">
                                        {formatMobileNumber(event?.data?.email)}
                                    </StyledTypography>}
                                    <StyledTypography variant="body2" color="textSecondary">
                                        {formatMobileNumber(event?.data?.customerNumber)}
                                    </StyledTypography>
                                </div>
                                <BookingStatus status={event?.data?.bookingStatus}>
                                    <span style={{ marginRight: '5px' }}>●</span>
                                    {event?.data?.bookingStatus === 'Waiting for confirmation' ? 'Open' : event?.data?.bookingStatus}
                                </BookingStatus>
                            </div>
                            <Divider />
                            <LocationTitle>Location</LocationTitle>
                            <StyledTypography >
                                {event?.description}
                            </StyledTypography>
                            {event?.data?.team?.length > 0 && <>
                                <Divider />
                                <LocationTitle>Crew</LocationTitle>
                                {event?.data?.team?.map(item =>
                                    <StyledTypography variant="body2" color="textSecondary" key={item.id}>
                                        {item?.firstName} {item?.lastName}
                                    </StyledTypography>
                                )}
                            </>}
                            {event?.data?.items?.length > 0 && <>
                                <Divider />
                                <LocationTitle>Line items</LocationTitle>
                                {event?.data?.items?.map(item =>
                                    <StyledTypography variant="body2" color="textSecondary" key={item.id}>
                                        {item?.name} x {item?.quantity}
                                    </StyledTypography>
                                )}
                            </>}
                            {/* <Button
                  onClick={() => alert('Button clicked!')}
                  variant="contained"
                  color="primary"
                  sx={{ marginTop: 2 }}
                >
                  Click Me
                </Button> */}
                        </EventBox>
                        <div style={{ display: 'flex', justifyContent: 'space-around', width: '302px', paddingBottom: '13px', paddingTop: '10px', position: 'fixed', bottom: 5, background: '#fff' }}>
                            <RippleButton
                                onClick={() => navigate(`/dashboard/calander/${event?.data?.referenceNo}`)}
                                variant="contained"
                                color="primary"
                                style={{ width: '130px', fontWeight: '500', fontSize: '13px', background: '#fff', color: '#000', border: '1px solid #ddd' }}
                            >
                                Edit job
                            </RippleButton>
                            <RippleButton
                                onClick={() => navigate(`/dashboard/calander/${event?.data?.referenceNo}`)}
                                variant="contained"
                                color="primary"
                                style={{ width: '130px', fontWeight: '500', fontSize: '13px', }}
                            >
                                View details
                            </RippleButton>
                        </div>
                    </div>

                }
            >
                <EventDiv status={event?.status}>
                    {event?.title}
                    <Description>{event.description}</Description>
                    {moment(event?.start).format('dd mm yyyy') === moment(event?.end).format('dd mm yyyy') &&
                        <TimeRange>{`${moment(event?.start).format('hh:mm A')} - ${moment(event?.end).format('hh:mm A')}`}</TimeRange>}
                </EventDiv>
            </TooltipStyled>
        );
    };


    const eventStyleGetter = (event, start, end, isSelected) => {
        const style = {
            backgroundColor: selected?.index ? colorArray[selected?.index] : '#D6E3F6',
            borderRadius: '5px',
            padding: '6px',
            opacity: 0.8,
            color: 'black',
            fontSize: '12px',
            marginLeft:'3px',
            opacity: 1.0,
            display: 'block',
            border: '0px solid #fff',
            borderLeft: selected?.index ? `3.5px solid ${colorArray[selected?.index]}` : `3.5px solid ${palette.newBlue}`,


        };

        return {
            style,
        };
    };
    const MyToolbar = (toolbar) => {

        const goToToday = () => {

            toolbar.onNavigate('TODAY');
        };

        const goToWeek = () => {
            setFilter('Week')
            setType(Views.WEEK)
            toolbar.onView('week');
        };

        const goToDay = () => {
            setFilter('Day')
            setType(Views.DAY)
            toolbar.onView('day');
        };
        const goToMonth = () => {
            setFilter('Month')
            setType(Views.MONTH)
            toolbar.onView('month');
        };
        // const goToAgendaView = () => {

        //     toolbar.onView('agenda');
        // };


        return (
            <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between', alignItems: 'center' }}>
                <div className="rbc-toolbar">


                    <span style={{ display: 'flex', marginLeft: '20px' }} className="rbc-btn-group">
                        <button style={{ marginRight: '20px', borderRadius: '4px', border: '1.2px solid gray', fontSize: '14px', fontWeight: '500' }} type="button" onClick={goToToday}>
                            Today
                        </button>
                        <button style={{ width: '30px', display: 'flex', border: '0px solid gray', justifyContent: 'center', borderRadius: '4px', alignItems: 'center' }} type="button" onClick={() => toolbar.onNavigate('PREV')}>
                            <ArrowBackIosNew style={{ fontSize: '20px' }} />
                        </button>

                        <button style={{ width: '30px', display: 'flex', border: '0px solid gray', borderRadius: '4px', justifyContent: 'center', alignItems: 'center' }} type="button" onClick={() => toolbar.onNavigate('NEXT')}>
                            <ArrowForwardIos style={{ fontSize: '20px' }} />
                        </button>
                        <span style={{ alignSelf: 'center',display:'flex',alignItems:'center', fontSize: '14px', fontWeight: '500' }} className="rbc-toolbar-label">
                            <CalendarMonth />
                            <div style={{marginLeft:'8px'}}>{toolbar.label}</div>
                        </span>

                    </span>

                    <span className="rbc-toolbar-label"></span>
                    {/* <span style={{ marginRight: '20px' }} className="rbc-btn-group">
                    <Selectbox
                        style={{ marginTop: '0px', borderRadius: '8px', border: '1px solid #f3f3f3', fontSize: '14px' }}
                        placeholder='select' value={'Select an option'} displayEmpty
                        renderValue={() => <div>{filter}</div>} onChange={(e) => {
                            console.log(e.target)

                        }}
                        MenuProps={{ // Add MenuProps to customize menu styling
                            PaperProps: {
                                style: {
                                    marginTop: '8px', // Adjust this value for the desired gap
                                },
                            },
                        }}
                    >
                        {team?.length > 0 ? <StyledMenuItem type="button" onClick={() => {
                            setFilter('Day')
                            navigate('/dashboard/timeline')
                        }}>
                            Day
                        </StyledMenuItem> : <StyledMenuItem type="button" onClick={goToDay}>
                            Day
                        </StyledMenuItem>}

                        <StyledMenuItem type="button" onClick={goToWeek}>
                            Week
                        </StyledMenuItem>
                        <StyledMenuItem type="button" onClick={goToMonth}>
                            Month
                        </StyledMenuItem>


                        {/* <StyledMenuItem type="button" onClick={goToAgendaView}>
                        Agenda
                    </StyledMenuItem> */}
                    {/* </Selectbox> */}





                </div>
                <div style={{ marginBottom: '15px', marginRight: '15px' }}>
                    <ToggleTabs
                        labels={['Week', 'Month', "Day"]}
                        selected={filter}
                        onToggle={(val) => {

                            if (val === 'Week') {
                                goToWeek()
                            }
                            else if (val === 'Month') {
                                goToMonth()
                            }
                            else if (val === 'Day') {
                                setFilter('Day')
                                navigate('/dashboard/timeline')
                            }
                        }}

                    />
                </div>
            </div>
        );
    };
    const dayHeaderContent = ({ label, view }) => {

        const dayNumber = moment(label).format('DD'); // Extract the day number
        const dayName = moment(label).format('ddd'); // Extract the day name


        return (
            <div style={{ textAlign: 'start', height: '35px', padding: '5px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <div style={{ fontSize: '14px', color: 'black', fontWeight: '500' }}>{dayNumber}</div>
                <div style={{ fontSize: '14px', color: 'black', fontWeight: '500', marginLeft: '5px' }}>{dayName}</div>
            </div>
        );
    };
    const dayssHeaderContent = ({ label, view }) => {

        const dayNumber = moment(label).format('DD'); // Extract the day number
        const dayName = moment(label).format('ddd'); // Extract the day name


        return (
            <div style={{ textAlign: 'start', height: '35px', padding: '5px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <div style={{ fontSize: '14px', color: 'black', fontWeight: '500' }}>{dayNumber}</div>
                <div style={{ fontSize: '14px', color: 'black', fontWeight: '500', marginLeft: '5px' }}>{dayName}</div>
            </div>
        );
    };
    const MyTimeSlotWrapper = (props) => {
        const { children, value } = props;

        return (
            <div style={{ fontSize: '13px', marginTop: 5, borderTop: 'none', color: 'rgba(32, 32,32, 0.75)', paddingLeft: '10px', overflow: 'hidden' }}>
                {children}
            </div>)
    };
    const dayColumnWrapper = (props) => {
        const { children, value } = props;

        return (
            <div style={{ fontSize: '13px', marginTop: 5, flexDirection: 'row', display: 'flex', borderTop: 'none', color: 'rgba(32, 32,32, 0.75)', paddingLeft: '10px', overflow: 'hidden' }}>
                {children}
            </div>)
    };
    const dayFormat = 'dddd, MMMM D, YYYY';
    const monthFormat = 'MMMM YYYY';


    const dayPropStyles = (date) => {
        const isToday = moment(date).isSame(moment(), 'day');

        return {
            style: {

                backgroundColor: isToday ? 'transparent' : 'white', // Set the background color to transparent for today's date

            },
        };
    }
    const MyEventWrapper = ({ children }) => (
        <div
            style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',

                background: 'none',
                borderRadius: 4,
            }}
        >
            {children}
        </div>
    );

    const getName = (channel) => {
        if (!channel) {
            return
        }
        const val = channel?.split(' ')

        if (val.length == 2) {
            return `${val[0]?.charAt(0).toUpperCase()}${val[1]?.charAt(0).toUpperCase()}`
        }
        else {
            return `${val[0]?.charAt(0).toUpperCase()}`
        }
    }

    const handleSelectSlot = (e) => {
        console.log(e)

        setValue({
            date: e?.start?.toISOString(),
            endDate: e?.end?.toISOString(),
            time: e?.start?.toISOString(),
            endTime: e?.end?.toISOString(),
            edit: true

        })
        setIsModal(true)

        // setIsModal(true)
        // `start` and `end` contain the selected range of dates

        // You can perform any actions with the selected dates here
    };
    const minTime = new Date();
    minTime.setHours(1, 0, 0, 0);
    const maxTime = new Date();
    maxTime.setHours(23, 0, 0, 0);

    const timeGutterWraper = ({ event }) => {
        console.log(event, "dccjcdn")
        return (
            <div style={{ height: 'auto', width: '125%', fontSize: '13px', marginTop: '29px', paddingTop: '5px', borderTop: '1px solid #ddd', marginLeft: '-10px', marginRight: '-20px', color: 'rgba(32, 32,32, 0.75)', paddingLeft: '10px' }}>
                All day
            </div>
        )
    }
    const CustomMonthEvent = ({ event }) => {
        return (
            <TooltipStyled
            disableTouchListener
            disableInteractive={false}
            placement={'right-start'}
            PopperProps={{
                disableInteractive: true, // Ensure the Popper itself is interactive
            }}
            componentsProps={{
                tooltip: {
                    sx: {
                        backgroundColor: '#fff',
                        color: '#000',
                        boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.3)',
                        border: '1px solid #ddd',
                        fontSize: '14px',
                        maxHeight: '350px',
                        paddingBottom: '40px',
                        width: '350px',

                        overflowY: 'scroll',
                    },
                },
                arrow: {
                    sx: {
                        color: '#fff',
                    },
                },
            }}
            title={
                <div onClick={(e) => e.stopPropagation()} style={{ marginTop: '5px', marginBottom: '5px' }}>
                    <EventBox>
                        <Typography variant="body2" color="textSecondary" style={{ color: palette.newBlue, fontSize: '12px' }}>
                            {`${moment(event?.start).format('DD MMM, hh:mm A')} - ${moment(event?.end).format('DD MMM, hh:mm A')}`}
                        </Typography>
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'start' }}>
                            <div>
                                <EventTitle>{event?.title}</EventTitle>
                                {event?.data?.email && <StyledTypography style={{ marginBottom: '6px' }} variant="body2" color="textSecondary">
                                    {formatMobileNumber(event?.data?.email)}
                                </StyledTypography>}
                                <StyledTypography variant="body2" color="textSecondary">
                                    {formatMobileNumber(event?.data?.customerNumber)}
                                </StyledTypography>
                            </div>
                            <BookingStatus status={event?.data?.bookingStatus}>
                                <span style={{ marginRight: '5px' }}>●</span>
                                {event?.data?.bookingStatus === 'Waiting for confirmation' ? 'Open' : event?.data?.bookingStatus}
                            </BookingStatus>
                        </div>
                        <Divider />
                        <LocationTitle>Location</LocationTitle>
                        <StyledTypography >
                            {event?.description}
                        </StyledTypography>
                        {event?.data?.team?.length > 0 && <>
                            <Divider />
                            <LocationTitle>Crew</LocationTitle>
                            {event?.data?.team?.map(item =>
                                <StyledTypography variant="body2" color="textSecondary" key={item.id}>
                                    {item?.firstName} {item?.lastName}
                                </StyledTypography>
                            )}
                        </>}
                        {event?.data?.items?.length > 0 && <>
                            <Divider />
                            <LocationTitle>Line items</LocationTitle>
                            {event?.data?.items?.map(item =>
                                <StyledTypography variant="body2" color="textSecondary" key={item.id}>
                                    {item?.name} x {item?.quantity}
                                </StyledTypography>
                            )}
                        </>}
                        {/* <Button
              onClick={() => alert('Button clicked!')}
              variant="contained"
              color="primary"
              sx={{ marginTop: 2 }}
            >
              Click Me
            </Button> */}
                    </EventBox>
                    <div style={{ display: 'flex', justifyContent: 'space-around', width: '302px', paddingBottom: '13px', paddingTop: '10px', position: 'fixed', bottom: 5, background: '#fff' }}>
                        <RippleButton
                            onClick={() => navigate(`/dashboard/calander/${event?.data?.referenceNo}`)}
                            variant="contained"
                            color="primary"
                            style={{ width: '130px', fontWeight: '500', fontSize: '13px', background: '#fff', color: '#000', border: '1px solid #ddd' }}
                        >
                            Edit job
                        </RippleButton>
                        <RippleButton
                            onClick={() => navigate(`/dashboard/calander/${event?.data?.referenceNo}`)}
                            variant="contained"
                            color="primary"
                            style={{ width: '130px', fontWeight: '500', fontSize: '13px', }}
                        >
                            View details
                        </RippleButton>
                    </div>
                </div>

            }
        >
                <div style={{ padding: '0px', borderRadius: '6px', fontSize: '14px' }}>

                {event?.title}
            </div>
        </TooltipStyled>
        );
      };

    return (
        <Body>
            <div style={{ display: 'flex', width: '100%', marginLeft: '-1px', height: '100vh', paddingTop: '15px', }}>

                <CalanderTime
                    localizer={localizer}
                    events={event}

                    startAccessor="start"
                    view={type}
                    onSelectEvent={handleSelectEvent}
                    selectable
                    tooltipAccessor={null}
                    eventPropGetter={eventStyleGetter}
                    dayLayoutAlgorithm={'no-overlap'}

                    endAccessor="end"
                    style={{ height: '100%', width: '100%' }}
                    timeslots={2}
                    step={15}
                    min={minTime}
                    max={maxTime}
                    popup={true}
                    onSelectSlot={handleSelectSlot}


                    formats={{
                        dayFormat: dayFormat,
                        monthHeaderFormat: monthFormat,
                        timeGutterFormat: 'h:mm A',
                        eventTimeRangeFormat: () => {
                            return ""
                        }





                    }}
                    allDayMaxRows={5}

                    allDayAccessor={(event) => console.log(event, "ckscjkcsjkncdsnjkcdsnjkdc")}
                    dayPropGetter={dayPropStyles}


                    components={{
                        toolbar: MyToolbar,
                        day: {
                            header: dayssHeaderContent
                        },


                        week: {
                            header: dayHeaderContent,






                        },
                        month:{
                                event:CustomMonthEvent
                        },

                        timeSlotWrapper: MyTimeSlotWrapper,
                        timeGutterHeader: timeGutterWraper,
                        dayColumnWrapper: dayColumnWrapper,


                        event: MyEvent,
                        eventContainerWrapper: MyEventWrapper,




                    }}
                />


                {
                    ismodal && <FullScreenModal onClose={() => setIsModal(false)}>

                        <CreateBooking
                            value={value?.edit ? value : selectedEvent}
                            team={team}
                            refresh={() => init()}
                            serviceRequest={value?.edit ? false : true}
                            newService={true}
                            setCreateBookingModal={() => {
                                init()
                                setIsModal(false)
                            }}
                            onSubmitsw={(val, editValue) => {

                                // sendchat(val, editValue)
                                init()
                                setIsModal(false)



                            }
                            }

                        />
                    </FullScreenModal>
                }
            </div>
        </Body>
    );
}
export default Bookings;
const Body = styled.div`
display:flex;
width:calc(100% - 240px);
overflow-x:scroll;
overflow-y:hidden;
position: fixed;
top: 0;
left: 0;
right: 0;
bottom: 0;
margin-left:240px;
`
const OutProfile = styled.div`
background:#2b373f;
color:white;
height:25px;
width:25px;
display:flex;
justify-content:center;
align-items:center;
font-size:14px;
padding-bottom:0px;
margin-right:12px;
border-radius:25px;
margin-left:-5px;
`
const TeamMain = styled.div`
display:flex;
flex-direction:column;
margin-top:30px;
cursor:pointer;
margin-left:10px;
margin-right:10px;
width:230px;

p{
    font-size:14px;
    text-align:start;
}
.innerContainer{
    display:flex;
    border-radius:5px;
    padding-top:5px;
    padding-bottom:5px;
    padding-right:15px;
    padding-left:15px;
    align-items:center;
    margin-bottom:10px;
    width:100%;

}
.blueseg{

}
`
const Icon = styled.img`
height:13px;

margin-right:10px;
padding-left:8px;
color:black
`

const CalanderTime = styled(Calendar)`

.rbc-time-view .rbc-row{
    min-height:30px;
    
    max-height: calc(100vh - 200px); 
}
.rbc-day-slot {
    position:none;
}
.rbc-timeslot-group {
    max-height:200px;
  }
  

.rbc-time-view .rbc-row div:nth-child(1) {
    height: auto !important;
    display:flex;
   
    align-items:center;

}

 
  .rbc-events-container{
      overflow:hidden;
  }
  .rbc-month-row {
    min-height: 120px; /* Increase height to fit more events */
  }
  


`

const TooltipStyled = styled(Tooltip)`
  .MuiTooltip-tooltip {
    background-color: #fff;
    color: #000;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.3);
    border: 1px solid #ddd;
    font-size: 14px;
    max-height: 350px;
    width: 350px;
    overflow-y: scroll;
  }

  .MuiTooltip-arrow {
    color: #fff;
  }
`;

const EventBox = styled(Box)`
  padding: 4px;

`;

const EventTitle = styled.h4`
  margin-bottom: 4px;
  margin-top: 12px;
  font-weight: 500;
`;

const BookingStatus = styled.div`
  font-weight: 500;
  border-radius: 5px;
  display: flex;
  text-align: center;
  justify-content: start;
  margin-top: 12px;
  font-size: 13px;
  color: ${({ status }) =>
        status === 'Waiting for confirmation'
            ? '#045ad0'
            : status === 'In Progress'
                ? '#29740f'
                : status === 'Completed'
                    ? '#004D4D'
                    : '#fa811f'};
`;

const LocationTitle = styled.h4`
  margin-bottom: 8px;
  margin-top: 8px;
  font-weight:500;
`;

const StyledTypography = styled.div`
  margin-bottom: 12px;
  font-size:13px;
  font-weight:400;
  color:rgba(32, 32,32, 0.75);
  
`;

const EventDiv = styled.div`
  height: 100vh;
  margin-right: 3px;
  text-decoration: ${({ status }) => status === 'Completed' && 'line-through'};
  margin-top: 5px;
  font-size: 12px;
  overflow: hidden;
  width:100%;
`;

const Description = styled.p`
  margin-bottom: 5px;
  margin-top: 5px;
  font-size: 12px;
  line-height: 16px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const TimeRange = styled.p`
  margin-bottom: 5px;
  margin-top: 5px;
  font-size: 10px;
  line-height: 16px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
const Selectbox = styled(Select)`

padding-right:10px;
border-radius:8px;
text-align:start;
margin-left:10px;
display:flex;
align-self:flex-start;
justify-content:flex-start;
border:1px solid #f3f3f3;
background:white;
height:38px;
font-size:14px;
 margin-top:10px;


.MuiMenuItem-root:hover {
    background: #dddddd;
    border-radius: 4px;
  }
}

`
const StyledMenuItem = styled(MenuItem)`
display:flex;
align-items:center;
border-bottom:1px solid #f3f3f3;
align-self:start;
height:45px;
font-size:14px;
  :hover {
    background: #dddddd;
    border-radius: 5px;
  }
`
const CustomTimeGridContainer = styled.div`
  .times {
    display: flex;
    justify-content: space-between;
  }
  .time-slot {
    padding: 5px;
    border: 1px solid #ddd;
    width: 100px;
    text-align: center;
  }
  .events {
    position: relative;
  }
  .event {
    position: absolute;
    top: 40px; /* Adjust this as needed */
    background-color: lightblue;
    border: 1px solid #ddd;
    padding: 5px;
  }
`;