import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import trash from '../../assets/trash.png'
import check from '../../assets/check.png'
import { deleteAddTask, DoneAddTask, getMissionControl } from "../../store/Actions/dashboard.action";
import calenda from '../../assets/calenda.png'
import moment from "moment";
import { Tab, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { Player } from "@lottiefiles/react-lottie-player";
import { palette } from "../../utils/Palette";
import { Check, DeleteOutlined } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";

const MissionControl = () => {
    const dispatch = useDispatch()
    const [data, setData] = useState([])
    const [loader, setLoader] = useState(false)
    const navigate=useNavigate();
    const init = async () => {
        setLoader(true)
        const res = await dispatch(getMissionControl())
        setData(res?.data?.all)
        setLoader(false)
    }

    useEffect(() => {
        init()
    }, [])

    const getName = (channel) => {
        if (!channel) {
            return
        }
        const val = channel?.split(' ')

        if (val.length == 2) {
            return `${val[0]?.charAt(0).toUpperCase()}${val[1]?.charAt(0).toUpperCase()}`
        }
        else {
            return `${val[0]?.charAt(0).toUpperCase()}`
        }
    }
    function capitalizeFirstLetter(string) {
        if (!string) return string;
        return string.charAt(0).toUpperCase() + string.slice(1);
      }
      
    return (
        <Body>
            <FlexboxContainer>
                <h1 >Notifications</h1>
                {loader ? <div style={{ display: 'flex', width: '100%', height: '100%', alignItems: 'center', justifyContent: 'center' }}> <Player
                    src={require('../../assets/loaders.json')}
                    className="player"
                    style={{ width: '200px', height: '200px' }}
                    loop
                    autoplay
                />
                </div>

                    : <CustomTable>

                        <div style={{ overflowY: 'auto',scrollbarWidth:'none', height: '99vh', paddingBottom: '120px' }}>
                            <Table sx={{ width: '100%' }} aria-label="simple table">
                                <CustomHead>
                                    <TableRow >

                                        <TableCell size='small'>
                                            <div >
                                                Name
                                            </div>
                                        </TableCell>
                                        <TableCell size="small" style={{ position: 'relative', color: palette.black, fontWeight: '700' }} >
                                            Description
                                        </TableCell>
                                        <TableCell size='small' style={{ minWidth: '120px', position: 'relative', color: palette.black, fontWeight: '700' }} >

                                            Date

                                        </TableCell>
                                        <TableCell size='small' style={{ minWidth: '80px', position: 'relative', color: palette.black, fontWeight: '700' }}>Type</TableCell>
                                        <TableCell size='small'></TableCell>
                                        <TableCell size='small'></TableCell>



                                    </TableRow>
                                </CustomHead>
                                {data?.map((item) => (
                                    <TableBody key={item._id} style={{ background: '#fff', cursor: 'pointer' }}>
                                        <TableRow>
                                            <TableCell size="small" onClick={()=>{
                                                  navigate(`/dashboard/contacts/${item?.contactId}`)
                                            }}>
                                                {item?.name && item?.name}
                                            </TableCell>
                                            <TableCell size="small">
                                                <TaskDetails>
                                                    <TaskLabel>{item?.task}</TaskLabel>
                                                </TaskDetails>
                                            </TableCell>
                                            <TableCell size="small">
                                                {item?.type == 'Task'||!item?.type ? `${moment(item?.date)?.format('DD MMM YYYY')}, ${moment(item.time).format("hh:mm A") === 'Invalid date' ? item?.time:moment(item.time).format("hh:mm A")}` : `${moment(item?.date)?.format('DD MMM YYYY')}, ${moment(item?.date)?.format('hh:mm A')}`}
                                            </TableCell>
                                            <TableCell size='small'>{capitalizeFirstLetter(item?.type || "Task")}</TableCell>
                                            <TableCell size="small" onClick={async () => {
                                                await dispatch(deleteAddTask(item?._id))
                                                init()
                                            }} >
                                                <DeleteOutlined style={{ fontSize: '20px' }} />
                                            </TableCell>
                                            <TableCell size="small" onClick={async () => {
                                                console.log(item, "dsds")
                                                await dispatch(DoneAddTask(item?._id));
                                                init()
                                            }}>
                                                <Check style={{ fontSize: '20px' }} />
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                ))}

                            </Table>
                            {data?.length > 0 ? null : <h3 style={{ color: 'gray', display: 'flex', justifyContent: 'center', marginTop: '30vh', marginBottom: '30vh' }}>No scheduled tasks</h3>}
                        </div>

                    </CustomTable>}


            </FlexboxContainer>
        </Body>
    )
}

export default MissionControl;

const FlexboxContainer = styled.div`
    width: 100%;
    background: #fff;
    display: flex;
    margin-left:30px;
    margin-right:30px;
    flex-direction: column;
    
    align-items: flex-start;
`;

const Body = styled.div`
display:flex;
width:calc(100% - 240px);
overflow-x:scroll;
overflow-y:hidden;
position: fixed;
top: 0;
left: 0;
right: 0;
bottom: 0;
margin-left:240px;
`

const CustomTable = styled(TableContainer)`
  &::-webkit-scrollbar {
    display: none; /* Hide the scrollbar */
  }
  border-color: red;

  .MuiTableBody-root {
   
    font-family: Inter;
    font-size: 13px;
  }

  .MuiTableHead-root {
    font-weight: 700;
    font-family: Inter;
    font-size: 14px;
    color:${palette.black};
  }

  .MuiTableRow-root {
    height: 35px;
   
    font-family: Inter;
    font-size: 13px;
    &:hover {
      background: #f8f8f8;
    }
  }

  .MuiTableCell-root {
    border-color: #f3f3f3;
    border-right:1px solid #f3f3f3;
    height:10px;
    font-family: Inter;
    white-space: nowrap;
    word-break: break-word;
    border-collapse: separate;
    line-height: 16px;
    color:${palette.black};
    font-size: 13px;
    font-weight:450;
  }
  .sticky {
    position: sticky;
    left: 0;
    z-index: 2;
    background: white;
  
  }
  .MuiTableCell-root:first-child {
    position: sticky;
    left: 0;
    background: #fff;
    border-left:1px solid #f3f3f3;
    font-weight:700;
    color:${palette.newBlue};
    z-index: 1;
    min-width:150px;
 
  }
  .MuiTableRow-root:hover .MuiTableCell-root:first-child {
    background:#f8f8f8;
  }

  .purple {
    background: #974EDE;
  }

  .green {
    background: #32cd32;
  }

  .dimgreen {
    background: #008000;
  }
`;

const CustomHead = styled(TableHead)`
position: sticky;
  top: 0;
  background-color: white;
  z-index: 2;
border-radius:8px;
font-size:14px;
color:${palette.black};
border-top:1px solid #f3f3f3;
color:#000;
height:10px;
flex-wrap:wrap;
background:#f9f9f9;
.MuiTableCell-root:first-child {
  position: sticky;
  background:#f9f9f9;
  color:${palette.black};
  z-index: 1;
  min-width:150px;

}
`
const ValueContainer = styled.div`
    padding: 15px;
    background: #fff;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

const ProfileIcon = styled.div`
    background: #2b373f;

    color: white;
    height: 25px;
    width: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
 
    font-size: 12px;
    padding-bottom: 0px;
    margin-right: 12px;
    border-radius: 25px;
    font-weight: bold;
`;

const NameLabel = styled.label`
    font-weight: bold;
    color: #344054;

`;

const TaskLabel = styled.label`
    color: #344054;
    text-align:start;
    
`;

const ActionsContainer = styled.div`
    display: flex;
    align-items: center;
`;

const DateIcon = styled.img`
    width: 15px;
    height: 15px;
    margin-right: 10px;
    cursor: pointer;
`;

const DateLabel = styled.label`
    margin-top: 5px;
    margin-right: 35px;
    color: #344054;
`;

const ActionIcon = styled.img`
    width: 22px;
    cursor: pointer;
`;

const TaskDetails = styled.div`
    display: flex;
    align-items: center;
    justify-content:start;
    align-self:start;
    max-width:350px;
    word-wrap: break-word; 
    word-break: break-word;
    white-space: pre-wrap;
`;

