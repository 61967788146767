import React, { useEffect, useMemo, useRef } from "react";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";

import * as XLSX from 'xlsx';
import { useState } from "react";
import { formatMobileNumber, putCommaInvalue, taxprice, totalprice } from "../../../utils/Commonfunction";
import { changePaymentMethod, getInvoiceQuote, sendInvoice, sendMailInvoice } from "../../../store/Actions/dashboard.action";
import { Player } from '@lottiefiles/react-lottie-player';

import moment from 'moment'
import Modal from "../../Modal";
import CreateInvoice from "../CreateInvoice";
import { sendMessage } from "../../../store/Actions/Auth.action";
import FullScreenModal from "../../FullScreenModal";
import PreviewInvoice from "../../../Pages/PreviewInvoice";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import money from '../../../assets/cash.png';
import atmcard from '../../../assets/card.png';
import moneyTransfer from '../../../assets/onlineTransfer.png';
import paymentimg from '../../../assets/banking.png';
import searchs from '../../../assets/searchs.png'
import { IconButton, InputAdornment, MenuItem, Pagination, Select, TextField } from "@mui/material";
import { plusIcon } from "../../../assets";
import { useLocation, useSearchParams } from "react-router-dom";
import { palette } from "../../../utils/Palette";
import CustomizedMenu from "../../../ReuseableComponets/MenuComponent";
import { AccountBalance, Cancel, CreditCard, DeleteOutlined, LocalAtm, NorthOutlined, KeyboardArrowDownOutlined, PaymentsOutlined, SouthOutlined } from "@mui/icons-material";
import { pdf, PDFDownloadLink } from "@react-pdf/renderer";
import NewInvoice from "../../../Pages/NewInvoice";
import ExportDropdown from "../../../ReuseableComponets/ExportComponent";
function EstimatePayments(props) {

    const location = useLocation()
    const popupRef = useRef(null);
    const detail = useSelector(state => state?.auth?.invoiceQuote)
    const userData = useSelector(state => state?.auth?.userData)
    const [searchParams, setSearchParams] = useSearchParams();
    const [loader, setLoader] = useState(false)
    const [modalOpen, setModalOpen] = useState(false)
    const [isDownloadOpen, setIsDownloadOpen] = useState(false)
    const [datadet, setDatadet] = useState({})
    const [invoice, setInvoice] = useState({})
    const [searchinvoice, setSearchInvoice] = useState(detail?.invoice || [])
    const [popupPosition, setPopupPosition] = useState({ x: 0, y: 0 });
    const [popupPositionDot, setPopupPositionDot] = useState({ x: 0, y: 0 });
    const [filter, setFilter] = useState("All invoice")
    const [statusModalOpen, setStatusModalOpen] = useState(false)
    const [isedit, setIsedit] = useState(false)
    const [search, setSearch] = useState('');
    const [statusDotOpen, setDotModalOpen] = useState(false)
    const [statusIndex, setStatusIndex] = useState(0)
    const [currentPage, setCurrentPage] = useState(1);
    const [open, setOpen] = useState(false)
    const [anchorEl, setAnchorEl] = useState(null);
    const containerRef = useRef(null);
    const [edit, setEdit] = useState(false)
    const Data = [
        {
            name: 'Cash',
            summary: 'Cash',
            image: LocalAtm
        },
        {
            name: 'Credit Card (In-person)',
            summary: 'Credit Card (In-person)',
            image: CreditCard
        },
        {
            name: 'Online Transfer',
            summary: 'Online Transfer',
            image: AccountBalance
        },
        {
            name: 'Text to Pay',
            summary: 'Text to Pay',
            image: PaymentsOutlined
        },
        {
            name: 'Void',
            summary: 'Void',
            image: Cancel
        },

    ]
    const dotData = [
        {
            name: 'Send Receipt',
            summary: 'Send Receipt',
            image: money
        },
        {
            name: 'Delete Invoice',
            summary: 'Delete Invoice',
            image: atmcard
        },
        {
            name: 'Send Invoice',
            summary: 'Send Invoice',
            image: moneyTransfer
        },
    ]
    const dispatch = useDispatch()
    const init = async () => {
        const searchParams = new URLSearchParams(location.search);
        const invoiceParam = searchParams.get('invoice');
        if (invoiceParam === 'true') {
            setModalOpen(true)
        }
        setFilter('All invoice')
        setLoader(true)

        let res = await dispatch(getInvoiceQuote())
        if (res.status === true) {
            setLoader(false)
            setSearchInvoice(res?.data?.invoice)
        }
    }

    useEffect(() => {
        init()
    }, [])
    const sendchat = async (val, data) => {
        console.log(data, "daa")
        await dispatch(sendMessage(
            {
                "to": data?.phoneNumber,
                "from": `+${localStorage.getItem('number')}`,
                "body": val
            }
        ))



    }
    const printDocument = async () => {
        const input = document.getElementById('divToPrint');
        await html2canvas(input, { scale: 2 }) // Adjust scale as needed
            .then(async (canvas) => {
                const imgData = canvas.toDataURL('image/jpeg', 1.0); // Use JPEG format
                const pdf = new jsPDF('p', 'mm', 'a4');
                const imgWidth = pdf.internal.pageSize.getWidth();
                const imgHeight = (canvas.height * imgWidth) / canvas.width;
                pdf.addImage(imgData, 'JPEG', 0, 0, imgWidth, imgHeight);
                pdf.save("download.pdf");
            })
            .catch((error) => {
                console.log('Error generating PDF: ' + error.message);
            });
    };
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (popupRef.current && !popupRef.current.contains(event.target)) {
                setStatusModalOpen(false);
                setDotModalOpen(false)

            }

        };

        document.addEventListener('click', handleClickOutside);

        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, [setStatusModalOpen]);

    const OnsubmitStauts = async (e, status) => {
        console.log(invoice?.invoiceNo, 'dfnjkfdkdjfkjfdjkfdjkn')
        e.stopPropagation()

        const res = await dispatch(changePaymentMethod({ "val": status?.name, "invoiceNo": invoice?.invoiceNo }))
        init()
        setStatusModalOpen(false)
    }
    const onClickDot = async (e, val, item) => {
        await dispatch(sendMailInvoice(invoice))

        if (val == 'Send Invoice') {
            sendchat(`Please see the attached invoice for $${parseFloat(invoice?.totalAmount)?.toFixed(2)}. https://app.getnotifi.com/invoice/${invoice?.invoiceNo}`, { "phoneNumber": item?.customerPhoneNumber })
        }
        else if (val == "Delete Invoice") {
            const res = await dispatch(changePaymentMethod({ "val": "Delete", "invoiceNo": invoice?.invoiceNo }))
            init()

        }
        else {
            sendchat(`Please see the attached receipt for $${parseFloat(invoice?.totalAmount)?.toFixed(2)}. https://app.getnotifi.com/receipt/${invoice?.invoiceNo}`, { "phoneNumber": item?.customerPhoneNumber })
        }
        setDotModalOpen(false)
    }

    const searchFilterFunction = (text) => {
        console.log(text, "data");
        if (text) {
            const newData = detail?.invoice?.filter((item) => {
                const nameData = item.name ? item.name.toUpperCase() : ''.toUpperCase();
                const numberData = item.numberOfInvoice ? item.numberOfInvoice.toUpperCase() : ''.toUpperCase();
                const searchText = text.toUpperCase();
                return nameData.includes(searchText) || numberData.includes(searchText);
            });

            setSearchInvoice(newData);
            setSearch(text);
        } else {
            setFilter('All invoice')
            setSearchInvoice(detail?.invoice);
            setSearch(text);
        }
    };
    const menuItemsc = [
        {
            label: 'Sort ascending',
            icon: <NorthOutlined style={{ fontSize: '18px' }} />,
            onClick: () => console.log('Edit clicked'),

        },
        {
            label: 'Sort descending',
            icon: <SouthOutlined style={{ fontSize: '18px' }} />,
            onClick: () => console.log('Duplicate clicked'),
        },


    ];
    const onClickRequiredHeader = async (lab, item) => {
        if (lab === 'Sort ascending') {
            const sorted = [...searchinvoice]
            const allcontc = [...detail?.invoice]
            sorted.sort((a, b) => a.name.localeCompare(b.name));

            setSearchInvoice(sorted)
        }
        else if (lab === 'Sort descending') {
            const sorted = [...searchinvoice]
            const allcontc = [...detail?.invoice]
            sorted.sort((a, b) => b.name.localeCompare(a.name));

            setSearchInvoice(sorted)

        }
        else {
            //   const removeItem = [...selectedItems]
            //   const index = removeItem.filter(items => items.text !== item?.text);
            //   const res = await dispatch(saveTableFilter({
            //     "filterData": index
            //   }))
            //   if (res.status === true) {
            //     init()
            //   }
            //   setSelectedItems(index)
            // 
        }

    }

    const handlePdf = () => {
        pdf(<NewInvoice
            data={datadet?.items}
            userData={datadet}
            type={'Invoice'}
        />).toBlob().then(async blob => {
            const pdfFile = new File([blob], "Quote", { type: 'application/pdf' });

            console.log(blob, "blob")
        })
    }
    const rawToHeaders = ({
        name,
        totalAmount,
        status,
        quoteNumber,
        createdAt,
        updatedAt,
    }) => {
        return {
            "Name": name, "Total amount": totalAmount, "Status": status, "Invoice number": quoteNumber, "Created date": createdAt, "Updated date": updatedAt
        }
    }
    const downloadExcel = (data) => {

        const worksheet = XLSX.utils.json_to_sheet(data?.map(rawToHeaders));

        const headerCell = worksheet['A1']; // Assuming header is in A1
        if (headerCell) {
            headerCell.s = {
                fill: {
                    fgColor: { rgb: "808080" } // Gray color
                },
                font: {
                    bold: true
                }
            };
        }

        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
        //let buffer = XLSX.write(workbook, { bookType: "xlsx", type: "buffer" });
        //XLSX.write(workbook, { bookType: "xlsx", type: "binary" });
        XLSX.writeFile(workbook, "Invoice.xlsx");
    };
    const handlePageChange = (event, value) => {
        setCurrentPage(value);
        if (containerRef.current) {
            containerRef.current.scrollTo({
                top: 0,
                behavior: 'smooth',
            });
        }
    };
    const currentData = useMemo(() => {
        const startIndex = (currentPage - 1) * 100;

        return searchinvoice.slice(startIndex, startIndex + 100);
    }, [currentPage, searchinvoice]);
    return (
        <>
            <Body>
                <Flexbox>

                    <CustomTable>
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginTop: '20px' }}>
                            <CustomTextField
                                placeholder="Search"
                                size="small"
                                value={search}
                                onChange={(e) => searchFilterFunction(e.target.value)}
                                InputProps={{
                                    style: {
                                        paddingLeft: '10px',
                                        borderRadius: '8px',
                                        fontSize: '14px',
                                        width: '450px',
                                        marginLeft: '15px',
                                        background: '#f3f3f3',
                                    },
                                    startAdornment: (
                                        <CustomInputAdornment position="start">
                                            <CustomIconButton>
                                                <Icons style={{ width: '15px', height: '15px' }} src={searchs} />
                                            </CustomIconButton>
                                        </CustomInputAdornment>
                                    ),

                                }}
                            />
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginLeft: '20px', marginRight: '20px', marginTop: '10px', }}>
                            <h1 style={{ textAlign: 'center', marginTop: '5px', marginBottom: '0px' }}>Invoices</h1>
                            <div style={{ display: 'flex', }}>
                                <div style={{ position: 'relative' }}>
                                    <OptionBox style={{ marginRight: '20px', background: '#fff', color: '#000', border: '1px solid #ddd' }} onClick={() => {
                                        setOpen(true)

                                        // const formattedData = searchinvoice.map(item => ({
                                        //     name: item.name,
                                        //     totalAmount: item.totalAmount,
                                        //     status: item.status,
                                        //     quoteNumber: item.numberOfQuote,
                                        //     createdAt: moment(item.createdAt).format('DD MMM YYYY'),
                                        //     updatedAt: moment(item.updatedAt).format('DD MMM YYYY'),
                                        // }));
                                        // downloadExcel(formattedData)

                                    }}>

                                        Export
                                        <KeyboardArrowDownOutlined style={{ marginLeft: '5px', marginRight: '-10px', marginTop: '2px' }} />

                                    </OptionBox>
                                    <ExportDropdown
                                        anchorEl={anchorEl}
                                        open={open}
                                        handleClose={() => setOpen(false)}
                                        statusData={[
                                            { label: 'All invoices', value: "All data" },
                                            { label: 'Paid', value: 'Paid' },
                                            { label: 'Unpaid', value: 'Unpaid' },
                                            { label: 'Draft', value: 'Draft' },
                                            { label: 'Void', value: 'Rejected' }
                                        ]}
                                        onExport={(startDate, endDate, status) => {

                                            const filteredDataByDate = detail?.invoice?.filter(item => {
                                                const itemDate = moment(item.createdAt).format('YYYY-MM-DD');
                                                return itemDate >= moment(startDate)?.format('YYYY-MM-DD') && itemDate <= moment(endDate)?.format('YYYY-MM-DD');
                                            });
                                            const filteredData = status === 'All data'
                                                ? filteredDataByDate
                                                : filteredDataByDate.filter(item => item.invoiceStatus === status);

                                            const formattedData = filteredData.map(item => ({
                                                name: item.name,
                                                totalAmount: `$${item.totalAmount}`,
                                                status: item.invoiceStatus,
                                                quoteNumber: item.numberOfInvoice,
                                                createdAt: moment(item.createdAt).format('DD MMM YYYY'),
                                                updatedAt: moment(item.updatedAt).format('DD MMM YYYY'),
                                            }));

                                            const totalAmount = filteredData.reduce((sum, item) => sum + parseFloat(item.totalAmount), 0);
                                            formattedData.push({
                                                name: 'Total',
                                                totalAmount: `$${totalAmount.toFixed(2)}`,
                                                status: '',
                                                quoteNumber: '',
                                                createdAt: '',
                                                updatedAt: '',

                                            });

                                            downloadExcel(formattedData)

                                        }}
                                    />
                                </div>

                                <OptionBox onClick={() => {
                                    setEdit(false)
                                    setModalOpen(true)
                                }}>

                                    Create invoice</OptionBox>
                            </div>
                        </div>
                        {loader && !detail?.invoice ? <div style={{ display: 'flex', height: '100%', alignItems: 'center', justifyContent: 'center' }}> <Player
                            src={require('../../../assets/loaders.json')}
                            className="player"
                            style={{ width: '200px', height: '200px' }}
                            loop
                            autoplay
                        />
                        </div>

                            :
                            <>
                                <div style={{ padding: 20, paddingRight: '0px', paddingBottom: '0px' }}>
                                    <div style={{ display: 'flex', width: '100%', marginBottom: '25px' }}>
                                        <ContainerBox
                                            onClick={() => {
                                                setFilter('All invoice')
                                                setSearchInvoice(detail?.invoice)
                                            }}
                                            style={{ border: filter === 'All invoice' ? '1.5px solid #0F52BA' : '1px solid #bbb', color: filter === 'All invoice' ? '#0F52BA' : palette.charcoleBlack }}>
                                            All Invoice
                                            <div>
                                                {detail?.invoice?.length}
                                            </div>
                                        </ContainerBox>
                                        <ContainerBox
                                            style={{ border: filter === 'Paid' ? '1.5px solid #0F52BA' : '1px solid #bbb', color: filter === 'Paid' ? '#0F52BA' : palette.charcoleBlack }}
                                            onClick={() => {
                                                setFilter('Paid')
                                                const data = detail?.invoice?.filter(item => item?.invoiceStatus === 'Paid')
                                                setSearchInvoice(data)

                                            }}
                                        >Paid
                                            <div>
                                                {detail?.invoice?.filter(item => item?.invoiceStatus === 'Paid')?.length}
                                            </div>
                                        </ContainerBox>
                                        {/* <ContainerBox>Overdue</ContainerBox> */}
                                        <ContainerBox
                                            style={{ border: filter === 'Unpaid' ? '1.5px solid #0F52BA' : '1px solid #bbb', color: filter === 'Unpaid' ? '#0F52BA' : palette.charcoleBlack }}
                                            onClick={() => {
                                                setFilter('Unpaid')
                                                const data = detail?.invoice?.filter(item => item?.invoiceStatus === 'Unpaid')
                                                setSearchInvoice(data)

                                            }}
                                        >Unpaid
                                            <div>
                                                {detail?.invoice?.filter(item => item?.invoiceStatus === 'Unpaid')?.length}
                                            </div>
                                        </ContainerBox>
                                        <ContainerBox
                                            style={{ border: filter === 'Draft' ? '1.5px solid #0F52BA' : '1px solid #bbb', color: filter === 'Draft' ? '#0F52BA' : palette.charcoleBlack }}
                                            onClick={() => {
                                                setFilter('Draft')
                                                const data = detail?.invoice?.filter(item => item?.invoiceStatus === 'Draft')
                                                setSearchInvoice(data)

                                            }}
                                        >Draft
                                            <div>
                                                {detail?.invoice?.filter(item => item?.invoiceStatus === 'Draft')?.length}
                                            </div>
                                        </ContainerBox>
                                        <ContainerBox
                                            style={{ border: filter === 'Void' ? '1.5px solid #0F52BA' : '1px solid #bbb', color: filter === 'Void' ? '#0F52BA' : palette.charcoleBlack }}
                                            onClick={() => {
                                                setFilter('Void')
                                                const data = detail?.invoice?.filter(item => item?.invoiceStatus === 'Rejected')
                                                setSearchInvoice(data)

                                            }}
                                        >Void
                                            <div>
                                                {detail?.invoice?.filter(item => item?.invoiceStatus === 'Rejected')?.length}
                                            </div>
                                        </ContainerBox>

                                    </div>
                                </div>
                                <div ref={containerRef} style={{ overflowY: 'auto', height: '82vh', }}>
                                    <Table style={{ gridTemplateColumns: 'repeat(3, 1fr)' }} sx={{ width: '100%' }} aria-label="simple table">
                                        <CustomHead>
                                            <TableRow >
                                                <TableCell size='small'>Customer name
                                                    <CustomizedMenu
                                                        menuItems={menuItemsc}
                                                        onClick={(lab) => onClickRequiredHeader(lab)}
                                                    />
                                                </TableCell>

                                                <TableCell style={{ position: 'relative', color: palette.black, fontWeight: '700' }} size='small'>

                                                    Amount

                                                </TableCell>
                                                <TableCell style={{ position: 'relative', color: palette.black, fontWeight: '700' }} size="small">
                                                    Status
                                                </TableCell>
                                                <TableCell style={{ position: 'relative', color: palette.black, fontWeight: '700' }} size='small'>

                                                    Invoice number

                                                </TableCell>


                                                <TableCell style={{ position: 'relative', color: palette.black, fontWeight: '700' }} size='small'>Due</TableCell>

                                                <TableCell style={{ position: 'relative', color: palette.black, fontWeight: '700' }} size='small'>Created</TableCell>
                                                <TableCell size='small'>

                                                </TableCell>
                                            </TableRow>
                                        </CustomHead>


                                        {currentData?.map((item, index) => <>

                                            <TableBody key={index} onClick={(event) => {
                                                setEdit(true)
                                                setIsedit(true)
                                                setInvoice({ ...item })
                                                setModalOpen(true)


                                            }} style={{ background: '#fff', cursor: 'pointer' }} >
                                                <TableRow style={{}}>
                                                    <TableCell size='small'>
                                                        {item?.name || formatMobileNumber(item?.customerPhoneNumber)}

                                                    </TableCell>
                                                    <TableCell size='small'>

                                                        ${putCommaInvalue(parseFloat(item?.totalAmount)?.toFixed(2))}

                                                    </TableCell>
                                                    <TableCell

                                                        onClick={(event) => {
                                                            event.stopPropagation()
                                                            setStatusIndex(index)
                                                            setStatusModalOpen(true)
                                                            setInvoice({ ...item })
                                                            const rect = event.target.getBoundingClientRect();
                                                            const containerRect = containerRef.current.getBoundingClientRect();
                                                            const scrollTop = containerRef.current.scrollTop;
                                                            const scrollLeft = containerRef.current.scrollLeft;

                                                            // Calculate the correct position of the popup
                                                            const posX = rect.left - containerRect.left + scrollLeft;
                                                            const posY = rect.bottom - containerRect.top + scrollTop;

                                                            setPopupPosition({ x: posX, y: posY });


                                                        }}
                                                        size='small'>
                                                        <div style={{

                                                            width: '90px',
                                                            fontWeight: '500',
                                                            borderRadius: 5,
                                                            display: 'flex',
                                                            textAlign: 'center',
                                                            justifyContent: 'start',
                                                            fontSize: '13px',

                                                        }}>
                                                            <span style={{ marginRight: '5px', color: item?.invoiceStatus === 'Rejected' ? '#c0113d' : item?.invoiceStatus === 'Paid' ? '#29740f' : item?.invoiceStatus === 'Draft' ? '#586171' : '#fa811f' }}>●</span> {item?.invoiceStatus === 'Paid' ? item?.paymentMethod?.replace(/\(In-person\)/g, '') || item?.invoiceStatus : item?.invoiceStatus === 'Rejected' ? "Void" : item?.invoiceStatus}
                                                        </div>

                                                    </TableCell>
                                                    <TableCell size='small' style={{ maxWidth: '100px' }} >
                                                        #{item?.numberOfInvoice}
                                                        {/* {item?.name || item?.customerPhoneNumber} */}


                                                    </TableCell>



                                                    <TableCell size='small'> {item?.notes?.length > 0 ? item?.notes : 'No due'}</TableCell>
                                                    <TableCell size='small'>
                                                        {moment(item?.date).format('DD-MMM, hh:mm a')}
                                                    </TableCell>
                                                    <TableCell
                                                        size='small' style={{ fontSize: '12px', textAlign: 'start' }}>
                                                        <div style={{ display: 'flex', marginTop: '-1px', marginBottom: '-1px' }}>
                                                            <PDFContainer onClick={(e) => {
                                                                e.stopPropagation()


                                                                setDatadet(item)

                                                                setIsDownloadOpen(true)
                                                                printDocument()

                                                            }}>
                                                                Download PDF
                                                            </PDFContainer>
                                                            <DotContainer onClick={(event) => {
                                                                event.stopPropagation()
                                                                setStatusIndex(index)
                                                                setDotModalOpen(true)
                                                                setInvoice({ ...item })
                                                                const rect = event.target.getBoundingClientRect();
                                                                const containerRect = containerRef.current.getBoundingClientRect();
                                                                const scrollTop = containerRef.current.scrollTop;
                                                                const scrollLeft = containerRef.current.scrollLeft;

                                                                // Calculate the correct position of the popup
                                                                const posX = rect.left - containerRect.left + scrollLeft - 250; // Adjusted for left positioning and width of the modal
                                                                const posY = rect.bottom - containerRect.top + scrollTop;

                                                                setPopupPositionDot({ x: posX, y: posY });


                                                            }}
                                                                style={{ fontSize: '4px', padding: '5px', }} >
                                                                ● ● ●
                                                            </DotContainer>
                                                        </div>
                                                    </TableCell>
                                                </TableRow>
                                                {statusModalOpen && index === 1 && <div style={{ position: 'relative' }}>

                                                    <StyledPopupContainer ref={popupRef} style={{
                                                        top: popupPosition.y - 115,
                                                        left: popupPosition.x,

                                                    }}>
                                                        <StyledPopupContent >
                                                            <StyledPopupItems>

                                                                {
                                                                    Data?.map((items) => {
                                                                        const OutlinedComponent = items?.image
                                                                        return (
                                                                            <PopupValues style={{ width: '91%', padding: '10px' }} onClick={e => {
                                                                                setDatadet(item)
                                                                                OnsubmitStauts(e, items)
                                                                            }}>
                                                                                <OutlinedComponent style={{ marginRight: '10px', fontSize: '18px' }} />
                                                                                {items?.name}</PopupValues>
                                                                        )

                                                                    }
                                                                    )
                                                                }
                                                            </StyledPopupItems>

                                                        </StyledPopupContent>
                                                    </StyledPopupContainer>


                                                </div>}
                                                {statusDotOpen && index === 0 && <div style={{ position: 'relative' }}>

                                                    <StyledPopupContainer ref={popupRef} style={{
                                                        top: popupPositionDot.y - 100,
                                                        left: popupPositionDot.x,

                                                    }}>
                                                        <StyledPopupContent >
                                                            <StyledPopupItems>

                                                                {
                                                                    dotData?.map((items) =>
                                                                        <PopupValues style={{ fontWeight: '500', padding: '15px', color: invoice?.invoiceStatus === 'Paid' && items?.name === 'Delete Invoice' ? 'gray' : invoice?.invoiceStatus !== 'Paid' && items?.name === 'Send Receipt' ? 'gray' : '#4169e1' }} onClick={e => {
                                                                            e.stopPropagation();
                                                                            if (invoice?.invoiceStatus === 'Paid' && items?.name === 'Delete Invoice') {

                                                                            }
                                                                            else if (invoice?.invoiceStatus !== 'Paid' && items?.name === 'Send Receipt') {

                                                                            }
                                                                            else {
                                                                                setDatadet(item)
                                                                                onClickDot(e, items?.name, invoice)
                                                                            }
                                                                        }}>

                                                                            {items?.name}</PopupValues>
                                                                    )
                                                                }
                                                            </StyledPopupItems>

                                                        </StyledPopupContent>
                                                    </StyledPopupContainer>


                                                </div>}

                                            </TableBody>

                                        </>

                                        )}

                                    </Table>
                                    {searchinvoice?.length > 90 && <Pagination
                                        count={Math.ceil(searchinvoice?.length / 100)}
                                        page={currentPage}
                                        onChange={handlePageChange}
                                        sx={{
                                            display: 'flex', justifyContent: 'center',
                                            marginTop: '50px', marginBottom: '100px',
                                            '& .MuiPaginationItem-root': {
                                                color: '#000',
                                            },
                                            '& .MuiPaginationItem-root.Mui-selected': {
                                                backgroundColor: '#0F52BA',
                                                color: '#fff',
                                            },
                                        }}
                                    />}
                                    {searchinvoice?.length > 0 ? null : <h3 style={{ color: 'gray', display: 'flex', justifyContent: 'center', marginTop: '30vh', marginBottom: '30vh' }}>No invoices</h3>}
                                </div>


                            </>
                        }

                    </CustomTable>
                    {modalOpen && (
                        <FullScreenModal onClose={() => setModalOpen(false)}>

                            <CreateInvoice
                                {...props}
                                value={edit ? { ...invoice, edit: isedit } : {}}
                                edit={edit ? false : true}
                                setModalOpenInvoice={() => {
                                    // const searchParams = new URLSearchParams(location.search);
                                    searchParams.delete('invoice');
                                    setSearchParams(searchParams)
                                    setModalOpen(false)
                                }}
                                onSubmitsw={(val, data) => {

                                    sendchat(val, data)
                                    init()

                                    setModalOpen(false)
                                    searchParams.delete('invoice');
                                    setSearchParams(searchParams)


                                }
                                }
                            />


                        </FullScreenModal>
                    )}


                </Flexbox>
                {isDownloadOpen &&
                    <Modal onClose={() => setIsDownloadOpen(false)}>
                        <Display id="divToPrint"  >
                            <div style={{ display: 'flex', justifyContent: 'flex-end', marginRight: '1rem' }}>
                                <CustomButton onClick={handlePdf} document={<NewInvoice
                                    data={datadet}
                                    userData={{
                                        "email": userData?.email,
                                        "logoImage": userData?.logoImage,
                                        "businessName": userData?.businessName,
                                        "address": userData?.address,
                                        "twilioPhone": formatMobileNumber(`+${localStorage.getItem('number')}`),
                                        "termCondition": [
                                            {
                                                "invoice": userData?.termCondition?.[0]?.invoice,
                                                "quotes": userData?.termCondition?.[0]?.quotes,

                                            }
                                        ]
                                    }}
                                    type={'Invoice'}
                                />} fileName='Invoice.pdf'>
                                    Download
                                </CustomButton>
                            </div>

                            <div
                                style={{

                                    width: '230mm',
                                    height: "297mm",
                                    background: 'white',

                                    pageBreakInside: 'avoid',
                                    boxSizing: 'border-box',
                                    overflow: 'auto', // Enable scrolling if content exceeds the page
                                }}
                            >
                                <PreviewInvoice
                                    userDetails={datadet}
                                    bundleData={datadet?.items?.filter((item) => item?.type === 'Bundle')}
                                    quoteData={datadet?.items?.filter((item) => item?.type !== 'Material' && item?.type !== 'Bundle')}
                                    materialData={datadet?.items?.filter((item) => item?.type === 'Material')}
                                />
                            </div>
                        </Display>
                    </Modal>
                }

            </Body>
        </>
    )


}


export default EstimatePayments;
const Body = styled.div`
display:flex;
width:calc(100% - 240px);
overflow-x:scroll;
overflow-y:hidden;
position: fixed;
top: 0;
left: 0;
right: 0;
bottom: 0;
margin-left:240px;
`

const StyledPopupContent = styled.div`
    background-color: #FAFAFA;
    box-shadow: 0 12px 48px rgba(32, 40, 77, 0.1), 0 32px 80px rgba(32, 40, 77, 0.1);
    opacity: 1.0;
    border-radius: 8px;
    width: 250px;
    background: white;
    position: absolute;
    top: 0px;
    left: 0px;
    z-index: 20000;
`

const StyledPopupItems = styled.div`
   
    display:flex;
flex-direction:column;
 
    justify-content:center;
    align-items:start;
`
const PopupValues = styled.div`

display:flex;
align-items:center;
border-bottom:1px solid #f3f3f3;
font-size:14px;
font-weight:500;
width:88%;
&:hover {
    background:#f3f3f3;
  }

`
const StyledPopupContainer = styled.div`
    z-index: 1500;
    position: absolute;
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-content: stretch;
    align-items: center;
`
const PDFContainer = styled.div`
border:1px solid #f3f3f3;
display:flex;
justify-content:center;
color:#000;
padding-top:4px;
padding-bottom:4px;
border-top-left-radius: 5px;
border-bottom-left-radius: 5px; 
background:#fff;
padding:5px;
&:hover {
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.2); 
  }
`
const DotContainer = styled.div`
border:1px solid #f3f3f3;
display:flex;
justify-content:center;
align-items:center;
color:#000;
padding-top:5px;
padding-bottom:5px;
border-top-right-radius: 5px;
border-bottom-right-radius: 5px; 
padding:5px;
background:#fff;
&:hover {
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.2); 
  }
`

const Display = styled.div`
background:#fff;
`

const TableCellHead = styled(TableCell)`
font-weight:600;
`


const CustomTable = styled(TableContainer)`
  &::-webkit-scrollbar {
    display: none; /* Hide the scrollbar */
  }
  border-color: red;

  .MuiTableBody-root {
   
    font-family: Inter;
    font-size: 13px;
  }

  .MuiTableHead-root {
    font-weight: 700;
    font-family: Inter;
    font-size: 14px;
    color:${palette.black};
  }

  .MuiTableRow-root {
    height: 35px;
   
    font-family: Inter;
    font-size: 13px;
    &:hover {
      background: #f8f8f8;
    }
  }

  .MuiTableCell-root {
    border-color: #f3f3f3;
    border-right:1px solid #f3f3f3;
    height:10px;
    font-family: Inter;
    white-space: nowrap;
    word-break: break-word;
    border-collapse: separate;
    line-height: 16px;
    color:${palette.black};
    font-size: 13px;
    font-weight:450;
  }
  .sticky {
    position: sticky;
    left: 0;
    z-index: 2;
    background: white;
  
  }
  .MuiTableCell-root:first-child {
    position: sticky;
    left: 0;
    background: #fff;
    border-left:1px solid #f3f3f3;
    font-weight:700;
    color:${palette.newBlue};
    z-index: 1;
    min-width:150px;
 
  }
  .MuiTableRow-root:hover .MuiTableCell-root:first-child {
    background:#f8f8f8;
  }

  .purple {
    background: #974EDE;
  }

  .green {
    background: #32cd32;
  }

  .dimgreen {
    background: #008000;
  }
`;

const CustomHead = styled(TableHead)`
position: sticky;
  top: 0;
  background-color: white;
  z-index: 2;
border-radius:8px;
font-size:14px;
color:${palette.black};
border-top:1px solid #f3f3f3;
color:#000;
height:10px;
flex-wrap:wrap;
background:#f9f9f9;
.MuiTableCell-root:first-child {
  position: sticky;
  background:#f9f9f9;
  color:${palette.black};
  z-index: 1;
  min-width:150px;

}
`
const Flexbox = styled.div`
width:100%;
background:#fff;
display:flex;
@media (min-width: 260px) and (max-width: 1300px){

    flex-shrink: 0;
overflow: auto; 
}
`
const SubTable = styled.div`

display:flex;
width:100%; 
justify-content:flex-end; 
align-self:flex-end;
padding-top:25px; 

.key{
    width:30%;
     text-align:start; 
     font-weight:bold;
}
`
const Icon = styled.img`
height:20px;
width:20px;
cursor:pointer;
color:black
`
const Icons = styled.img`
width: 20px;
height: 20px;
`
const PaymnetIcon = styled.img`

height: 50px;
`
const CloseButton = styled.button`
background:transparent;
display:flex;
align-items:center;
justify-content:center;
border: none;
background:#ddd;
width:30px;
height:30px;
align-self:center;
border-radius:20px;
font-size: 16px;
cursor: pointer;

`
const ContainerBox = styled.div`
width:100%;
height:35px;
border-radius:8px;
display:flex;
justify-content:space-between;
font-size:13px;
padding-left:15px;
padding-right:15px;
margin-right:20px;
align-items:center;
border:1px solid #ddd;
font-weight:550;
cursor:pointer;
&:hover {
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.1); /* Box shadow on hover */
  }
`
const CustomButton = styled(PDFDownloadLink)`
width:130px;
height:44px;
margin-top:15px;
border-radius:8px;
border:1px solid #4169E1;
align-self:flex-end;
color:white;
font-size:16px;
background:#4169E1;
text-decoration: none; 
display:flex;
align-items:center;
justify-content:center;
cursor:pointer;
`
const CustomTextField = styled(TextField)`
border-radius: 8px;
&& {
   
    height: 30px; /* Adjust the height as needed */
    padding: 5px; /* Adjust the padding as needed */
}
`;

const CustomInputAdornment = styled(InputAdornment)`
  
`;

const CustomIconButton = styled(IconButton)`
 
`;
const OptionBox = styled.div`
padding-left:20px;
padding-right:20px;
height:35px;
border-radius:8px;
font-weight:600;
background:#0F52BA;
color:#fff;
font-size:14px;


display:flex;
align-items:center;
justify-content:center;

cursor:pointer;
&:hover {
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.2); 
}

`

const OptionIcon = styled.img`
width: 12px;
height: 12px;
`