import React, { useEffect, useRef } from "react";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import * as XLSX from 'xlsx';
import { useState } from "react";
import { formatMobileNumber, putCommaInvalue, taxprice, totalprice } from "../../../utils/Commonfunction";
import { changePaymentMethod, changeQuotePaymentMethod, fetchAllContacts, getInvoiceQuote, sendInvoice, sendMailInvoice, sendMailQuote } from "../../../store/Actions/dashboard.action";
import { Player } from '@lottiefiles/react-lottie-player';
import { IconButton, InputAdornment, MenuItem, Select, TextField } from "@mui/material";
import moment from 'moment'
import Modal from "../../Modal";
import { sendMessage } from "../../../store/Actions/Auth.action";
import FullScreenModal from "../../FullScreenModal";
import CreateQuote from "../CreateQuote";
import moneyTransfer from '../../../assets/mobile-banking.png';
import searchs from '../../../assets/searchs.png'
import reject from '../../../assets/reject.png';
import PreviewInvoice from "../../../Pages/PreviewInvoice";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { plusIcon } from "../../../assets";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { palette } from "../../../utils/Palette";
import { KeyboardArrowDownOutlined, NorthOutlined, SouthOutlined } from "@mui/icons-material";
import CustomizedMenu from "../../../ReuseableComponets/MenuComponent";
import { pdf, PDFDownloadLink } from "@react-pdf/renderer";
import NewInvoice from "../../../Pages/NewInvoice";
import ExportDropdown from "../../../ReuseableComponets/ExportComponent";

function Estimate(props) {
    const location = useLocation();
    const popupRef = useRef(null);
    const detail = useSelector(state => state?.auth?.invoiceQuote)
    const userData = useSelector(state => state?.auth?.userData)
    const [statusDotOpen, setDotModalOpen] = useState(false)
    const navigate = useNavigate()
    const [loader, setLoader] = useState(false)
    const [modalOpen, setModalOpen] = useState(false)
    const [isDownloadOpen, setIsDownloadOpen] = useState(false)
    const [searchParams, setSearchParams] = useSearchParams();
    const [invoice, setInvoice] = useState({})
    const [datadet, setDatadet] = useState({})
    const [search, setSearch] = useState('');
    const [searchinvoice, setSearchInvoice] = useState(detail?.quote || [])
    const [popupPosition, setPopupPosition] = useState({ x: 0, y: 0 });
    const [popupPositionDot, setPopupPositionDot] = useState({ x: 0, y: 0 });
    const [filter, setFilter] = useState("All quotes")
    const [statusModalOpen, setStatusModalOpen] = useState(false)
    const [statusIndex, setStatusIndex] = useState(0)
    const containerRef = useRef(null);
    const [open, setOpen] = useState(false)
    const [anchorEl, setAnchorEl] = useState(null);
    const [edit, setEdit] = useState(false)
    const Data = [
        {
            name: 'Accepted',
            summary: 'Credit Card (In-person)',
            image: moneyTransfer
        },
        {
            name: 'Rejected',
            summary: 'Rejected',
            image: reject
        },


    ]

    const dispatch = useDispatch()
    const init = async () => {
        const searchParams = new URLSearchParams(location.search);
        const invoiceParam = searchParams.get('quote');
        if (invoiceParam === 'true') {
            setModalOpen(true)
        }
        setFilter('All quotes')
        setLoader(true)
        const res1 = await dispatch(fetchAllContacts())
        let res = await dispatch(getInvoiceQuote())
        if (res.status === true) {
            setLoader(false)
            setSearchInvoice(res?.data?.quotes)
        }
    }

    useEffect(() => {
        init()
    }, [])
    const sendchat = async (val, data) => {
        console.log(data, "data")

        await dispatch(sendMessage(
            {
                "to": data?.phoneNumber,
                "from": `+${localStorage.getItem('number')}`,
                "body": val
            }
        ))



    }
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (popupRef.current && !popupRef.current.contains(event.target)) {
                setStatusModalOpen(false);
            }
        };

        document.addEventListener('click', handleClickOutside);

        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, [setStatusModalOpen]);

    const OnsubmitStauts = async (e, status) => {
        console.log(invoice?.invoiceNo, 'dfnjkfdkdjfkjfdjkfdjkn')
        e.stopPropagation()

        const res = await dispatch(changeQuotePaymentMethod({ "val": status?.name, "invoiceNo": invoice?.referenceNo }))
        init()
        setStatusModalOpen(false)
    }
    const printDocument = async () => {
        const input = document.getElementById('divToPrint');
        await html2canvas(input, { scale: 2 }) // Adjust scale as needed
            .then(async (canvas) => {
                const imgData = canvas.toDataURL('image/jpeg', 1.0); // Use JPEG format
                const pdf = new jsPDF('p', 'mm', 'a4');
                const imgWidth = pdf.internal.pageSize.getWidth();
                const imgHeight = (canvas.height * imgWidth) / canvas.width;
                pdf.addImage(imgData, 'JPEG', 0, 0, imgWidth, imgHeight);
                pdf.save("download.pdf");
            })
            .catch((error) => {
                console.log('Error generating PDF: ' + error.message);
            });
    };
    const searchFilterFunction = (text) => {
        console.log(text, "data");
        if (text) {
            const newData = detail?.quote?.filter((item) => {
                const nameData = item.name ? item.name.toUpperCase() : ''.toUpperCase();
                const numberData = item.numberOfQuote ? item.numberOfQuote.toUpperCase() : ''.toUpperCase();
                const searchText = text.toUpperCase();
                return nameData.includes(searchText) || numberData.includes(searchText);
            });

            setSearchInvoice(newData);
            setSearch(text);
        } else {
            setFilter('All quotes')
            setSearchInvoice(detail?.quote);
            setSearch(text);
        }
    };
    const menuItemsc = [
        {
            label: 'Sort ascending',
            icon: <NorthOutlined style={{ fontSize: '18px' }} />,
            onClick: () => console.log('Edit clicked'),

        },
        {
            label: 'Sort descending',
            icon: <SouthOutlined style={{ fontSize: '18px' }} />,
            onClick: () => console.log('Duplicate clicked'),
        },


    ];
    const onClickRequiredHeader = async (lab, item) => {
        if (lab === 'Sort ascending') {
            const sorted = [...searchinvoice]
            const allcontc = [...detail?.quote]
            sorted.sort((a, b) => a.name.localeCompare(b.name));

            setSearchInvoice(sorted)
        }
        else if (lab === 'Sort descending') {
            const sorted = [...searchinvoice]
            const allcontc = [...detail?.quote]
            sorted.sort((a, b) => b.name.localeCompare(a.name));

            setSearchInvoice(sorted)

        }
        else {
            //   const removeItem = [...selectedItems]
            //   const index = removeItem.filter(items => items.text !== item?.text);
            //   const res = await dispatch(saveTableFilter({
            //     "filterData": index
            //   }))
            //   if (res.status === true) {
            //     init()
            //   }
            //   setSelectedItems(index)
            // 
        }

    }
    const handlePdf = () => {
        pdf(<NewInvoice
            data={datadet?.items}
            userData={datadet}
            type={'Quote'}
        />).toBlob().then(async blob => {
            const pdfFile = new File([blob], "Quote", { type: 'application/pdf' });

            console.log(blob, "blob")
        })
    }
    const dotData = [
        {
            name: 'Send Quote',
            summary: 'Send Quote',

        },
        {
            name: 'Delete Quote',
            summary: 'Delete Quote',

        },

    ]
    const onClickDot = async (e, val, item) => {
        await dispatch(sendMailQuote(invoice))

        if (val == 'Send Quote') {
            sendchat(`Please see the attached quote for $${parseFloat(invoice?.totalAmount)?.toFixed(2)}. https://app.getnotifi.com/invoice/${invoice?.referenceNo}`, { "phoneNumber": item?.customerPhoneNumber })
        }
        else if (val == "Delete Quote") {
            const res = await dispatch(changeQuotePaymentMethod({ "val": "Delete", "invoiceNo": invoice?.referenceNo }))
            init()

        }
        setDotModalOpen(false)
    }
    const rawToHeaders = ({
        name,
        totalAmount,
        status,
        quoteNumber,
        createdAt,
        updatedAt,
    }) => {
        return {
            "Name": name, "Total amount": totalAmount, "Status": status, "Quote number": quoteNumber, "Created date": createdAt, "Updated date": updatedAt
        }
    }
    const downloadExcel = (data) => {

        const worksheet = XLSX.utils.json_to_sheet(data?.map(rawToHeaders));
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
        //let buffer = XLSX.write(workbook, { bookType: "xlsx", type: "buffer" });
        //XLSX.write(workbook, { bookType: "xlsx", type: "binary" });
        XLSX.writeFile(workbook, "Quote.xlsx");
    };

    return (
        <>
            <Body>
                <Flexbox>

                    <CustomTable>
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginTop: '20px' }}>
                            <CustomTextField
                                placeholder="Search"
                                size="small"
                                value={search}
                                onChange={(e) => searchFilterFunction(e.target.value)}
                                InputProps={{
                                    style: {
                                        paddingLeft: '10px',
                                        borderRadius: '8px',
                                        fontSize: '14px',
                                        width: '450px',
                                        marginLeft: '15px',
                                        background: '#f3f3f3',
                                    },
                                    startAdornment: (
                                        <CustomInputAdornment position="start">
                                            <CustomIconButton>
                                                <Icons style={{ width: '15px', height: '15px' }} src={searchs} />
                                            </CustomIconButton>
                                        </CustomInputAdornment>
                                    ),

                                }}
                            />
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginLeft: '20px', marginRight: '20px', marginTop: '10px', }}>
                            <h1 style={{ textAlign: 'center', marginTop: '5px', marginBottom: '0px' }}>Quotes</h1>

                            <div style={{ display: 'flex', }}>
                                <div style={{ position: 'relative' }}>
                                    <OptionBox style={{ marginRight: '20px', background: '#fff', color: '#000', border: '1px solid #ddd' }} onClick={() => {
                                        setOpen(true)

                                        // const formattedData = searchinvoice.map(item => ({
                                        //     name: item.name,
                                        //     totalAmount: item.totalAmount,
                                        //     status: item.status,
                                        //     quoteNumber: item.numberOfQuote,
                                        //     createdAt: moment(item.createdAt).format('DD MMM YYYY'),
                                        //     updatedAt: moment(item.updatedAt).format('DD MMM YYYY'),
                                        // }));
                                        // downloadExcel(formattedData)

                                    }}>

                                        Export
                                        <KeyboardArrowDownOutlined style={{ marginLeft: '5px', marginRight: '-10px', marginTop: '2px' }} />

                                    </OptionBox>
                                    <ExportDropdown
                                        anchorEl={anchorEl}
                                        open={open}
                                        handleClose={() => setOpen(false)}
                                        statusData={[
                                            { label: 'All quotes', value: "All data" },
                                            { label: 'Waiting', value: 'Waiting for confirmation' },
                                            { label: 'Accepted', value: 'Accepted' },
                                            { label: 'Draft', value: 'Draft' },
                                            { label: 'Rejected', value: 'Rejected' }
                                        ]}
                                        onExport={(startDate, endDate, status) => {

                                            const filteredDataByDate = detail?.quote?.filter(item => {
                                                const itemDate = moment(item.createdAt).format('YYYY-MM-DD');
                                                return itemDate >= moment(startDate)?.format('YYYY-MM-DD') && itemDate <= moment(endDate)?.format('YYYY-MM-DD');
                                            });
                                            const filteredData = status === 'All data'
                                                ? filteredDataByDate
                                                : filteredDataByDate.filter(item => item.status === status);

                                            const formattedData = filteredData.map(item => ({
                                                name: item.name,
                                                totalAmount: `$${item.totalAmount}`,
                                                status: item.status,
                                                quoteNumber: item.numberOfQuote,
                                                createdAt: moment(item.createdAt).format('DD MMM YYYY'),
                                                updatedAt: moment(item.updatedAt).format('DD MMM YYYY'),
                                            }));

                                            const totalAmount = filteredData.reduce((sum, item) => sum + parseFloat(item.totalAmount), 0);
                                            formattedData.push({
                                                name: 'Total',
                                                totalAmount: `$${totalAmount.toFixed(2)}`,
                                                status: '',
                                                quoteNumber: '',
                                                createdAt: '',
                                                updatedAt: '',

                                            });

                                            downloadExcel(formattedData)

                                        }}
                                    />
                                </div>
                                <OptionBox onClick={() => {
                                    setEdit(false)
                                    setModalOpen(true)
                                }}>

                                    Create quote</OptionBox>
                            </div>
                        </div>

                        {loader && !detail?.invoice ? <div style={{ display: 'flex', height: '100%', alignItems: 'center', justifyContent: 'center' }}> <Player
                            src={require('../../../assets/loaders.json')}
                            className="player"
                            style={{ width: '200px', height: '200px' }}
                            loop
                            autoplay
                        />
                        </div>

                            :
                            <>
                                <div style={{ padding: 20, paddingRight: '0px', paddingBottom: '0px' }}>
                                    <div style={{ display: 'flex', width: '100%', marginBottom: '25px' }}>
                                        <ContainerBox
                                            onClick={() => {
                                                setFilter('All quotes')
                                                setSearchInvoice(detail?.quote)
                                            }}
                                            style={{ border: filter === 'All quotes' ? '1.5px solid #0F52BA' : '1px solid #bbb', color: filter === 'All quotes' ? '#0F52BA' : palette.charcoleBlack }}>
                                            All quotes
                                            <div>
                                                {detail?.quote?.length}
                                            </div>
                                        </ContainerBox>
                                        <ContainerBox
                                            style={{ border: filter === 'Waiting' ? '1.5px solid #0F52BA' : '1px solid #bbb', color: filter === 'Waiting' ? '#0F52BA' : palette.charcoleBlack }}
                                            onClick={() => {
                                                setFilter('Waiting')
                                                const data = detail?.quote?.filter(item => item?.status === 'Waiting for confirmation')
                                                setSearchInvoice(data)

                                            }}
                                        >Waiting
                                            <div>
                                                {detail?.quote?.filter(item => item?.status === 'Waiting for confirmation')?.length}
                                            </div>
                                        </ContainerBox>
                                        {/* <ContainerBox>Overdue</ContainerBox> */}
                                        <ContainerBox
                                            style={{ border: filter === 'Accepted' ? '1.5px solid #0F52BA' : '1px solid #bbb', color: filter === 'Accepted' ? '#0F52BA' : palette.charcoleBlack }}
                                            onClick={() => {
                                                setFilter('Accepted')
                                                const data = detail?.quote?.filter(item => item?.status === 'Accepted')
                                                setSearchInvoice(data)

                                            }}
                                        >Accepted
                                            <div>
                                                {detail?.quote?.filter(item => item?.status === 'Accepted')?.length}
                                            </div>
                                        </ContainerBox>
                                        <ContainerBox
                                            style={{ border: filter === 'Draft' ? '1.5px solid #0F52BA' : '1px solid #bbb', color: filter === 'Draft' ? '#0F52BA' : palette.charcoleBlack }}
                                            onClick={() => {
                                                setFilter('Draft')
                                                const data = detail?.quote?.filter(item => item?.status === 'Draft')
                                                setSearchInvoice(data)

                                            }}
                                        >Draft
                                            <div>
                                                {detail?.quote?.filter(item => item?.status === 'Draft')?.length}
                                            </div>
                                        </ContainerBox>
                                        <ContainerBox
                                            style={{ border: filter === 'Rejected' ? '1.5px solid #0F52BA' : '1px solid #bbb', color: filter === 'Rejected' ? '#0F52BA' : palette.charcoleBlack }}
                                            onClick={() => {
                                                setFilter('Rejected')
                                                const data = detail?.quote?.filter(item => item?.status === 'Rejected')
                                                setSearchInvoice(data)

                                            }}
                                        >Rejected
                                            <div>
                                                {detail?.quote?.filter(item => item?.status === 'Rejected')?.length}
                                            </div>
                                        </ContainerBox>

                                    </div>
                                </div>
                                <div ref={containerRef} style={{ overflowY: 'auto', height: '99vh', paddingBottom: '100px' }}>
                                    <Table sx={{ width: '100%' }} aria-label="simple table">
                                        <CustomHead>
                                            <TableRow >

                                                <TableCell size='small'>

                                                    Customer name
                                                    <CustomizedMenu
                                                        menuItems={menuItemsc}
                                                        onClick={(lab) => onClickRequiredHeader(lab)}
                                                    />

                                                </TableCell>
                                                <TableCell style={{ position: 'relative', color: palette.black, fontWeight: '700' }} size='small'>Amount</TableCell>
                                                <TableCell style={{ position: 'relative', color: palette.black, fontWeight: '700' }} size="small">
                                                    Status
                                                </TableCell>
                                                <TableCell style={{ position: 'relative', color: palette.black, fontWeight: '700' }} size='small'>

                                                    Quote number

                                                </TableCell>




                                                <TableCell style={{ position: 'relative', color: palette.black, fontWeight: '700' }} size='small'>Created</TableCell>
                                                <TableCell style={{ position: 'relative', color: palette.black, fontWeight: '700' }} size='small'>

                                                </TableCell>
                                            </TableRow>
                                        </CustomHead>
                                        {searchinvoice?.map((item, index) => <>
                                            <TableBody key={index} onClick={() => {
                                                setEdit(true)
                                                setInvoice({ ...item })
                                                setModalOpen(true)
                                            }} style={{ background: '#fff', cursor: 'pointer' }} >
                                                <TableRow style={{}}>
                                                    <TableCell size='small'>
                                                        {item?.name || formatMobileNumber(item?.customerPhoneNumber)}

                                                    </TableCell>
                                                    <TableCell size='small'>

                                                        ${putCommaInvalue(parseFloat(item?.totalAmount)?.toFixed(2))}

                                                    </TableCell>
                                                    <TableCell

                                                        onClick={(event) => {
                                                            event.stopPropagation()
                                                            setStatusIndex(index)
                                                            setStatusModalOpen(true)
                                                            setInvoice({ ...item })
                                                            const rect = event.target.getBoundingClientRect();
                                                            const containerRect = containerRef.current.getBoundingClientRect();
                                                            const scrollTop = containerRef.current.scrollTop;
                                                            const scrollLeft = containerRef.current.scrollLeft;

                                                            // Calculate the correct position of the popup
                                                            const posX = rect.left - containerRect.left + scrollLeft;
                                                            const posY = rect.bottom - containerRect.top + scrollTop;

                                                            setPopupPosition({ x: posX, y: posY });


                                                        }}
                                                        size='small'>
                                                        <div style={{

                                                            width: '90px',
                                                            fontWeight: '500',
                                                            borderRadius: 5,
                                                            display: 'flex',
                                                            textAlign: 'center',
                                                            justifyContent: 'start',
                                                            fontSize: '13px',
                                                        }}>
                                                            <span style={{ marginRight: '5px', color: item?.status === 'Waiting for confirmation' ? '#fa811f' : item?.status === 'Draft' ? '#586171' : item?.status === "Rejected" ? '#c0113d' : '#29740f' }}>●</span> {item?.status === 'Waiting for confirmation' ? "Waiting" : item?.status}
                                                        </div>

                                                    </TableCell>
                                                    <TableCell size='small' style={{ maxWidth: '100px' }} >
                                                        #{item?.numberOfQuote}
                                                        {/* {item?.name || item?.customerPhoneNumber} */}


                                                    </TableCell>




                                                    <TableCell size='small'>
                                                        {moment(item?.date).format('DD MMM, hh:mm a')}
                                                    </TableCell>
                                                    <TableCell

                                                        size='small' style={{ fontSize: '12px', textAlign: 'start' }}>
                                                        <div style={{ display: 'flex' }}>
                                                            <PDFContainer onClick={(e) => {
                                                                e.stopPropagation()
                                                                if (item?.pdf) {
                                                                    window?.open(item?.pdf)
                                                                }
                                                                else {

                                                                    setDatadet(item)

                                                                    setIsDownloadOpen(true)
                                                                    printDocument()
                                                                }
                                                            }}>
                                                                Download PDF
                                                            </PDFContainer>
                                                            <DotContainer onClick={(event) => {
                                                                event.stopPropagation()
                                                                setStatusIndex(index)
                                                                setDotModalOpen(true)
                                                                setInvoice({ ...item })
                                                                const rect = event.target.getBoundingClientRect();
                                                                const containerRect = containerRef.current.getBoundingClientRect();
                                                                const scrollTop = containerRef.current.scrollTop;
                                                                const scrollLeft = containerRef.current.scrollLeft;

                                                                // Calculate the correct position of the popup
                                                                const posX = rect.left - containerRect.left + scrollLeft - 250; // Adjusted for left positioning and width of the modal
                                                                const posY = rect.bottom - containerRect.top + scrollTop;

                                                                setPopupPositionDot({ x: posX, y: posY });


                                                            }}
                                                                style={{ fontSize: '4px', padding: '5px', }} >
                                                                ● ● ●
                                                            </DotContainer>
                                                            {/* <DotContainer style={{ fontSize: '8px', padding: '5px', }} onClick={(e) => {
                                                            e.stopPropagation()
                                                            if (item?.pdf) {
                                                                window?.open(item?.pdf)
                                                            }
                                                            else {

                                                                setDatadet(item)

                                                                setIsDownloadOpen(true)
                                                                printDocument()
                                                            }
                                                        }}>
                                                            ● ● ●
                                                        </DotContainer> */}
                                                        </div>
                                                    </TableCell>
                                                </TableRow>
                                                {statusModalOpen && index === 1 && <div style={{ position: 'relative' }}>

                                                    <StyledPopupContainer ref={popupRef} style={{
                                                        top: popupPosition.y - 120,
                                                        left: popupPosition.x,

                                                    }}>
                                                        <StyledPopupContent >
                                                            <StyledPopupItems>

                                                                {
                                                                    Data?.map((items) =>
                                                                        <PopupValues onClick={e => {
                                                                            setDatadet(item)
                                                                            OnsubmitStauts(e, items)
                                                                        }}>
                                                                            <Icons style={{ marginRight: '10px' }} src={items?.image} />
                                                                            {items?.name}</PopupValues>
                                                                    )
                                                                }
                                                            </StyledPopupItems>

                                                        </StyledPopupContent>
                                                    </StyledPopupContainer>


                                                </div>}
                                                {statusDotOpen && index === 0 && <div style={{ position: 'relative' }}>

                                                    <StyledPopupContainer ref={popupRef} style={{
                                                        top: popupPositionDot.y - 80,
                                                        left: popupPositionDot.x,

                                                    }}>
                                                        <StyledPopupContent >
                                                            <StyledPopupItems>

                                                                {
                                                                    dotData?.map((items) =>
                                                                        <PopupValues style={{ fontWeight: '500', padding: '15px', color: invoice?.invoiceStatus === 'Paid' && items?.name === 'Delete Invoice' ? 'gray' : invoice?.invoiceStatus !== 'Paid' && items?.name === 'Send Receipt' ? 'gray' : '#4169e1' }} onClick={e => {
                                                                            e.stopPropagation();
                                                                            if (invoice?.invoiceStatus === 'Paid' && items?.name === 'Delete Invoice') {

                                                                            }
                                                                            else if (invoice?.invoiceStatus !== 'Paid' && items?.name === 'Send Receipt') {

                                                                            }
                                                                            else {
                                                                                setDatadet(item)
                                                                                onClickDot(e, items?.name, invoice)
                                                                            }
                                                                        }}>

                                                                            {items?.name}</PopupValues>
                                                                    )
                                                                }
                                                            </StyledPopupItems>

                                                        </StyledPopupContent>
                                                    </StyledPopupContainer>


                                                </div>}

                                            </TableBody>

                                        </>)}
                                    </Table>
                                    {searchinvoice?.length > 0 ? null : <h3 style={{ color: 'gray', display: 'flex', justifyContent: 'center', marginTop: '30vh', marginBottom: '30vh' }}>No quotes</h3>}
                                </div>

                            </>
                        }








                    </CustomTable>
                    {modalOpen && (
                        <FullScreenModal onClose={() => setModalOpen(false)}>

                            <CreateQuote
                                {...props}
                                value={edit ? { ...invoice, edit: true } : {}}
                                edit={edit ? false : true}
                                setModalOpenInvoice={() => {
                                    setModalOpen(false)
                                    searchParams.delete('quote');
                                    setSearchParams(searchParams)
                                }}
                                onSubmitsw={(val, data, booking) => {

                                    sendchat(val, data)
                                    init()
                                    setModalOpen(false)
                                    searchParams.delete('quote');
                                    setSearchParams(searchParams)
                                    if (booking) {
                                        navigate(`/dashboard/servicerequests?id=${booking}`)
                                    }


                                }
                                }
                            />


                        </FullScreenModal>
                    )}


                </Flexbox>
                {isDownloadOpen &&
                    <Modal onClose={() => setIsDownloadOpen(false)}>
                        <div id="divToPrint"  >
                            <div style={{ display: 'flex', justifyContent: 'flex-end', marginRight: '1rem' }}>
                                <CustomButton onClick={handlePdf} document={<NewInvoice
                                    data={datadet}
                                    userData={{
                                        "email": userData?.email,
                                        "logoImage": userData?.logoImage,
                                        "businessName": userData?.businessName,
                                        "address": userData?.address,
                                        "twilioPhone": formatMobileNumber(`+${localStorage.getItem('number')}`),
                                        "termCondition": [
                                            {
                                                "invoice": userData?.termCondition?.[0]?.invoice,
                                                "quotes": userData?.termCondition?.[0]?.quotes,

                                            }
                                        ]
                                    }}
                                    type={'Quote'}
                                />} fileName='Quote.pdf'>
                                    Download
                                </CustomButton>
                            </div>

                            <div
                                style={{

                                    width: '230mm',
                                    height: "auto",
                                    background: 'white',

                                    pageBreakInside: 'avoid',
                                    boxSizing: 'border-box',
                                    overflow: 'auto', // Enable scrolling if content exceeds the page
                                }}
                            >
                                <PreviewInvoice
                                    type={"Quote"}
                                    userDetails={datadet}
                                    bundleData={datadet?.items?.filter((item) => item?.type === 'Bundle')}
                                    quoteData={datadet?.items?.filter((item) => item?.type !== 'Material' && item?.type !== 'Bundle')}
                                    materialData={datadet?.items?.filter((item) => item?.type === 'Material')}

                                />
                            </div>
                        </div>
                    </Modal>
                }
            </Body>
        </>
    )
}

export default Estimate;


const StyledPopupContent = styled.div`
    background-color: #FAFAFA;
    box-shadow: 0 12px 48px rgba(32, 40, 77, 0.1), 0 32px 80px rgba(32, 40, 77, 0.1);
    opacity: 1.0;
    border-radius: 8px;
    width: 250px;
    background: white;
    position: absolute;
    top: 0px;
    left: 0px;
    z-index: 20000;
`

const StyledPopupItems = styled.div`
   
    display:flex;
flex-direction:column;
 
    justify-content:center;
    align-items:start;
`
const PDFContainer = styled.div`
border:1px solid #f3f3f3;
display:flex;
justify-content:center;
color:#000;
padding-top:0px;
padding-bottom:0px;
border-top-left-radius: 5px;
border-bottom-left-radius: 5px; 
border-radius:5px;
background:#fff;
padding:3px;
&:hover {
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.2); 
  }
`
const DotContainer = styled.div`
border:1px solid #f3f3f3;
display:flex;
justify-content:center;
align-items:center;
color:#000;
padding-top:5px;
padding-bottom:5px;
border-top-right-radius: 5px;
border-bottom-right-radius: 5px; 
padding:5px;
background:#fff;
&:hover {
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.2); 
  }
`
const PopupValues = styled.div`
padding:15px;
display:flex;
align-items:center;
border-bottom:1px solid #f3f3f3;
font-size:14px;
width:88%;
&:hover {
    background:#f3f3f3;
  }

`
const StyledPopupContainer = styled.div`
    z-index: 1500;
    position: absolute;
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-content: stretch;
    align-items: center;
`


const CustomTable = styled(TableContainer)`
  &::-webkit-scrollbar {
    display: none; /* Hide the scrollbar */
  }
  border-color: red;

  .MuiTableBody-root {
   
    font-family: Inter;
    font-size: 13px;
  }

  .MuiTableHead-root {
    font-weight: 700;
    font-family: Inter;
    font-size: 14px;
    color:${palette.black};
  }

  .MuiTableRow-root {
    height: 35px;
   
    font-family: Inter;
    font-size: 13px;
    &:hover {
      background: #f8f8f8;
    }
  }

  .MuiTableCell-root {
    border-color: #f3f3f3;
    border-right:1px solid #f3f3f3;
    height:10px;
    font-family: Inter;
    white-space: nowrap;
    word-break: break-word;
    border-collapse: separate;
    line-height: 16px;
    color:${palette.black};
    font-size: 13px;
    font-weight:450;
  }
  .sticky {
    position: sticky;
    left: 0;
    z-index: 2;
    background: white;
  
  }
  .MuiTableCell-root:first-child {
    position: sticky;
    left: 0;
    background: #fff;
    border-left:1px solid #f3f3f3;
    font-weight:700;
    color:${palette.newBlue};
    z-index: 1;
    min-width:150px;
 
  }
  .MuiTableRow-root:hover .MuiTableCell-root:first-child {
    background:#f8f8f8;
  }

  .purple {
    background: #974EDE;
  }

  .green {
    background: #32cd32;
  }

  .dimgreen {
    background: #008000;
  }
`;

const CustomHead = styled(TableHead)`
position: sticky;
  top: 0;
  background-color: white;
  z-index: 2;
border-radius:8px;
font-size:14px;
color:${palette.black};
border-top:1px solid #f3f3f3;
color:#000;
height:10px;
flex-wrap:wrap;
background:#f9f9f9;
.MuiTableCell-root:first-child {
  position: sticky;
  background:#f9f9f9;
  color:${palette.black};
  z-index: 1;
  min-width:150px;

}
`
const Flexbox = styled.div`
width:100%;
background:#fff;
display:flex;
@media (min-width: 260px) and (max-width: 1300px){

    flex-shrink: 0;
overflow: auto; 
}
`
const Body = styled.div`
display:flex;
width:calc(100% - 240px);
overflow-x:scroll;
overflow-y:hidden;
position: fixed;
top: 0;
left: 0;
right: 0;
bottom: 0;
margin-left:240px;
`
const Icon = styled.img`
height:20px;
width:20px;
cursor:pointer;
color:black
`
const Icons = styled.img`
width: 20px;
height: 20px;
`
const CloseButton = styled.button`
background:transparent;
display:flex;
align-items:center;
justify-content:center;
border: none;
background:#ddd;
width:30px;
height:30px;
align-self:center;
border-radius:20px;
font-size: 16px;
cursor: pointer;

`
const ContainerBox = styled.div`
width:100%;
height:35px;
border-radius:8px;
display:flex;
justify-content:space-between;
font-size:13px;
padding-left:15px;
padding-right:15px;
margin-right:20px;
align-items:center;
border:1px solid #ddd;
font-weight:550;
cursor:pointer;
&:hover {
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.1); /* Box shadow on hover */
  }
`
const CustomButton = styled(PDFDownloadLink)`
width:130px;
height:44px;
margin-top:15px;
border-radius:8px;
border:1px solid #4169E1;
align-self:flex-end;
color:white;
font-size:16px;
background:#4169E1;
text-decoration: none; 
display:flex;
align-items:center;
justify-content:center;
cursor:pointer;
`
const CustomTextField = styled(TextField)`
border-radius: 8px;
border:1px solid #f3f3f3;s
&& {
   
    height: 30px; 
    padding: 5px; /* Adjust the padding as needed */
}
`;

const CustomInputAdornment = styled(InputAdornment)`
  
`;

const CustomIconButton = styled(IconButton)`
 
`;
const OptionBox = styled.div`
padding-left:20px;
padding-right:20px;
height:35px;
border-radius:8px;
font-weight:600;
background:#0F52BA;
color:#fff;
font-size:14px;


display:flex;
align-items:center;
justify-content:center;

cursor:pointer;
&:hover {
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.2); 
}

`

const OptionIcon = styled.img`
width: 12px;
height: 12px;
`